import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import cogoToast from "cogo-toast";
import { Button, Form, Modal } from "react-bootstrap";
import abbreviate from "number-abbreviate";
import styled from "styled-components";

import CollapsibleWrapper from "../CollapsibleWrapper";
import StepRangeSlider from "../../shared/StepRangeSlider/StepRangeSlider";
import UpgradeButton from "../../shared/Button/UpgradeButton";
import RadioButton from "../../shared/Button/RadioButton";
import TagButton from "../../shared/Button/TagButton";
import ColorPalette from "../../shared/ColorPalette";
import {
  ATR_MAX,
  ATR_RANGE,
  AVG_VOL_MAX,
  COUNT_MAX,
  COUNT_RANGE,
  DEFAULT_STREAM_SETTING,
  FLOAT_MAX,
  FLOAT_RANGE,
  GAP_MAX,
  GAP_RANGE,
  MARKET_CAP_MAX,
  MARKET_CAP_RANGE,
  PRICE_MAX,
  PRICE_RANGE,
  SECTORS_FILTER,
  STREAM_CHANNEL_MODE,
  STREAM_CHANNEL_MODE_LABEL,
  STREAM_CHANNEL_TYPE,
  VOLUME_RANGE,
  isActiveSubscription,
  isPro,
  isProNew,
  isProPlusNew,
} from "../../constants";

import API from "../../api";

import { Types as ConfigTypes } from "../../store/config";

import styles from "./index.module.scss";

const currencyFormatter = new Intl.NumberFormat();

const StreamTypeRadioButton = styled(RadioButton)`
  margin-left: 1.5em;
  @media only screen and (max-width: 576px) {
    & {
      margin-left: unset;
    }
  }
`;

function StreamSettings(props) {
  const { setting } = props;

  if (!setting.value.float) setting.value.float = DEFAULT_STREAM_SETTING.float;
  if (!setting.value.price) setting.value.price = DEFAULT_STREAM_SETTING.price;
  if (!setting.value.volume) setting.value.volume = DEFAULT_STREAM_SETTING.volume;
  if (!setting.value.count) setting.value.count = DEFAULT_STREAM_SETTING.count;
  if (!setting.value.atr) setting.value.atr = DEFAULT_STREAM_SETTING.atr;
  if (!setting.value.gap) setting.value.gap = DEFAULT_STREAM_SETTING.gap;
  if (!setting.value.marketCap) setting.value.marketCap = DEFAULT_STREAM_SETTING.marketCap;

  const { channel, title, type, mode, haltluld, value, color } = setting;

  const dispatch = useDispatch();
  const { auth, setting: storeSetting, config /* dashboard: { layout }*/ } = useSelector((state) => state);

  // const { menu } = getActiveLayout(layout);

  // if (!menu[channel]) {
  //   return (
  //     <div className={styles['channel-wrapper']}>
  //       <div className="d-flex align-items-center">
  //         <label className="small mr-2 mb-0 setting-panel-label-1">{title}</label>
  //         <label className="small mb-0 mt-1">
  //           <span className="text-symbol">(Disabled)</span>
  //         </label>
  //       </div>
  //     </div>
  //   )
  // }

  const plan = {
    isPro:
      isActiveSubscription(auth.user.subscription) &&
      (isPro(auth.user.subscription.plan) || isProNew(auth.user.subscription.plan)),
    isProOld: isActiveSubscription(auth.user.subscription) && isPro(auth.user.subscription.plan),
    isProPlus: isActiveSubscription(auth.user.subscription) && isProPlusNew(auth.user.subscription.plan),
  };

  const [priceSaving, setPriceSaving] = useState(false);
  const [volumeSaving, setVolumeSaving] = useState(false);
  const [floatSaving, setFloatSaving] = useState(false);
  const [atrSaving, setAtrSaving] = useState(false);
  const [gapSaving, setGapSaving] = useState(false);
  const [countSaving, setCountSaving] = useState(false);
  const [marketCapSaving, setMarketCapSaving] = useState(false);
  const [industrySaving, setIndustrySaving] = useState(false);
  const [symbolsSaving, setSymbolsSaving] = useState(false);

  const [userSymbolStatusText, setUserSymbolStatusText] = useState("");
  const [newUserSymbol, setNewUserSymbol] = useState("");
  const [symbolsForEdit, setSymbolsForEdit] = useState([]);
  const [showAddUserSymbol, setShowAddUserSymbol] = useState(false);
  const [showEditUserSymbol, setShowEditUserSymbol] = useState(false);

  const _refCSVInput = useRef();
  const _refCSVDropZone = useRef();
  const refUserSymbolInput = useRef();

  const onChangeType = (type) => {
    dispatch({
      type: ConfigTypes.UPDATE_STREAM_CHANNEL_TYPE,
      value: {
        channel: setting.channel,
        type,
      },
    });
  };

  const onChangeMode = (mode) => {
    dispatch({
      type: ConfigTypes.UPDATE_STREAM_CHANNEL_MODE,
      value: {
        channel: setting.channel,
        mode,
      },
    });
  };

  const renderFilterIndustries = (channel, value) => {
    const sectorSymbolsCnt = storeSetting.sectorSymbolsCnt || {};

    const industries = Object.keys(SECTORS_FILTER);

    const getSectorSymbolsCnt = (sector) => {
      return sectorSymbolsCnt[sector] || 0;
    };

    return industries
      .sort((a, b) => {
        if (a === "MISCELLANEOUS") return 1;
        if (b === "MISCELLANEOUS") return -1;
        if (a < b) return -1;
        if (b < a) return 1;
        return 0;
      })
      .map((item, index) => {
        return (
          <div
            key={`industry-${index}`}
            className="d-flex flex-row align-items-center industry-row"
            onClick={() => {
              dispatch({
                type: ConfigTypes.UPDATE_INDUSTRY_FILTER,
                value: {
                  channel,
                  item,
                },
              });
              setIndustrySaving(false);
              setTimeout(() => setIndustrySaving(true), 100);
            }}
          >
            {value && value.industries && value.industries[item] ? (
              <div className="industry-checked" />
            ) : (
              <div className="industry-unchecked" />
            )}
            <span className="small white-no-wrap industry-txt">
              {item} <span className="text-symbol">({getSectorSymbolsCnt(item)})</span>
            </span>
          </div>
        );
      });
  };

  const renderHaltLULD = (channel) => {
    const { halt, luld } = haltluld;
    const items = [
      {
        label: "Halt Bar",
        id: "halt",
        value: halt,
      },
      {
        label: "Limits",
        id: "luld",
        value: luld,
      },
    ];
    return items.map((item) => (
      <div
        key={`haltluld-${item.id}`}
        className={`d-flex flex-row align-items-center ${styles["haltluld-item"]}`}
        onClick={() => {
          dispatch({
            type: ConfigTypes.UPDATE_STREAM_CHANNEL_HALT_LULD,
            value: {
              channel: channel,
              type: item.id,
              value: !item.value,
            },
          });
        }}
      >
        {item.value ? <div className="industry-checked" /> : <div className="industry-unchecked" />}
        <span className="small white-no-wrap industry-txt">{item.label}</span>
      </div>
    ));
  };

  const initCSVInputFileHandler = () => {
    if (_refCSVInput.current) {
      _refCSVInput.current.onchange = (e) => {
        const file = e.target.files[0];
        onCSVFileSelected(file);
      };
    }
  };

  const onCSVFileSelected = (file) => {
    if (!file) {
      return;
    }
    if (!file.name.endsWith(".csv")) {
      cogoToast.error(`File type is invalid`);
      return;
    }
    const fr = new FileReader();
    fr.onload = () => {
      setUserSymbolStatusText("Processing ...");

      const arr = fr.result
        .split("\n")
        .map((value) => {
          let parsed = value.trim().toUpperCase();
          if (parsed[parsed.length - 1] === ",") {
            parsed = parsed.substr(0, parsed.length - 1);
          }
          if (parsed[0] === ",") {
            parsed = parsed.substr(1, parsed.length - 1);
          }
          parsed = parsed.replace(/[^A-Z0-9\.\-\/\^:]/g, "");
          return parsed;
        })
        .filter((value) => !!value && value != "SYMBOL");
      importUserSymbols(arr);
    };

    fr.readAsText(file);
  };

  const initCSVDropZoneHandler = () => {
    if (_refCSVDropZone.current) {
      _refCSVDropZone.current.ondragover = (e) => {
        e.preventDefault();
        e.stopPropagation();
      };
      _refCSVDropZone.current.ondrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const file = e.dataTransfer.files[0];
        onCSVFileSelected(file);
      };
    }
  };

  const onClickCSVUpload = () => {
    _refCSVInput.current && _refCSVInput.current.click();
  };

  const importUserSymbols = async (symbolsPayload) => {
    try {
      const res = await API.validateUserSymbols(symbolsPayload);
      if (!res.success) {
        throw "error";
      }

      const { symbols } = res;
      if (symbols.length == 0) {
        setUserSymbolStatusText("");
        cogoToast.warn("No valid symbols found in the CSV file");
      } else if (symbols.length === symbolsPayload.length) {
        setUserSymbolStatusText("");
        dispatch({
          type: ConfigTypes.UPDATE_STREAM_CUSTOM_SYMBOLS,
          value: {
            channel,
            symbols,
          },
        });
        setSymbolsSaving(false);
        setTimeout(() => setSymbolsSaving(true), 100);
      } else {
        const { hide } = cogoToast.warn(
          <div>
            <b>{symbols.length}</b> of <b>{symbolsPayload.length}</b> symbols are valid. Do you want to
            proceed?&nbsp;&nbsp;&nbsp;
            <span
              className="link-button"
              onClick={() => {
                hide();
                setUserSymbolStatusText("");
                dispatch({
                  type: ConfigTypes.UPDATE_STREAM_CUSTOM_SYMBOLS,
                  value: {
                    channel,
                    symbols,
                  },
                });
                setSymbolsSaving(false);
                setTimeout(() => setSymbolsSaving(true), 100);
              }}
            >
              Yes
            </span>
            &nbsp;&nbsp;&nbsp;
            <span
              className="link-button"
              onClick={() => {
                hide();
              }}
            >
              No
            </span>
          </div>,
          {
            hideAfter: 0,
            onClick: async () => {
              hide();
              setUserSymbolStatusText("");
            },
          }
        );
      }
    } catch (e) {
      cogoToast.error(`Failed to import symbols`);
    }
  };

  const addSingleUserSymbol = async () => {
    if (!newUserSymbol) {
      return;
    }
    const newSymbol = newUserSymbol.toUpperCase();

    const { stream } = config;

    const symbols = (stream || []).filter((item) => item.channel === channel)[0]?.value?.custom_view || [];
    if (symbols && symbols.indexOf(newSymbol) > -1) {
      cogoToast.warn(
        <div>
          You already have <b>{newSymbol}</b> imported
        </div>
      );
      return;
    }

    setUserSymbolStatusText("Processing ...");
    setShowAddUserSymbol(false);

    try {
      const res = await API.validateUserSymbols([newSymbol]);
      if (!res.success) {
        throw "error";
      }

      if (res.symbols.length == 0) {
        setUserSymbolStatusText("");
        cogoToast.warn(
          <div>
            Symbol <b>{newSymbol}</b> is invalid
          </div>
        );
      } else {
        setUserSymbolStatusText("");
        dispatch({
          type: ConfigTypes.UPDATE_STREAM_CUSTOM_SYMBOLS,
          value: {
            channel,
            symbols: [...symbols, newSymbol],
          },
        });
        setSymbolsSaving(false);
        setTimeout(() => setSymbolsSaving(true), 100);
      }
    } catch (e) {
      cogoToast.error(`Failed to add symbol`);
    }
  };

  const updateUserSymbols = () => {
    dispatch({
      type: ConfigTypes.UPDATE_STREAM_CUSTOM_SYMBOLS,
      value: {
        channel,
        symbols: [...symbolsForEdit],
      },
    });

    setShowEditUserSymbol(false);
    setSymbolsSaving(false);
    setTimeout(() => setSymbolsSaving(true), 100);
  };

  const emptyUserSymbols = (channel) => {
    dispatch({
      type: ConfigTypes.UPDATE_STREAM_CUSTOM_SYMBOLS,
      value: {
        channel,
        symbols: [],
      },
    });

    setSymbolsSaving(false);
    setTimeout(() => setSymbolsSaving(true), 100);
  };

  const renderAddUserSymbolModal = () => {
    return (
      <Modal
        className={"modal-drak-overlay"}
        show={showAddUserSymbol}
        onHide={() => {
          setShowAddUserSymbol(false);
        }}
        backdrop={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span style={{ fontSize: "1rem", fontWeight: "normal" }}>Add Symbol</span>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group>
            <label>Symbol</label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">@</span>
              </div>
              <Form.Control
                autoFocus={true}
                ref={refUserSymbolInput}
                type="text"
                className="form-control form-control-custom-warning text-light"
                value={newUserSymbol}
                onChange={(e) => {
                  setNewUserSymbol(e.target.value);
                }}
              />
            </div>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <div className="footer-container">
            <Button variant="warning col-12" className="btn" onClick={() => addSingleUserSymbol()}>
              Add
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };

  const renderEditUserSymbolModal = () => {
    const { stream } = config;
    const symbols = (stream || []).filter((item) => item.channel === channel)[0]?.value?.custom_view || [];
    const sorted = (symbolsForEdit || []).sort();
    const isWide = symbols && symbols.length > 100;
    return (
      <Modal
        className={"modal-drak-overlay"}
        show={showEditUserSymbol}
        onHide={() => {
          setShowEditUserSymbol(false);
        }}
        size={isWide ? "xl" : "md"}
        backdrop={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span style={{ fontSize: "1rem", fontWeight: "normal" }}>Edit Symbols</span>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="d-flex flex-row flex-wrap">
            {sorted.map((item, index) => (
              <TagButton
                key={`${item}`}
                label={item}
                hasClose
                onClose={() => {
                  setSymbolsForEdit(symbolsForEdit.filter((value) => value != item));
                }}
              />
            ))}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="footer-container">
            <Button
              variant={isWide ? "warning col-lg-6 offset-lg-3" : "warning col-12"}
              className="btn"
              onClick={() => updateUserSymbols()}
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };

  const customSymbols = value.custom_view || [];

  return (
    <div className={styles["channel-wrapper"]}>
      <div className="d-flex align-items-center">
        {/* <h4 className={`${styles['setting-channel-title']} mr-4`}>{title}</h4> */}
        <label className="small mr-4 mb-0 setting-panel-label-1">{title}</label>
        <ColorPalette
          activeColor={color}
          onChangeColor={(color) => {
            dispatch({
              type: ConfigTypes.UPDATE_STREAM_COLOR,
              value: {
                channel,
                color,
              },
            });
          }}
        />
      </div>
      <div className={`${styles["setting-type-container"]}`}>
        <div className="mt-3 mt-md-2 order-3 order-md-1">
          {/* <span className={styles['setting-type-label']}>Type</span> */}
          <label className="small mb-0 setting-panel-label-2">Type</label>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexWrap: "wrap",
            }}
          >
            {Object.keys(STREAM_CHANNEL_TYPE)
              .filter((item) => STREAM_CHANNEL_TYPE[item] !== STREAM_CHANNEL_TYPE.NONE)
              .map((item, i) => (
                <StreamTypeRadioButton
                  key={item}
                  className={i === 0 ? "ml-0" : ""}
                  label={STREAM_CHANNEL_TYPE[item]}
                  checked={type === STREAM_CHANNEL_TYPE[item]}
                  onClick={() => onChangeType(STREAM_CHANNEL_TYPE[item])}
                />
              ))}
          </div>
        </div>
        <div className="mt-2 mt-md-2 order-1 order-md-2">
          {/* <span className={styles['setting-type-label']}>Mode</span> */}
          <label className="small mb-0 setting-panel-label-2">Mode</label>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexWrap: "wrap",
            }}
          >
            {Object.keys(STREAM_CHANNEL_MODE).map((item, i) => (
              <StreamTypeRadioButton
                key={item}
                className={i === 0 ? "ml-0" : ""}
                label={STREAM_CHANNEL_MODE_LABEL[item]}
                checked={mode === STREAM_CHANNEL_MODE[item]}
                onClick={() => onChangeMode(STREAM_CHANNEL_MODE[item])}
              />
            ))}
          </div>
        </div>
        <div className="mt-3 mt-md-2 order-2 order-md-3">
          {/* <span className={styles['setting-type-label']}>Mode</span> */}
          <label className="small mb-0 setting-panel-label-2">Halt</label>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexWrap: "wrap",
            }}
          >
            {renderHaltLULD(channel)}
          </div>
        </div>
      </div>
      <div className={styles["setting-panel-content"]}>
        {type === STREAM_CHANNEL_TYPE.NONE && <div className="w-100 text-center small mt-2">Channel disabled</div>}
        {(type === STREAM_CHANNEL_TYPE.POPULAR || type === STREAM_CHANNEL_TYPE.FAV) && (
          <div className="w-100 text-center small mt-2">No additional settings required</div>
        )}
        {type === STREAM_CHANNEL_TYPE.FILTER && (
          <>
            <CollapsibleWrapper title="Price" storeKey="price" storeSubKey={channel} triggerAnim={priceSaving}>
              <div className={`mx-0 item-content ${styles["item-content"]} px-4`}>
                <div className="d-flex align-items-baseline">
                  <div className="d-flex flex-column flex-fill mt-3">
                    <StepRangeSlider
                      ref={(ref) => {
                        // TODO: handle ref
                      }}
                      values={{ low: value.price.min, high: value.price.max }}
                      range={PRICE_RANGE}
                      onChange={(value) => {
                        dispatch({
                          type: ConfigTypes.UPDATE_RANGE_FILTER,
                          value: {
                            channel,
                            value,
                            type: "price",
                          },
                        });
                        setPriceSaving(false);
                        setTimeout(() => setPriceSaving(true), 100);
                      }}
                    />
                    <div className="d-flex justify-content-between mt-3" style={{ fontSize: 12 }}>
                      <div className="small">MIN: ${currencyFormatter.format(value.price.min)}</div>
                      <div className="small">
                        MAX:{" "}
                        {value.price.max === PRICE_MAX ? (
                          <span>&infin;</span>
                        ) : (
                          `$${currencyFormatter.format(value.price.max)}`
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CollapsibleWrapper>

            <div className="mt-3"></div>
            <CollapsibleWrapper title="Count" storeKey="count" storeSubKey={channel} triggerAnim={countSaving}>
              <div className={`mx-0 item-content ${styles["item-content"]} px-4`}>
                <div className="d-flex align-items-baseline">
                  <div className="d-flex flex-column flex-fill mt-3">
                    <StepRangeSlider
                      ref={(ref) => {
                        // TODO: handle ref
                      }}
                      values={{ low: value.count.min, high: value.count.max }}
                      range={COUNT_RANGE}
                      onChange={(value) => {
                        dispatch({
                          type: ConfigTypes.UPDATE_RANGE_FILTER,
                          value: {
                            channel,
                            value,
                            type: "count",
                          },
                        });
                        setCountSaving(false);
                        setTimeout(() => setCountSaving(true), 100);
                      }}
                    />
                    <div className="d-flex justify-content-between mt-3" style={{ fontSize: 12 }}>
                      <div className="small">MIN: {abbreviate(value.count.min)}</div>
                      <div className="small">
                        MAX: {value.count.max === COUNT_MAX ? <span>&infin;</span> : abbreviate(value.count.max)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CollapsibleWrapper>

            <div className="mt-3"></div>
            <CollapsibleWrapper title="Avg Vol" storeKey="volume" storeSubKey={channel} triggerAnim={volumeSaving}>
              <div className={`mx-0 item-content ${styles["item-content"]} px-4`}>
                <div className="d-flex align-items-baseline">
                  <div className="d-flex flex-column flex-fill mt-3">
                    <StepRangeSlider
                      ref={(ref) => {
                        // TODO: handle ref
                      }}
                      values={{ low: value.volume.min, high: value.volume.max }}
                      range={VOLUME_RANGE}
                      onChange={(value) => {
                        dispatch({
                          type: ConfigTypes.UPDATE_RANGE_FILTER,
                          value: {
                            channel,
                            value,
                            type: "volume",
                          },
                        });
                        setVolumeSaving(false);
                        setTimeout(() => setVolumeSaving(true), 100);
                      }}
                    />
                    <div className="d-flex justify-content-between mt-3" style={{ fontSize: 12 }}>
                      <div className="small">MIN: {abbreviate(value.volume.min)}</div>
                      <div className="small">
                        MAX: {value.volume.max === AVG_VOL_MAX ? <span>&infin;</span> : abbreviate(value.volume.max)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CollapsibleWrapper>

            <div className="mt-3"></div>
            <CollapsibleWrapper title="Float" storeKey="float" storeSubKey={channel} triggerAnim={floatSaving}>
              <div className={`mx-0 item-content ${styles["item-content"]} px-4`}>
                <div className="d-flex align-items-baseline">
                  <div className="d-flex flex-column flex-fill mt-3">
                    <StepRangeSlider
                      ref={(ref) => {
                        // TODO: handle ref
                      }}
                      values={{ low: value.float.min, high: value.float.max }}
                      range={FLOAT_RANGE}
                      onChange={(value) => {
                        dispatch({
                          type: ConfigTypes.UPDATE_RANGE_FILTER,
                          value: {
                            channel,
                            value,
                            type: "float",
                          },
                        });
                        setFloatSaving(false);
                        setTimeout(() => setFloatSaving(true), 100);
                      }}
                    />
                    <div className="d-flex justify-content-between mt-3" style={{ fontSize: 12 }}>
                      <div className="small">MIN: {abbreviate(value.float.min)}</div>
                      <div className="small">
                        MAX: {value.float.max === FLOAT_MAX ? <span>&infin;</span> : abbreviate(value.float.max)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CollapsibleWrapper>

            <div className="mt-3"></div>
            <CollapsibleWrapper
              title="Market Cap"
              storeKey="marketCap"
              storeSubKey={channel}
              triggerAnim={marketCapSaving}
            >
              <div className={`mx-0 item-content ${styles["item-content"]} px-4`}>
                <div className="d-flex align-items-baseline">
                  <div className="d-flex flex-column flex-fill mt-3">
                    <StepRangeSlider
                      ref={(ref) => {
                        // TODO: handle ref
                      }}
                      values={{
                        low: value.marketCap.min,
                        high: value.marketCap.max,
                      }}
                      range={MARKET_CAP_RANGE}
                      onChange={(value) => {
                        dispatch({
                          type: ConfigTypes.UPDATE_RANGE_FILTER,
                          value: {
                            channel,
                            value,
                            type: "marketCap",
                          },
                        });
                        setMarketCapSaving(false);
                        setTimeout(() => setMarketCapSaving(true), 100);
                      }}
                    />
                    <div className="d-flex justify-content-between mt-3" style={{ fontSize: 12 }}>
                      <div className="small">MIN: {abbreviate(value.marketCap.min)}</div>
                      <div className="small">
                        MAX:{" "}
                        {value.marketCap.max === MARKET_CAP_MAX ? (
                          <span>&infin;</span>
                        ) : (
                          abbreviate(value.marketCap.max)
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CollapsibleWrapper>
            {value.marketCap?.min > 0 && (
              <div className="px-4 mt-2 mb-2" style={{ color: "#bfbfbf", fontSize: "12px" }}>
                Any ETF's, ETN's, and Indices will be removed as they don't have market cap value.
              </div>
            )}

            <div className="mt-3"></div>
            <CollapsibleWrapper
              title="GAP %"
              storeKey="gap"
              storeSubKey={channel}
              triggerAnim={gapSaving}
              dimmed={!plan.isProPlus}
            >
              {plan.isProPlus ? (
                <div className={`mx-0 item-content ${styles["item-content"]} px-4`}>
                  <div className="d-flex align-items-baseline">
                    <div className="d-flex flex-column flex-fill mt-3">
                      <StepRangeSlider
                        ref={(ref) => {
                          // TODO: handle ref
                        }}
                        values={{ low: value.gap.min, high: value.gap.max }}
                        range={GAP_RANGE}
                        onChange={(value) => {
                          dispatch({
                            type: ConfigTypes.UPDATE_RANGE_FILTER,
                            value: {
                              channel,
                              value,
                              type: "gap",
                            },
                          });
                          setGapSaving(false);
                          setTimeout(() => setGapSaving(true), 100);
                        }}
                      />
                      <div className="d-flex justify-content-between mt-3" style={{ fontSize: 12 }}>
                        <div className="small">MIN: {abbreviate(value.gap.min)}</div>
                        <div className="small">
                          MAX: {value.gap.max === GAP_MAX ? <span>&infin;</span> : abbreviate(value.gap.max)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <UpgradeButton label="PRO + ONLY" />
              )}
            </CollapsibleWrapper>

            <div className="mt-3"></div>
            <CollapsibleWrapper
              title="ATR %"
              storeKey="atr"
              storeSubKey={channel}
              triggerAnim={atrSaving}
              dimmed={!plan.isProPlus}
            >
              {plan.isProPlus ? (
                <div className={`mx-0 item-content ${styles["item-content"]} px-4`}>
                  <div className="d-flex align-items-baseline">
                    <div className="d-flex flex-column flex-fill mt-3">
                      <StepRangeSlider
                        ref={(ref) => {
                          // TODO: handle ref
                        }}
                        values={{ low: value.atr.min, high: value.atr.max }}
                        range={ATR_RANGE}
                        onChange={(value) => {
                          dispatch({
                            type: ConfigTypes.UPDATE_RANGE_FILTER,
                            value: {
                              channel,
                              value,
                              type: "atr",
                            },
                          });
                          setAtrSaving(false);
                          setTimeout(() => setAtrSaving(true), 100);
                        }}
                      />
                      <div className="d-flex justify-content-between mt-3" style={{ fontSize: 12 }}>
                        <div className="small">MIN: {abbreviate(value.atr.min)}</div>
                        <div className="small">
                          MAX: {value.atr.max === ATR_MAX ? <span>&infin;</span> : abbreviate(value.atr.max)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <UpgradeButton label="PRO + ONLY" />
              )}
            </CollapsibleWrapper>

            <div className="mt-3"></div>
            <CollapsibleWrapper
              title="Categories"
              storeKey="industry"
              storeSubKey={channel}
              triggerAnim={industrySaving}
            >
              <div className={`mx-0 item-content ${styles["item-content"]} px-4`}>
                <div className="d-flex flex-row flex-wrap pb-2">{renderFilterIndustries(channel, value)}</div>
              </div>
            </CollapsibleWrapper>
          </>
        )}
        {type === STREAM_CHANNEL_TYPE.CUSTOM && (
          <CollapsibleWrapper
            title="Custom View"
            storeKey="custom_view"
            storeSubKey={channel}
            triggerAnim={symbolsSaving}
          >
            <div className="mx-0 section-custom-view item-content px-2">
              <div className="d-flex flex-row flex-wrap py-2">
                <input
                  ref={(ref) => {
                    _refCSVInput.current = ref;
                    initCSVInputFileHandler();
                  }}
                  type="file"
                  accept=".csv"
                  style={{ display: "none" }}
                />
                <div
                  ref={(ref) => {
                    _refCSVDropZone.current = ref;
                    initCSVDropZoneHandler();
                  }}
                  className="csv-dropzone d-flex justify-content-center align-items-center flex-grow-1"
                  onClick={() => {
                    onClickCSVUpload();
                  }}
                >
                  <span>Drag & Drop or Click to Upload</span>
                </div>
                <div className="d-flex flex-column ml-2">
                  <div
                    className={"btn-toggle-state active mb-1"}
                    onClick={() => {
                      setShowAddUserSymbol(true);
                      setNewUserSymbol("");
                      setTimeout(() => {
                        refUserSymbolInput.current && refUserSymbolInput.current.focus();
                      }, 200);
                    }}
                  >
                    Add
                  </div>
                  <div
                    className={
                      customSymbols && customSymbols.length > 0 ? "btn-toggle-state active" : "btn-toggle-state"
                    }
                    onClick={() => {
                      if (customSymbols && customSymbols.length > 0) {
                        setShowEditUserSymbol(true);
                        setSymbolsForEdit([...(customSymbols || [])]);
                      }
                    }}
                    style={!customSymbols || !customSymbols.length ? { cursor: "initial" } : {}}
                  >
                    Edit
                  </div>
                </div>
              </div>
              <div className="user-symbols-status text-center pb-1">
                {userSymbolStatusText ? (
                  <span>{userSymbolStatusText}</span>
                ) : (
                  !customSymbols ||
                  customSymbols.length == 0 || (
                    <>
                      <span>{customSymbols.length} symbols imported</span>
                      <a
                        className="fa fa-trash-o ml-2"
                        onClick={() => emptyUserSymbols(channel)}
                        style={{ fontSize: "14px" }}
                      ></a>
                    </>
                  )
                )}
              </div>
            </div>
          </CollapsibleWrapper>
        )}
      </div>
      {renderAddUserSymbolModal()}
      {renderEditUserSymbolModal()}
    </div>
  );
}

StreamSettings.propTypes = {
  setting: PropTypes.object.isRequired,
};

export default StreamSettings;
