import React from "react";
import { Menu, Item } from "react-contexify";
import { connect } from "react-redux";
import { DEFAULT_VOLUME_DATA, VOLUME_CONTEXT_MENU_ID } from "../../constants";
import VolumeMoneyFlowPopupContent from "../VolumeMoneyFlowPopupContent";

class VolumePopup extends React.Component {
  render() {
    const { widget } = this.props;
    const { symbol, dist, loading, tf, data, err } = (this.props.volumeData || {})[widget] || DEFAULT_VOLUME_DATA;
    return (
      <Menu id={`${VOLUME_CONTEXT_MENU_ID}-${widget}`} className="p-0 volume-context-menu" theme={"dark"}>
        <VolumeMoneyFlowPopupContent symbol={symbol} dist={dist} loading={loading} data={data || []} err={err} />
      </Menu>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (state, props) => ({
  volumeData: state.discovery.volumeData,
  ...props,
});

export default connect(mapStateToProps, mapDispatchToProps)(VolumePopup);
