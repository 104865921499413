import { createActions, createReducer } from "reduxsauce";

const defaultState = {
  quotes: [],
  showSpinner: false,
};

export const { Types, Creators } = createActions({
  addToQuotes: ["symbol"],
  removeFromQuotes: ["symbol"],
  quotesFetch: [],
  quotesFetchSuccess: ["quotes"],
  quotesFetchFailed: ["message"],
  registerQuote: ["adds", "deletes"],
  registerSuccess: ["adds", "deletes"],
  registerFailed: ["message"],
  removeSuccess: ["symbol"],
  removeFailed: ["message"],
  resetQuotePin: ["value"],
  updateQuoteOrders: ["quotes"],
  updateSingleQuote: ["value"],
  updateQuotes: ["value"],
});

const registerQuote = (state, { symbol }) => {
  return {
    ...state,
  };
};

const registerSuccess = (state, { adds, deletes }) => {
  return {
    ...state,
    quotes: [
      ...(state.quotes || []).filter((quote) => !(deletes || []).includes((quote.symbol || "").toUpperCase())),
      ...(adds || []),
    ],
  };
};

const registerFailed = (state, { message }) => {
  return {
    ...state,
    error: message,
  };
};

const removeSuccess = (state, { symbol }) => {
  return {
    ...state,
    quotes: (state.quotes || []).filter((quote) => {
      return (quote.symbol || "").toUpperCase() !== (symbol || "").toUpperCase();
    }),
  };
};

const quotesFetchSuccess = (state, { quotes }) => {
  const data = Array.isArray(quotes) ? quotes : [];
  return {
    ...state,
    quotes: data,
  };
};

const quotesFetchFailed = (state, { message }) => {
  return {
    ...state,
    error: message,
  };
};

const addToQuotes = (state, { symbol }) => {
  return {
    ...state,
  };
};

const removeFromQuotes = (state, { symbol }) => {
  return {
    ...state,
  };
};

const resetQuotePin = (state, { value }) => {
  const { symbol, pin } = value;
  return {
    ...state,
    quotes: (state.quotes || []).map((quote) => {
      if (quote.symbol != symbol) return quote;
      quote.pin = pin;
      quote.pin_set = true;
      quote.pin_percent = 0;
      quote.pin_splitr = 1;
      return quote;
    }),
  };
};

const updateQuoteOrders = (state, { quotes }) => {
  return {
    ...state,
    quotes: [...quotes],
  };
};

const updateSingleQuote = (state, { value }) => {
  return {
    ...state,
    quotes: (state.quotes || []).map((item) => {
      if (item.symbol === value.symbol) {
        return value;
      }
      return item;
    }),
  };
};

const updateQuotes = (state, { value }) => {
  if (!Array.isArray(value)) {
    return state;
  }
  const updateSymbols = value.map((item) => item.symbol);
  return {
    ...state,
    quotes: (state.quotes || []).map((item) => {
      if (updateSymbols.includes(item.symbol)) {
        return value.find((updateItem) => updateItem.symbol === item.symbol);
      }
      return item;
    }),
  };
};

export const QuoteTypes = Types;

export const quoteReducer = createReducer(defaultState, {
  [Types.QUOTES_FETCH_SUCCESS]: quotesFetchSuccess,
  [Types.QUOTES_FETCH_FAILED]: quotesFetchFailed,
  [Types.ADD_TO_QUOTES]: addToQuotes,
  [Types.REMOVE_FROM_QUOTES]: removeFromQuotes,
  [Types.REGISTER_QUOTE]: registerQuote,
  [Types.REGISTER_SUCCESS]: registerSuccess,
  [Types.REGISTER_FAILED]: registerFailed,
  [Types.REMOVE_SUCCESS]: removeSuccess,
  [Types.REMOVE_FAILED]: registerFailed,
  [Types.RESET_QUOTE_PIN]: resetQuotePin,
  [Types.UPDATE_QUOTE_ORDERS]: updateQuoteOrders,
  [Types.UPDATE_SINGLE_QUOTE]: updateSingleQuote,
  [Types.UPDATE_QUOTES]: updateQuotes,
});

export default Creators;
