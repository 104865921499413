export const CHANNEL_TYPE = "type";
export const CHANNEL_AUTH = "auth";
export const CHANNEL_CHAT_SUBSCRIBE = "chat_subscribe";
export const CHANNEL_CHAT_UNSUBSCRIBE = "chat_unsubscribe";
export const CHANNEL_CHAT = "chat";
export const CHANNEL_STREAM_SUBSCRIBE = "stream_subscribe";
export const CHANNEL_STREAM_UNSUBSCRIBE = "stream_unsubscribe";
export const CHANNEL_DISCOVERY_SYMBOL_SUBSCRIBE = "discovery_symbol_subscribe";
export const CHANNEL_DISCOVERY_FIELD_SUBSCRIBE = "discovery_field_subscribe";
export const CHANNEL_DISCOVERY_UNSUBSCRIBE = "discovery_unsubscribe";
export const CHANNEL_QUOTES_SUBSCRIBE = "quotes_subscribe";
export const CHANNEL_QUOTES_UNSUBSCRIBE = "quotes_unsubscribe";
export const CHANNEL_FLOW_SUBSCRIBE = "flow_subscribe";
export const CHANNEL_FLOW_UNSUBSCRIBE = "flow_unsubscribe";
export const CHANNEL_ALERTS_SUBSCRIBE = "alert_subscribe";
export const CHANNEL_ALERTS_UNSUBSCRIBE = "alert_unsubscribe";
