import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CSSTransitionGroup } from "react-transition-group";
import { Collapse } from "react-bootstrap";

import { Types } from "../store/setting";

function CollapsibleWrapper(props) {
  const { title, triggerAnim, storeKey, storeSubKey, dimmed, labelSuffix, labelClassName } = props;

  const { collapsed } = useSelector((state) => state.setting);
  const dispatch = useDispatch();

  const isCollapsed = storeSubKey ? collapsed[storeKey]?.[storeSubKey] : collapsed[storeKey];

  return (
    <>
      <div
        className={`d-flex justify-content-between btn-collapse-toggler ${dimmed ? "text-symbol" : ""}`}
        onClick={() => {
          dispatch({
            type: Types.TOGGLE_COLLAPSED,
            value: {
              key: storeKey,
              subkey: storeSubKey,
            },
          });
        }}
      >
        <label className={"small"}>
          <span className={labelClassName || ""}>{title}</span>
          {labelSuffix}
          {!triggerAnim || (
            <CSSTransitionGroup
              transitionAppear={true}
              transitionAppearTimeout={1000}
              transitionName="setting-save"
              transitionEnter={false}
              transitionLeave={false}
            >
              <i key={0} className="fa fa-check saving-animator" />
            </CSSTransitionGroup>
          )}
        </label>
        <i className={`mdi ${isCollapsed ? "mdi-chevron-down" : "mdi-chevron-up"}`} style={{ lineHeight: 1 }} />
      </div>
      <Collapse in={!isCollapsed}>
        <div>{props.children}</div>
      </Collapse>
    </>
  );
}

export default CollapsibleWrapper;
