import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import cogoToast from "cogo-toast";
import { Scrollbars } from "react-custom-scrollbars-2";
const moment = require("moment-timezone");

import API from "../api";

import { useDataSource } from "../../contexts/datasource/provider";

import { Types as DashboardActionTypes } from "../dashboard/dashboardReducer";

const Alerts = function (props) {
  const dispatch = useDispatch();

  const { maximisedView, alerts, alertDetailItem } = useSelector((state) => state.dashboard);

  const datasource = useDataSource();

  useEffect(() => {
    const timerId = setInterval(
      () => {
        getAlertHistory();
      },
      3 * 60 * 1000
    ); // Update Every 3 minutes

    getAlertHistory();

    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    if (datasource.realtime) {
      datasource.realtime.subscribeAlerts();
      datasource.realtime.on("alerts", onRealtimeData);
      datasource.realtime.on("connected", onRealtimeSocketConnected);
    }

    return () => {
      datasource.realtime?.unsubscribeAlerts();
      datasource.realtime?.off("alerts", onRealtimeData);
      datasource.realtime?.off("connected", onRealtimeSocketConnected);
    };
  }, [datasource.realtime]);

  const onRealtimeSocketConnected = () => {
    datasource.realtime.subscribeAlerts();
  };

  const onRealtimeData = (data) => {
    const { detail: alertList } = data;
    dispatch({
      type: DashboardActionTypes.ADD_ALERTS,
      value: (alertList || []).sort((a, b) => {
        const ma = moment(a.date);
        const mb = moment(b.date);
        if (ma.isAfter(mb)) {
          return -1;
        } else if (ma.isBefore(mb)) {
          return 1;
        } else {
          return 0;
        }
      }),
    });
  };

  const getAlertHistory = () => {
    API.getAlertHistory()
      .then((alerts) => {
        if (Array.isArray(alerts)) {
          dispatch({
            type: DashboardActionTypes.SET_ALERTS,
            value: alerts,
          });
        }
      })
      .catch((error) => {
        console.info(error);
      });
  };

  const clearAlertHistory = () => {
    API.clearAlertHistory()
      .then((response) => {
        if (response.success) {
          cogoToast.success(`Alert history has been cleared`);
          dispatch({
            type: DashboardActionTypes.SET_ALERTS,
            value: [],
          });
          dispatch({
            type: DashboardActionTypes.SET_ALERT_DETAIL_ITEM,
            value: null,
          });
        } else {
          cogoToast.error(`Failed to clear alert history`);
        }
      })
      .catch((error) => {
        console.info(error);
      });
  };

  const formatDate = (inputDate) => {
    const mInputDate = moment(inputDate);
    const duration = moment.duration(moment().diff(mInputDate));
    let minutes = parseInt(duration.asMinutes());

    if (minutes < 60) {
      return mInputDate
        .fromNow()
        .replace("seconds", "sec")
        .replace("second", "sec")
        .replace("minutes", "min")
        .replace("minute", "min");
    }

    return mInputDate.format("MM/DD/YY HH:mm");
  };

  return (
    <div
      className={`card w-100 ${maximisedView ? "h-auto" : "h-100"}`}
      style={{
        // minHeight: 150,
        // maxHeight: 200,
        overflow: "hidden",
      }}
    >
      <div style={{}} className="alert-container">
        <div className={`d-flex justify-content-between mb-1`}>
          <div className="d-flex flex-wrap align-items-end">
            {alertDetailItem ? (
              <div
                className="alert-detail-item-clear mr-2"
                onClick={() => {
                  dispatch({
                    type: DashboardActionTypes.SET_ALERT_DETAIL_ITEM,
                    value: null,
                  });
                }}
              >
                <i className="fa fa-chevron-left mr-2"></i>
              </div>
            ) : (
              <h4 className="mr-2 mb-0">Alerts</h4>
            )}
            {/* <span className="font-13 alert-history-color">Filtered alerts daily limit reached</span> */}
          </div>
          {alerts && alerts.length > 0 && (
            <span className="clear-history" onClick={clearAlertHistory}>
              clear
            </span>
          )}
        </div>
        <div className="data-section alert-section flex-grow-1 d-flex mx-0">
          <Scrollbars
            autoHide
            style={{
              width: "100%",
              height: "auto",
            }}
            renderTrackVertical={(props) => <div className="track-vertical" {...props} />}
          >
            <div className="d-flex flex-row flex-fill alert-history-separator" />
            <div className="alert-history-data">
              {alertDetailItem ? (
                <>
                  <div className="font-14 alert-history-color my-2">{alertDetailItem.msg}</div>
                  <div className="font-14 alert-history-color">{formatDate(alertDetailItem.date)}</div>
                </>
              ) : (
                (alerts || []).map((item, index) => (
                  <div className="alert-item" key={`render-alert-history-${index}`}>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex flex-row flex-fill flex-wrap">
                        {/* <div className='font-13 alert-history-color'>{item.msg}</div> */}
                        <div className="font-13 alert-history-color text-double-line-ellipsis">{`${
                          item.msg
                        } - ${formatDate(item.date)}`}</div>
                      </div>
                      <div
                        className="alert-item-detail"
                        onClick={() => {
                          dispatch({
                            type: DashboardActionTypes.SET_ALERT_DETAIL_ITEM,
                            value: item,
                          });
                        }}
                      >
                        <i className="fa fa-chevron-right"></i>
                      </div>
                    </div>
                    <div className="d-flex flex-row flex-fill alert-history-separator" />
                  </div>
                ))
              )}
            </div>
          </Scrollbars>
        </div>
      </div>
    </div>
  );
};

export default Alerts;
