import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Table, Column, InfiniteLoader, AutoSizer, SortDirection } from "react-virtualized";
import Scrollbars from "react-custom-scrollbars-2";

import styles from "./index.module.scss";

// const defaultAvatar = require('../../../../assets/images/chat/no-image-user.png');
// const badgeVIP = require('../../../../assets/images/chat/crownvip.png');
const badgeModAdmin = require("../../../../../assets/images/chat/mod-admin.png");
// const badge1yrService = require('../../../../assets/images/chat/diamond1yr.png');

const UserTable = ({ data, sortBy, sortDirection, _sort, updateUserSetting }) => {
  const [scrollTop, setScrollTop] = useState(0);

  const loadMore = () => {};

  const handleScroll = ({ target: { scrollTop } }) => {
    setScrollTop(scrollTop);
  };

  const toggleChat = (user_id, value) => {
    updateUserSetting &&
      updateUserSetting({
        uid: user_id,
        field: "chat",
        value,
      });
  };

  const toggleRole = (user_id, value) => {
    updateUserSetting &&
      updateUserSetting({
        uid: user_id,
        field: "role",
        value,
      });
  };

  return (
    <section style={{ height: 570 }}>
      <InfiniteLoader isRowLoaded={({ index }) => !!data[index]} loadMoreRows={loadMore} rowCount={data.length}>
        {({ onRowsRendered }) => (
          <AutoSizer>
            {({ width, height }) => (
              <>
                <Table
                  autoHeight
                  height={height}
                  sort={_sort}
                  headerHeight={50}
                  width={width}
                  style={{ fontSize: 14 }}
                  sortBy={sortBy}
                  rowCount={0}
                  sortDirection={sortDirection}
                >
                  <Column
                    width={150}
                    flexGrow={1}
                    label="Username"
                    dataKey="username"
                    style={{ fontWeight: 600, paddingLeft: 10 }}
                    headerClassName={styles["admin-tbl-header"]}
                  />
                  <Column
                    width={200}
                    flexGrow={1}
                    label="Email"
                    dataKey="email"
                    style={{ fontWeight: 600, paddingLeft: 10 }}
                    headerClassName={styles["admin-tbl-header"]}
                  />
                  <Column
                    width={150}
                    label="Status"
                    dataKey="status"
                    style={{ fontWeight: 600, paddingLeft: 10 }}
                    headerClassName={styles["admin-tbl-header"]}
                  />
                  <Column
                    width={50}
                    label="Vibe"
                    dataKey="vibe"
                    style={{ fontWeight: 600, paddingLeft: 10 }}
                    headerClassName={styles["admin-tbl-header"]}
                  />
                  <Column
                    width={60}
                    label="Current Posts"
                    dataKey="cur_posts"
                    style={{
                      fontWeight: 600,
                      paddingLeft: 10,
                      whiteSpace: "break-spaces",
                    }}
                    headerClassName={styles["admin-tbl-header"]}
                  />
                  <Column
                    width={60}
                    label="Total Posts"
                    dataKey="tot_posts"
                    style={{
                      fontWeight: 600,
                      paddingLeft: 10,
                      whiteSpace: "break-spaces",
                    }}
                    headerClassName={styles["admin-tbl-header"]}
                  />
                  <Column
                    width={100}
                    label="Active"
                    dataKey="active"
                    style={{ fontWeight: 600, paddingLeft: 10 }}
                    headerClassName={styles["admin-tbl-header"]}
                  />
                  <Column
                    width={200}
                    label="Actions"
                    dataKey="actions"
                    disableSort
                    style={{ fontWeight: 600, paddingLeft: 10 }}
                    headerClassName={styles["admin-tbl-header"]}
                  />
                </Table>
                <Scrollbars
                  autoHide
                  style={{ height: height - 50, width: width }}
                  onScroll={handleScroll}
                  renderTrackVertical={(props) => <div className="track-vertical" {...props} />}
                >
                  <Table
                    autoHeight
                    height={height}
                    rowHeight={65}
                    sort={_sort}
                    headerHeight={0}
                    width={width}
                    style={{ fontSize: 14, height: "100%" }}
                    sortBy={sortBy}
                    onRowsRendered={onRowsRendered}
                    rowCount={data.length}
                    sortDirection={sortDirection}
                    rowGetter={({ index }) => data[index]}
                    rowClassName={(index) => (index.index % 2 === 0 ? "oddRow" : "evenRow")}
                    // isScrolling={isScrolling}
                    // onScroll={onChildScroll}
                    scrollTop={scrollTop}
                  >
                    <Column
                      width={150}
                      flexGrow={1}
                      label="Username"
                      dataKey="username"
                      className={`column-username`}
                      cellRenderer={({ cellData, rowData }) => (
                        <span
                          style={{
                            color: "#9B9B9C",
                            wordBreak: "break-all",
                            whiteSpace: "initial",
                          }}
                        >
                          {cellData || "-"}
                        </span>
                      )}
                    />
                    <Column
                      width={200}
                      flexGrow={1}
                      label="Email"
                      dataKey="email"
                      className={`column-email`}
                      cellRenderer={({ cellData, rowData }) => (
                        <span
                          style={{
                            color: "#9B9B9C",
                            wordBreak: "break-all",
                            whiteSpace: "initial",
                          }}
                        >
                          {cellData || "-"}
                        </span>
                      )}
                    />
                    <Column
                      width={150}
                      label="Status"
                      dataKey="role"
                      className={`column-status`}
                      cellRenderer={({ cellData, rowData }) => (
                        <span style={{ color: "#9B9B9C" }}>{cellData || "trader"}</span>
                      )}
                    />
                    <Column
                      width={50}
                      label="Vibe"
                      dataKey="vibe"
                      className={`column-vibe`}
                      cellRenderer={({ cellData, rowData }) => (
                        <span style={{ color: "#9B9B9C" }}>{cellData || 0}</span>
                      )}
                    />
                    <Column
                      width={60}
                      label="Current Posts"
                      dataKey="cur_posts"
                      className={`column-cur-posts`}
                      cellRenderer={({ cellData, rowData }) => (
                        <span
                          style={{
                            color: cellData > 0 ? "#00d25b" : "#9B9B9C",
                          }}
                        >
                          {cellData || 0}
                        </span>
                      )}
                    />
                    <Column
                      width={60}
                      label="Total Posts"
                      dataKey="tot_posts"
                      className={`column-tot-posts`}
                      cellRenderer={({ cellData, rowData }) => (
                        <span
                          style={{
                            color: cellData > 0 ? "#00d25b" : "#9B9B9C",
                          }}
                        >
                          {cellData || 0}
                        </span>
                      )}
                    />
                    <Column
                      width={100}
                      label="Active"
                      dataKey="active"
                      className={`column-subscription`}
                      cellRenderer={({ cellData, rowData }) => <span style={{ color: "#9B9B9C" }}>{cellData}</span>}
                    />
                    <Column
                      width={200}
                      label="Actions"
                      dataKey="actions"
                      className={`column-actions`}
                      cellRenderer={({ cellData, rowData }) => {
                        const chatEnabled = rowData.chat !== 0;
                        const roleModAdmin = rowData.role === "mod" || rowData.role === "admin";

                        return (
                          <div className="d-flex align-items-center">
                            <button
                              className={styles["action-button"]}
                              title={chatEnabled ? "Chat enabled" : "Chat disabled"}
                              onClick={() => toggleChat(rowData.id, !chatEnabled)}
                            >
                              {!chatEnabled ? (
                                <i className={`fa fa-ban ${styles["action-toggle-chat"]} ${styles["disabled"]}`} />
                              ) : (
                                <i className={`fa fa-check-circle ${styles["action-toggle-chat"]}`} />
                              )}
                            </button>
                            <button
                              className={styles["action-button"]}
                              title={rowData.role || "trader"}
                              onClick={() => toggleRole(rowData.id, !roleModAdmin)}
                            >
                              <img
                                src={badgeModAdmin}
                                className={`${styles["action-toggle-mod"]} ${roleModAdmin ? styles["active"] : ""}`}
                              />
                            </button>
                          </div>
                        );
                      }}
                    />
                  </Table>
                </Scrollbars>
              </>
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
    </section>
  );
};

UserTable.propTypes = {
  data: PropTypes.array,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.oneOf([SortDirection.ASC, SortDirection.DESC]),
  _sort: PropTypes.func,
  updateUserSetting: PropTypes.func,
};

export default UserTable;
