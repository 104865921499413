import React from "react";
import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import NumberFormat from "react-number-format";

import ColorPalette from "../../shared/ColorPalette";

import { LAST_AT_FILTER_DEFAULT_THRESHOLD, LAST_AT_FILTER_UNIT, TREND_TYPE } from "../../constants";
import { FILTER_TYPE } from "../discoveryReducer";

class SmartFiltersModal extends React.Component {
  columns = [
    "atr",
    "avgVolume",
    "float",
    "gap",
    "halt",
    "last",
    "price_dist",
    "marketCap",
    "momentum",
    "mf_price",
    "mf_dist",
    "news",
    "rs",
    "short_ratio",
    "squeeze",
    "tradeCount",
    "tradeCount_dist",
    "trend",
    "uVol",
    "rv_price",
    "rv_dist",
    "vWapDist",
  ];
  state = {
    mode: "add",
    name: "",
    type: FILTER_TYPE.ALL,
    color: "",
    filters: [{ column: "", min: "", max: "" }],
  };

  updatingFilterName = "";

  getColumnTitle = (col) => {
    if (col === "avgVolume") {
      return "Avg Volume";
    }
    if (col === "float") {
      return "Float";
    }
    if (col === "last") {
      return "Last";
    }
    if (col === "marketCap") {
      return "Market Cap";
    }
    if (col === "momentum") {
      return "Momentum";
    }
    if (col === "mf_price") {
      return "Money Flow $";
    }
    if (col === "mf_dist") {
      return "Money Flow %";
    }
    if (col === "price_dist") {
      return "Last %";
    }
    if (col === "squeeze") {
      return "Squeeze";
    }
    if (col === "tradeCount") {
      return "Trades";
    }
    if (col === "tradeCount_dist") {
      return "Trades %";
    }
    if (col === "trend") {
      return "Trend";
    }
    if (col === "uVol") {
      return "UVol";
    }
    if (col === "vWapDist") {
      return "VWAPDist";
    }
    if (col === "rs") {
      return "RelStr %";
    }
    if (col === "rv_price") {
      return "Volume";
    }
    if (col === "rv_dist") {
      return "Volume %";
    }
    if (col === "short_ratio") {
      return "Short Ratio";
    }
    if (col === "atr") {
      return "ATR %";
    }
    if (col === "gap") {
      return "Gap %";
    }
    if (col === "news") {
      return "News";
    }
    if (col === "halt") {
      return "Halt";
    }
    return col;
  };

  addRow = () => {
    if (this.state.filters.length < this.columns.length) {
      const filters = [...this.state.filters];
      filters.push({
        column: "",
        min: "",
        max: "",
      });
      this.setState({
        filters,
      });
    }
  };

  updateFilterColumn = (index, c) => {
    const filters = [...this.state.filters];
    filters[index].column = c;
    if (c === "squeeze") {
      filters[index].min = {
        NOW: false,
        PRE: false,
        POST: false,
      };
      filters[index].max = "";
    } else if (c === "trend") {
      filters[index].min = TREND_TYPE.BUY;
      filters[index].max = "";
    } else if (c === "news" || c === "halt") {
      filters[index].min = {
        threshold: LAST_AT_FILTER_DEFAULT_THRESHOLD,
        unit: LAST_AT_FILTER_UNIT[1],
      };
    } else {
      filters[index].min = "";
      filters[index].max = "";
    }
    this.setState({
      filters,
    });
  };

  updateFilterValue = (index, type, value) => {
    const filters = [...this.state.filters];
    filters[index][type] = value;
    this.setState({
      filters,
    });
  };

  updateFilterType = (type) => {
    this.setState({
      type,
    });
  };

  deleteFilterRow = (index) => {
    const filters = [...this.state.filters];
    filters.splice(index, 1);
    this.setState({
      filters,
    });
  };

  clear = () => {
    this.setState({
      name: "",
      type: FILTER_TYPE.ALL,
      color: "",
      filters: [{ column: "", min: "", max: "" }],
    });
  };

  initForAdd = () => {
    this.setState({
      mode: "add",
      name: "",
      type: FILTER_TYPE.ALL,
      color: "",
      filters: [{ column: "", min: "", max: "" }],
    });
  };

  initForUpdate = (name, filter) => {
    const values = [];
    for (let key in filter.values) {
      let { min, max } = filter.values[key];
      if (key === "squeeze") {
        if (Object.prototype.toString.call(min) !== "[object Object]") {
          min = {};
        } else {
          if (min["PRE"] === true) min["PRE"] = 7;
          if (min["POST"] === true) min["POST"] = -2;
        }
        max = null;
      } else if (key === "trend") {
        if (min !== TREND_TYPE.BUY && min !== TREND_TYPE.SELL) {
          min = TREND_TYPE.BUY;
        }
        max = "";
      } else if (key === "news" || key === "halt") {
        if (Object.prototype.toString.call(min) !== "[object Object]") {
          min = {};
        } else {
          if (isNaN(min["threshold"])) min["threshold"] = LAST_AT_FILTER_DEFAULT_THRESHOLD;
          if (!LAST_AT_FILTER_UNIT.includes(min["unit"])) min["unit"] = LAST_AT_FILTER_UNIT[1];
        }
        max = null;
      } else {
        if (isNaN(min)) min = "";
        else if (!min && min !== 0 && min !== "0") min = "";
        if (isNaN(max)) max = "";
        else if (!max && max !== 0 && max !== "0") max = "";
      }
      if (this.isBillionColumn(key)) {
        if (min) {
          min = min / 1000000000;
        }
        if (max) {
          max = max / 1000000000;
        }
      }
      values.push({
        column: key,
        min: min,
        max: max,
      });
    }

    this.updatingFilterName = name;
    this.setState({
      mode: "edit",
      name,
      type: filter.type,
      color: filter.color || "",
      filters: [...values],
    });
  };

  updateFilterName = (name) => {
    this.setState({
      name,
    });
  };

  handleFilter = () => {
    this.props.onFilter({
      name: this.state.name,
      type: this.state.type,
      color: this.state.color,
      values: this.state.filters.reduce((acc, curr) => {
        acc[curr.column] = {
          min:
            curr.column === "squeeze" || curr.column === "trend" || curr.column === "news" || curr.column === "halt"
              ? curr.min
              : parseFloat(curr.min),
          max: parseFloat(curr.max),
        };
        if (this.isBillionColumn(curr.column)) {
          if (curr.min) {
            acc[curr.column].min = curr.min * 1000000000;
          }
          if (curr.max) {
            acc[curr.column].max = curr.max * 1000000000;
          }
        }
        return acc;
      }, {}),
    });
    // this.clear();
    // this.props.onHide(false);
  };

  handleUpdate = () => {
    this.props.onUpdate(this.updatingFilterName, {
      name: this.state.name,
      type: this.state.type,
      color: this.state.color,
      values: this.state.filters.reduce((acc, curr) => {
        acc[curr.column] = {
          min:
            curr.column === "squeeze" || curr.column === "trend" || curr.column === "news" || curr.column === "halt"
              ? curr.min
              : parseFloat(curr.min),
          max: parseFloat(curr.max),
        };
        if (this.isBillionColumn(curr.column)) {
          if (curr.min) {
            acc[curr.column].min = curr.min * 1000000000;
          }
          if (curr.max) {
            acc[curr.column].max = curr.max * 1000000000;
          }
        }
        return acc;
      }, {}),
    });
  };

  isMoneyColumn = (column) => {
    return column === "last" || column === "mf_price" || column === "marketCap";
  };

  isPercentColumn = (column) => {
    return (
      column === "price_dist" ||
      column === "uVol" ||
      column === "vWapDist" ||
      column === "mf_dist" ||
      column === "rv_dist" ||
      column === "atr" ||
      column === "gap" ||
      column === "rs" ||
      column === "tradeCount_dist"
    );
  };

  isBillionColumn = (column) => {
    return column === "marketCap";
  };

  isFieldDisabled = (column) => {
    if (column === "squeeze" || column === "mf_price" || column === "mf_dist") {
      return !this.props.isProPlus && !this.props.isProOld;
    }
    if (
      column === "trend" ||
      column === "short_ratio" ||
      column === "atr" ||
      column === "gap" ||
      column === "tradeCount" ||
      column === "tradeCount_dist"
    ) {
      return !this.props.isProPlus;
    }
    if (column === "news") {
      return !this.props.isProPlus && !this.props.isProOld && !this.props.isPro;
    }
    return false;
  };

  isFormValid = (params) => {
    const { requiresName } = params || {};
    const { name, filters } = this.state;

    if (requiresName && !name) {
      return false;
    }

    const validFilters = filters.filter((item) => {
      if (!item.column) return false;
      if (item.column === "squeeze") {
        if (!item.min || Object.keys(item.min).length === 0) return false;
        const checkedCnt = Object.keys(item.min).filter((key) => item.min[key]).length;
        if (checkedCnt === 0) return false;
      } else if (item.column === "trend") {
        if (item.min !== TREND_TYPE.BUY && item.min !== TREND_TYPE.SELL) return false;
      } else if (item.column === "news" || item.column === "halt") {
        if (isNaN(item.min?.threshold) || item.min?.threshold <= 0 || !LAST_AT_FILTER_UNIT.includes(item.min?.unit))
          return false;
      } else {
        if (!item.min && item.min !== 0 && !item.max && item.max !== 0) return false;
      }
      return true;
    });
    if (validFilters.length < filters.length) {
      return false;
    }

    return true;
  };

  renderMinMaxFilterItem = (index, filter) => {
    return (
      <div className="d-flex flex-row align-items-center">
        <Form.Label className="my-1 mr-1 ml-2 d-none d-sm-block min-max-label" htmlFor="inlineMin">
          Min
        </Form.Label>
        {/* <Form.Control
        size="sm"
        type="number"
        placeholder="min"
        id="inlineMin"
        className="ml-1 input-number-hide-spinner"
        value={filter.min}
        style={{color: 'white'}}
        onChange={(e) => {
          e.preventDefault();
          this.updateFilterValue(index, "min", e.target.value);
        }}
      /> */}
        <NumberFormat
          thousandSeparator={true}
          className="form-control form-control-sm px-1 ml-1 text-right"
          inputMode="numeric"
          placeholder="min"
          prefix={this.isMoneyColumn(filter.column) ? "$" : ""}
          suffix={this.isPercentColumn(filter.column) ? "  %" : this.isBillionColumn(filter.column) ? "  B" : ""}
          style={{ color: "white" }}
          value={filter.min}
          onValueChange={(values) => {
            this.updateFilterValue(index, "min", values.floatValue);
          }}
        />
        <Form.Label className="my-1 mr-1 ml-2 d-none d-sm-block min-max-label" htmlFor="inlineMax">
          Max
        </Form.Label>
        {/* <Form.Control
        size="sm"
        type="number"
        placeholder="max"
        className="ml-1 input-number-hide-spinner"
        style={{color: 'white'}}
        value={filter.max}
        onChange={(e) => {
          e.preventDefault();
          this.updateFilterValue(index, "max", e.target.value);
        }}
      /> */}
        <NumberFormat
          thousandSeparator={true}
          className="form-control form-control-sm px-1 ml-1 text-right"
          inputMode="numeric"
          placeholder="max"
          prefix={this.isMoneyColumn(filter.column) ? "$" : ""}
          suffix={this.isPercentColumn(filter.column) ? "  %" : this.isBillionColumn(filter.column) ? "  B" : ""}
          style={{ color: "white" }}
          value={filter.max}
          onValueChange={(values) => {
            this.updateFilterValue(index, "max", values.floatValue);
          }}
        />
      </div>
    );
  };

  renderSqueezeFilterItem = (index, filter) => {
    const values = ["PRE", "NOW", "POST"];
    return (
      <div className="d-flex flex-column flex-sm-row justify-content-between align-items-baseline align-items-sm-center flex-grow-1 pl-1 pl-sm-4">
        {values.map((item, id) => (
          <div
            key={`filter-squeeze-${id}`}
            className={`input-group-checkbox`}
            onClick={() => {
              const min = filter.min;
              if (!min[item]) {
                if (item === "PRE") {
                  if (min["POST"]) return;
                  else {
                    min[item] = 7;
                  }
                } else if (item === "POST") {
                  if (min["PRE"]) return;
                  else {
                    min[item] = -2;
                  }
                } else {
                  if (min["PRE"] && min["POST"]) return;
                  else min[item] = true;
                }
              } else {
                min[item] = false;
              }
              this.updateFilterValue(index, "min", min);
            }}
          >
            <span className={`chk-box ${filter.min[item] ? "checked" : ""}`}></span>
            <span className="chk-label">{item}</span>
            {item !== "NOW" && (
              <NumberFormat
                className="form-control form-control-sm px-1 ml-1 text-right"
                inputMode="numeric"
                placeholder=""
                style={{ color: "white", maxWidth: "40px", height: "24px" }}
                disabled={!filter.min[item]}
                value={filter.min[item] || ""}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onValueChange={(values) => {
                  const min = filter.min;
                  min[item] = values.value;
                  if (item === "PRE") {
                    if (min[item] !== "" && min[item] < 1) {
                      min[item] = 1;
                    }
                  }
                  if (item === "POST") {
                    if (min[item] !== "") {
                      if (min[item] < -5) {
                        min[item] = -5;
                      } else if (min[item] > -1) {
                        min[item] = -1;
                      }
                    }
                  }
                  this.updateFilterValue(index, "min", min);
                }}
              />
            )}
          </div>
        ))}
      </div>
    );
  };

  renderTrendFilterItem = (index, filter) => {
    const trendOptions = [TREND_TYPE.BUY, TREND_TYPE.SELL];
    return (
      <div className="d-flex justify-content-end align-items-center flex-grow-1">
        <Dropdown varaint="btn btn-outline-secondary" className="mx-2" style={{ borderBottom: "1px solid white" }}>
          <Dropdown.Toggle className="industry_input d-flex align-items-center px-1">
            <div className="d-flex justify-content-start align-items-center">
              <div className={`trend-wrapper ${filter.min === TREND_TYPE.BUY ? "trending mb-1" : "mb-1"} mr-2`}>
                <div className="triangle"></div>
              </div>
              <span style={{ textTransform: "capitalize" }}>{filter.min}</span>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {trendOptions.map((option) => {
              return (
                <Dropdown.Item
                  key={`discovery-filter-trend-${option}`}
                  onClick={() => {
                    this.updateFilterValue(index, "min", option);
                  }}
                  tabIndex="1"
                >
                  <div className="d-flex justify-content-start align-items-center">
                    <div className={`trend-wrapper ${option === TREND_TYPE.BUY ? "trending mb-1" : ""} mr-2`}>
                      <div className="triangle"></div>
                    </div>
                    <span style={{ textTransform: "capitalize" }}>{option}</span>
                  </div>
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  renderLastAtFilterItem = (index, filter) => {
    const { threshold, unit } = filter.min || {};
    return (
      <div className="d-flex flex-column flex-sm-row justify-content-end align-items-baseline align-items-sm-center flex-grow-1 pl-1 pl-sm-4">
        {/* <div className={`input-group-checkbox`} onClick={() => {
        }}>
          <span className={`chk-box checked`}></span>
        </div> */}
        <div className="d-flex align-items-center justify-content-between">
          <NumberFormat
            className="form-control form-control-sm px-1 ml-1 text-right"
            inputMode="numeric"
            placeholder=""
            style={{ color: "white", maxWidth: "40px", marginTop: "2px" }}
            value={threshold}
            onValueChange={(values) => {
              this.updateFilterValue(index, "min", {
                threshold: values.value,
                unit,
              });
            }}
          />
          <Dropdown varaint="btn btn-outline-secondary" className="ml-2" style={{ borderBottom: "1px solid white" }}>
            <Dropdown.Toggle className="industry_input px-1">
              <span className="" style={{}}>
                {unit || "unit"}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {LAST_AT_FILTER_UNIT.map((key) => {
                return (
                  <Dropdown.Item
                    key={key}
                    onClick={() => {
                      this.updateFilterValue(index, "min", {
                        threshold,
                        unit: key,
                      });
                    }}
                    tabIndex="1"
                  >
                    <span
                      style={{
                        color: `${key === unit ? "#f6c548" : ""}`,
                      }}
                    >
                      {key}
                    </span>
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    );
  };

  render() {
    const { open, onHide } = this.props;
    return (
      <Modal
        id="discovery-smart-filter"
        className={"modal-drak-overlay"}
        show={open}
        onHide={() => {
          onHide(false);
        }}
        aria-labelledby="Discovery Filter Modal"
        backdrop={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex flex-column flex-md-row align-items-start align-items-md-center">
            <Form.Control
              className="mr-0 mr-md-2 mb-2 mb-md-0"
              size="sm"
              type="text"
              placeholder="Enter filter name"
              style={{ width: 200, color: "white" }}
              value={this.state.name}
              onChange={(e) => {
                e.preventDefault();
                this.updateFilterName(e.target.value);
              }}
            />
            <ColorPalette activeColor={this.state.color} onChangeColor={(color) => this.setState({ color })} />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="discovery-filters">
          {this.state.filters.map((filter, index) => (
            <div key={`filter-${index}-${filter.column}`}>
              <Form.Group className="d-flex align-items-center mb-0">
                <Dropdown
                  varaint="btn btn-outline-secondary"
                  style={{
                    border: "1px solid rgb(128, 128, 128)",
                    borderRadius: 4,
                    padding: 2,
                  }}
                >
                  <Dropdown.Toggle className="industry_input" id="dropdown-basic" style={{ width: 120 }}>
                    {filter.column ? this.getColumnTitle(filter.column) : "Column"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdown-2-columns">
                    {this.columns
                      .filter((c) => !this.state.filters.map((f) => f.column).includes(c))
                      .map((c, i) => (
                        <Dropdown.Item
                          key={`filter-column-value-${i}`}
                          onClick={() => {
                            this.updateFilterColumn(index, c);
                          }}
                          disabled={this.isFieldDisabled(c)}
                        >
                          <span className={`${this.isFieldDisabled(c) ? "text-symbol" : ""}`}>
                            {this.getColumnTitle(c)}
                          </span>
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
                <div className="d-flex align-items-center justify-content-between flex-grow-1">
                  {filter.column === "squeeze" && this.renderSqueezeFilterItem(index, filter)}
                  {filter.column === "trend" && this.renderTrendFilterItem(index, filter)}
                  {(filter.column === "news" || filter.column === "halt") && this.renderLastAtFilterItem(index, filter)}
                  {filter.column !== "squeeze" &&
                    filter.column !== "trend" &&
                    filter.column !== "news" &&
                    filter.column !== "halt" &&
                    this.renderMinMaxFilterItem(index, filter)}
                  <i
                    className="mdi mdi-trash-can pl-2 pr-0 pl-sm-3 pr-sm-3 py-2"
                    role="button"
                    onClick={(e) => {
                      e.preventDefault();
                      this.deleteFilterRow(index);
                    }}
                  />
                </div>
              </Form.Group>
              {filter?.column === "marketCap" && filter?.min > 0 && (
                <div className="mb-1" style={{ color: "#bfbfbf", fontSize: "12px" }}>
                  Any ETF's, ETN's, and Indices will be removed as they don't have market cap value.
                </div>
              )}
            </div>
          ))}

          {this.columns.length > this.state.filters.length ? (
            <div className="d-flex align-items-center justify-content-end">
              <div
                role="button"
                onClick={() => {
                  this.addRow();
                }}
              >
                <i className="mdi mdi-plus mt-3" />
                Add row
              </div>
            </div>
          ) : null}

          <Form.Group className="d-flex align-items-center justify-content-end" style={{ color: "white" }}>
            <Form.Check
              className="d-flex white"
              inline
              label={FILTER_TYPE.ALL}
              type="radio"
              id={`inline-radio-1`}
              name="type"
              checked={this.state.type === FILTER_TYPE.ALL}
              onChange={(e) => {
                if (e.target.checked) {
                  this.updateFilterType(FILTER_TYPE.ALL);
                }
              }}
            />
            <Form.Check
              className="d-flex"
              inline
              label={FILTER_TYPE.ANY}
              type="radio"
              id={`inline-radio-2`}
              name="type"
              checked={this.state.type === FILTER_TYPE.ANY}
              onChange={(e) => {
                if (e.target.checked) {
                  this.updateFilterType(FILTER_TYPE.ANY);
                }
              }}
            />

            <span style={{ height: 20 }}>conditions met</span>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer className="justify-content-between">
          <Button variant="secondary" onClick={() => this.clear()}>
            Clear
          </Button>
          {this.state.mode === "add" ? (
            <div>
              <Button
                variant="success"
                className="mr-2"
                onClick={() => {
                  this.handleFilter();
                }}
                disabled={!this.isFormValid()}
              >
                Filter
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  this.handleFilter();
                }}
                disabled={!this.isFormValid({ requiresName: true })}
              >
                Save & Filter
              </Button>
            </div>
          ) : (
            <div>
              <Button
                variant="primary"
                onClick={() => {
                  this.handleUpdate();
                }}
                disabled={!this.isFormValid({ requiresName: true })}
              >
                Update
              </Button>
            </div>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default SmartFiltersModal;
