import styled from "styled-components";

export const SettingLabel = styled.span`
  font-size: 14px;
  color: white;
  display: block;
  text-align: right;
  width: 60px;
  margin-right: 5px;
  text-transform: initial;
`;
export const SettingValue = styled.span`
  font-size: 12px;
  color: white;
  text-align: right;
  min-width: 21px;
  margin-left: 5px;
  display: block;
  white-space: nowrap;
  text-transform: lowercase;
`;
