import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import DarkTheme from "@amcharts/amcharts5/themes/Dark";
import Animated from "@amcharts/amcharts5/themes/Animated";
import _, { last } from "lodash";

export default function setupChart({ chartDiv, chartColor, timeUnit, timeCount, tf }) {
  // chartColor = chartColor ?? "#00ff6e";

  const amChartColor = am5.color(chartColor);
  const amChartColorDark = am5.Color.brighten(amChartColor, -0.6);

  let mouseOverChart = false;

  const root = am5.Root.new(chartDiv);
  root._logo.dispose();
  //   root.setThemes([DarkTheme.new(root)]);
  const darkTheme = DarkTheme.new(root);
  darkTheme.rule("Label").set("fontSize", "14px");

  root.setThemes([Animated.new(root), darkTheme]);
  // root.timezone = am5.Timezone.new("America/New_York");

  const chart = root.container.children.push(
    am5xy.XYChart.new(root, {
      panX: true,
      panY: false,
      wheelY: "zoomX",
      wheelX: "panX",
      pinchZoomX: true,
    })
  );

  const xTooltip = am5.Tooltip.new(root, {});

  [xTooltip].forEach((tooltip) => {
    tooltip.get("background").setAll({ opacity: 0.8, fill: "#191c24", strokeOpacity: 0.2 });
    tooltip.label.set("fill", am5.color("#fff"));
    tooltip.label.set("fontSize", "9pt");
    tooltip.setAll({ opacity: 0.5 });
  });

  const xAxis = chart.xAxes.push(
    am5xy.GaplessDateAxis.new(root, {
      baseInterval: {
        timeUnit: timeUnit,
        count: timeCount,
      },
      maxDeviation: 0,
      renderer: am5xy.AxisRendererX.new(root, {}),
      tooltip: xTooltip,
    })
  );

  xAxis.adapters.add("tooltipDateFormat", () => {
    const dateTfs = [/d/, /mo/, /w/];

    if (dateTfs.find((word) => (tf ?? "").match(word))) {
      return "dd MMM yyyy";
    } else if ((tf ?? "").match(/(2|4).*h.*/)) {
      return "dd MMM HH:mm";
    } else {
      return "HH:mm";
    }
  });

  const yAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      maxDeviation: 0.01,
      renderer: am5xy.AxisRendererY.new(root, {
        opposite: true,
      }),
    })
  );

  // xAxis.get("renderer").grid.template.set("visible", false);
  // yAxis.get("renderer").grid.template.set("visible", false);

  const tooltip = am5.Tooltip.new(root, {
    pointerOrientation: "horizontal",
    labelText: "{valueY}",
  });
  tooltip.get("background").setAll({ opacity: 0.4, background: am5.color("#000000") });

  const series = chart.series.push(
    am5xy.SmoothedXYLineSeries.new(root, {
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "value",
      valueXField: "time",
      stroke: amChartColor,
      // sequencedInterpolation: true,
      tooltip: tooltip,
    })
  );
  series.strokes.template.set("templateField", "strokeSettings");

  var cursor = chart.set(
    "cursor",
    am5xy.XYCursor.new(root, {
      xAxis,
      yAxis,
    })
  );
  cursor.lineY.set("visible", false);

  const gradient = am5.LinearGradient.new(root, {
    stops: [{ color: amChartColor }, { color: amChartColor, opacity: 0.2 }, { color: amChartColor, opacity: 0 }],
    rotation: 90,
  });

  series.fills.template.setAll({
    fillOpacity: 0.2,
    visible: true,
    fillGradient: gradient,
    templateField: "fillSettings",
  });

  series.strokes.template.setAll({
    templateField: "strokeSettings",
  });

  //   series.axisRanges.setAll([am5xy.r])

  series.bullets.push(function (root, series, dataItem) {
    if (dataItem.dataContext.bullet) {
      var container = am5.Container.new(root, {});
      var circle0 = container.children.push(
        am5.Circle.new(root, {
          radius: 3,
          fill: chartColor,
          opacity: 0,
        })
      );
      var circle1 = container.children.push(
        am5.Circle.new(root, {
          radius: 3,
          fill: chartColor,
          opacity: 0,
        })
      );

      const bulletTime = 1000;
      const delay = 0;

      setTimeout(() => {
        circle0.set("opacity", 1);

        circle1.animate({
          key: "radius",
          to: 15,
          duration: bulletTime,
          easing: am5.ease.out(am5.ease.cubic),
        });
        circle1.animate({
          key: "opacity",
          to: 0,
          from: 0.8,
          duration: bulletTime,
          easing: am5.ease.out(am5.ease.cubic),
        });
      }, delay);

      setTimeout(() => {
        circle0.animate({
          key: "opacity",
          from: 1,
          to: 0,
          duration: bulletTime * 2,
        });
      }, delay);

      return am5.Bullet.new(root, {
        sprite: container,
      });
    }
  });

  // let permanentLabel = chart.plotContainer.children.push(  );

  const latestPriceLabel = yAxis.labelsContainer.children.push(
    am5.Label.new(root, {
      paddingLeft: 0,
      fontSize: "12px",
      width: "100%",
    })
  );
  const latestPriceLabelHTML = /*html*/ `
  <div style="background-color: ${amChartColorDark.toCSS()}; font-size: 12px; padding: 0px 5px; padding-right:15px; height:fit-content; width: 50px; z-index:-1;">
    <div style="text-wrap:nowrap;">DaVal</div>
  </div>
`;

  const setLastestPriceLabelValue = ({ val, min, max }) => {
    let fxd = Math.ceil(2 - Math.log10(Math.abs(max - min)));
    fxd = Math.max(0, Math.min(fxd, 99));
    val = Number(Number(val).toFixed(fxd)).toLocaleString("us");
    latestPriceLabel.set("html", latestPriceLabelHTML.replace("DaVal", val));
  };

  const updatePriceLabel = _.debounce(
    (lastDataItem) => {
      const y = lastDataItem.get("point").y;
      latestPriceLabel.set("y", y - 15);

      const data = series.data;
      const val = data.getIndex(data.length - 1).value;
      setLastestPriceLabelValue({
        val,
        min: yAxis.positionToValue(0),
        max: yAxis.positionToValue(1),
      });
    },
    100,
    {}
  );

  series.data.events.on("push", () => {
    try {
      if (mouseOverChart) return;
      const lastDataItem = series.dataItems[series.dataItems.length - 1];
      if (!lastDataItem) return;
      if (lastDataItem.get("point")) {
        updatePriceLabel(lastDataItem);
      } else {
        lastDataItem.on("point", () => {
          updatePriceLabel(lastDataItem);
        });
      }
    } catch (e) {
      console.log({ e });
    }
  });
  cursor.events.on("cursormoved", ({ point }) => {
    const yOffset = 16;
    latestPriceLabel.set("y", point.y - yOffset * 2);

    const pos = 1 - (point.y - yOffset) / yAxis.height();
    const val = yAxis.positionToValue(pos);

    setLastestPriceLabelValue({
      val,
      min: yAxis.positionToValue(0),
      max: yAxis.positionToValue(1),
    });
  });

  chart.events.on("pointerover", () => {
    mouseOverChart = true;
  });

  chart.events.on("pointerout", () => {
    mouseOverChart = false;
    try {
      const lastDataItem = series.dataItems[series.dataItems.length - 1];
      if (!lastDataItem) return;

      if (lastDataItem.get("point")) {
        updatePriceLabel(lastDataItem);
      } else {
        lastDataItem.on("point", () => {
          updatePriceLabel(lastDataItem);
        });
      }
    } catch (e) {
      console.log({ e });
    }
  });

  // series.events.on("datasetchanged")

  series.appear(1000);
  chart.appear(1000, 100);

  return {
    root,
    series,
    chart,
    dispose: () => {
      root.dispose();
    },
  };
}
