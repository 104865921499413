import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import cogoToast from "cogo-toast";
import * as Sentry from "@sentry/react";

import App from "./app/App";
import "./i18n";
// import * as serviceWorker from "./serviceWorker";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./app/store/createStore";
import DataSourceProvider from "./contexts/datasource/provider";

if (process.env.REACT_APP_ENV === "prod") {
  Sentry.init({
    dsn: "https://477223a7b8f2f65912df9839768411ba@o4506162644713472.ingest.sentry.io/4506182461947904",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.25, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.25, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
} else if (process.env.REACT_APP_ENV === "dev") {
  Sentry.init({
    dsn: "https://718069155ea96234c386f47fe2e90428@o4506162644713472.ingest.sentry.io/4506176554336256",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.25, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.25, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter basename="/">
        <DataSourceProvider>
          <App />
        </DataSourceProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/sw.js")
    .then((reg) => console.log("service worker registered", reg))
    .catch((err) => console.log("service worker not registered", err));

  // Detects if device is on iOS
  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };
  // Detects if device is in standalone mode
  const isInStandaloneMode = () => "standalone" in window.navigator && window.navigator.standalone;

  // Checks if should display install popup notification:
  if (isIos() && !isInStandaloneMode()) {
    const { hide } = cogoToast.warn(
      <div>
        Install <b>MOMO</b> on your iPhone: tab <i className="mdi mdi-export-variant" style={{ fontSize: "1.2rem" }} />{" "}
        and then &nbsp;<b>Add To Home Screen</b>.
      </div>,
      {
        position: "top-center",
        hideAfter: 5,
        bar: {
          color: "white",
        },
        renderIcon: () => {
          return <></>;
        },
        onClick: () => {
          hide();
        },
      }
    );
  }
}

// serviceWorker.unregister();
