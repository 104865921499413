import React from "react";
import { useMediaQuery } from "react-responsive";
import { DEVICE_SIZES } from "../../constants";

function withScreenSizes(Component) {
  return function WrappedComponent(props) {
    const isSmallScreen = useMediaQuery({ query: DEVICE_SIZES.small });
    const isMediumScreen = useMediaQuery({ query: DEVICE_SIZES.medium });
    const isLargeScreen = useMediaQuery({ query: DEVICE_SIZES.large });

    return <Component {...props} {...{ isSmallScreen, isMediumScreen, isLargeScreen }} />;
  };
}

export default withScreenSizes;
