import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Popover, OverlayTrigger } from "react-bootstrap";
import styled from "styled-components";

import RadioButton, {
  ButtonLabel as RadioButtonLabel,
  ButtonWrapper as RadioButtonWrapper,
} from "../shared/Button/RadioButton";

import { StreamActions } from "../store";
import _ from "lodash";

const PopoverTriggerButton = styled.button`
  font-size: 20px;
  color: white;
  border: 0;
`;

const PopoverContentTimeframeOptionsWrapper = styled.div`
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${RadioButtonLabel} {
    color: white;
    margin-left: 0.25rem;
  }

  ${RadioButtonWrapper} {
    margin-left: 0.5em;
    :first-child {
      margin-left: 0;
    }
    margin-top: 0;
  }
`;

const PopoverContentVolumeText = styled.span`
  font-size: 14px;
  color: white;
  display: block;
  text-align: right;
`;

const PopoverContentTimeframeRow = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: row;
`;

const PopoverContentTimeframeText = styled(PopoverContentVolumeText)`
  margin-left: 11px;
  text-align: start;
`;
const PopoverContentMoreSettingsText = styled(PopoverContentVolumeText)`
  margin-top: 12px;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: #f6c548;
  }
`;

function StreamSettingPopover({ widget }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const shown = useSelector((state) => state.stream[widget].shown);

  const toggleItems = [
    { name: "Default", shown: { highs: true, lows: true }, key: "default" },
    { name: "Highs", shown: { highs: true, lows: false }, key: "highs" },
    { name: "Lows", shown: { highs: false, lows: true }, key: "lows" },
  ];

  const popover = (
    <Popover style={{ borderRadius: 12 }}>
      <Popover.Content style={{ minWidth: "fit-content", borderRadius: 12 }}>
        <PopoverContentTimeframeText>Display</PopoverContentTimeframeText>
        <PopoverContentTimeframeRow>
          <PopoverContentTimeframeOptionsWrapper>
            {toggleItems.map((item) => (
              <RadioButton
                key={item.key}
                label={item.name}
                checked={_.isEqual(shown, item.shown)}
                onClick={() => {
                  dispatch(StreamActions.updateShown(widget, item.shown));
                }}
              />
            ))}
          </PopoverContentTimeframeOptionsWrapper>
        </PopoverContentTimeframeRow>
        <PopoverContentMoreSettingsText onClick={() => history.push("/settings/stream")}>
          + More settings
        </PopoverContentMoreSettingsText>
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger trigger="click" placement="bottom" rootClose overlay={popover}>
      <PopoverTriggerButton className="bg-transparent" onClick={() => {}}>
        <i className="mdi mdi-settings" />
      </PopoverTriggerButton>
    </OverlayTrigger>
  );
}

export default StreamSettingPopover;
