import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";

import { isActiveSubscription } from "../constants";
import { AuthActions } from "../store";
import Api from "../api";
import i18n from "../../i18n";

class Login extends Component {
  state = {
    loginErrTxt: "",
  };

  componentDidMount() {
    const script = document.createElement("script");
    script.id = "g_auth_script";
    script.async = true;
    script.defer = true;
    script.src = "https://accounts.google.com/gsi/client";
    document.head.append(script);

    window.__g_login = (response) => {
      this.onGoogleLogin(response.credential);
    };
  }

  componentWillUnmount() {
    delete window.__g_login;
    document.getElementById("g_auth_script")?.remove();
    document.getElementById("googleidentityservice")?.remove();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.authenticated && nextProps.email_verified) {
      this.props.history.push("/dashboard");
    }
  }

  onLogin = () => {
    this.setState({ loginErrTxt: "" });
    const email = this.refEmail.value;
    const password = this.refPassword.value;
    this.props.setLoading(true);
    Api.login(email, password)
      .then(({ user, access_token }) => {
        this.props.setLoading(false);

        // Save Session
        Api.setSession(access_token);
        this.props.setUser(user);
        this.props.setAuthenticated(true);

        if (!user.email_verified) {
          this.props.history.push("/verify");
        } else if (!isActiveSubscription(user.subscription)) {
          this.props.history.push("/profile");
        } else {
          this.props.history.push("/dashboard");
        }
      })
      .catch((error) => {
        this.props.setLoading(false);
        this.props.setUser({});
        this.props.setAuthenticated(false);

        const errTxt = error.toString();
        let loginErrTxt;
        if (error.toString() === "TypeError: Failed to fetch") {
          loginErrTxt = "Service not available";
        } else {
          loginErrTxt = i18n.getResource("en", ["translations"], errTxt);
          if (!loginErrTxt) {
            loginErrTxt = "Unknown problem";
          }
        }

        this.setState({ loginErrTxt });
      });
  };

  onFacebook = () => {};

  onGoogleLogin = async (id_token) => {
    this.setState({ loginErrTxt: "" });

    try {
      const { user, access_token } = await Api.signInWithGoogle({
        id_token,
      });
      this.props.setLoading(false);
      Api.setSession(access_token);
      this.props.setUser(user);
      this.props.setAuthenticated(true);
      if (!isActiveSubscription(user.subscription)) {
        this.props.history.push("/profile");
      } else {
        this.props.history.push("/dashboard");
      }
    } catch (e) {
      this.props.setLoading(false);
      this.props.setUser({});
      this.props.setAuthenticated(false);

      console.error(e);
      this.setState({
        loginErrTxt: "Failed to sign in with Google, please try again",
      });
    }
  };

  render() {
    const { loginErrTxt } = this.state;
    return (
      <div className="d-flex align-items-md-center auth px-0 pt-4 pt-md-0 vh-100">
        <div className="row w-100 mx-0">
          <div className="col-xl-4 col-lg-6 mx-auto">
            <div className="card text-left py-5 px-4 px-sm-5 min-sm-520">
              <div className="brand-logo">
                <span className="pr-2">MOMO</span>
              </div>
              <h4>Sign Into your Account</h4>
              <h6 className="font-weight-light">You may use your registered details or social account</h6>
              <Form className="pt-3">
                <Form.Group>
                  <label>Email</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <i className="input-group-text mdi mdi-email text-momo-green" />
                    </div>
                    <Form.Control
                      type="text"
                      className="form-control text-light form-momo-green"
                      placeholder="Email"
                      ref={(ref) => {
                        this.refEmail = ref;
                      }}
                    />
                  </div>
                </Form.Group>

                <Form.Group>
                  <label>Password</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <i className="input-group-text mdi mdi-lock text-momo-green" />
                    </div>
                    <Form.Control
                      type="password"
                      className="form-control text-light form-momo-green"
                      placeholder="Password"
                      ref={(ref) => {
                        this.refPassword = ref;
                      }}
                    />
                  </div>
                </Form.Group>

                {loginErrTxt !== "" && <label className="text-danger">{`${loginErrTxt}`}</label>}
                <div className="mt-3">
                  <a
                    className="btn btn-block btn-momo-green btn-lg font-weight-medium auth-form-btn"
                    onClick={this.onLogin}
                  >
                    SIGN IN
                  </a>
                </div>
                <div className="my-2 d-flex justify-content-between align-items-center">
                  <div className="form-check">
                    <label className="form-check-label text-muted">
                      <input type="checkbox" className="form-check-input text-success" />
                      <i className="input-helper"></i>
                      Keep me signed in
                    </label>
                  </div>
                  <Link to="/forgot-password" className="auth-link text-momo-green">
                    Forgot password?
                  </Link>
                </div>
                <div className="row">
                  {/*<div className='mb-2 col'>
                   <button
                     type='button'
                     className='btn btn-block btn-facebook auth-form-btn'
                     onClick={this.onFacebook}
                   >
                     <i className='mdi mdi-facebook mr-2'></i>Facebook
                   </button>
                 </div>*/}

                  <div className="mb-2 col d-flex justify-content-center">
                    <div
                      id="g_id_onload"
                      data-client_id="4608974693-ks8bvj7359jmii5r1spuu64n92fhvmg0.apps.googleusercontent.com"
                      data-context="signin"
                      data-ux_mode="popup"
                      data-callback="__g_login"
                      data-auto_prompt="false"
                    ></div>

                    <div
                      className="g_id_signin"
                      data-type="standard"
                      data-shape="rectangular"
                      data-theme="outline"
                      data-text="signin_with"
                      data-size="large"
                      data-logo_alignment="left"
                    ></div>
                  </div>
                </div>

                <div className="text-center mt-4 font-weight-light">
                  Don't have an account?{" "}
                  <Link to="/register" className="text-momo-green">
                    Create now
                  </Link>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setAuthenticated: AuthActions.setAuthenticated,
  setLoading: AuthActions.setLoading,
  setUser: AuthActions.setUser,
};

const mapStateToProps = (state) => ({
  authenticated: state.auth.authenticated,
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
