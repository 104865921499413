import React, { Component } from "react";
import { connect } from "react-redux";
import cogoToast from "cogo-toast";

import { AuthActions } from "../../../store";
import Api from "../../../api";
import { validateEmail } from "../../../util";

class Account extends Component {
  state = {
    errTxt: "",
    username: "",
    email: "",
    password: "",
    confirm: "",
    loading: false,
  };

  componentDidMount() {
    console.info("User:", this.props.user);
    const { user } = this.props;
    this.setState({
      username: user.username,
      email: user.email,
    });
  }

  onSave = async () => {
    const { password, confirm, email, username } = this.state;
    const { setUser, user } = this.props;
    const data = {};

    if (!validateEmail(email)) {
      return cogoToast.error("Email address is invalid");
    }
    if (password) {
      // if (password.length < 4) { // Length
      //   return cogoToast.error('Please enter a password more than 4 characters')
      // }
      if (password != confirm) {
        return cogoToast.error("The passwords do not match");
      }
    }

    data.password = password;
    data.email = email;

    this.setState({ loading: true });
    cogoToast.loading("Please wait for a moment!");

    Api.updateUserData(data)
      .then((res) => {
        console.log(res);
        cogoToast.success("Profile updated successfully");

        this.setState({
          password: "",
          confirm: "",
        });

        user.email = email;
        setUser(user);
      })
      .catch((error) => {
        const errTxt = error.toString();
        let updateErrTxt;
        if (errTxt === "duplicate_account_active") {
          updateErrTxt = "Email is duplicated. Please try again with a different email address";
        } else {
          updateErrTxt = "Failed to update profile. Please try again";
        }
        cogoToast.error(updateErrTxt);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
    // const res = await Api.updateUserData(data)
    // console.info('Update User Data Result:', res)
    // if (res && res.email) {
    //   cogoToast.success('Profile Updated!')
    // } else {
    //   cogoToast.error('Update failed, please try again!')
    // }
  };

  render() {
    const { errTxt, username, email, password, confirm, loading } = this.state;

    return (
      <section>
        <h2 className="mb-4">Account Details</h2>
        {/* <div className="form-group">
          <label>Username</label>
          <input type="text" className="form-control" placeholder="Optional"
            value={username}
            onChange={(e) => {
              this.setState({
                username: e.target.value
              })
            }}
          />
        </div> */}
        <div className="form-group">
          <label>Email address</label>
          <input
            type="email"
            className="form-control"
            aria-describedby="emailHelp"
            placeholder="Enter email"
            value={email}
            onChange={(e) => {
              this.setState({
                email: e.target.value,
              });
            }}
            style={{ color: "white" }}
          />
          <small id="emailHelp" className="form-text text-muted">
            We'll never share your email with anyone else.
          </small>
        </div>
        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            className="form-control"
            placeholder="Password"
            value={password}
            onChange={(e) => {
              this.setState({
                password: e.target.value,
              });
            }}
            style={{ color: "white" }}
          />
        </div>
        <div className="form-group">
          <label>Confirm Password</label>
          <input
            type="password"
            className="form-control"
            placeholder="Confirm password"
            value={confirm}
            onChange={(e) => {
              this.setState({
                confirm: e.target.value,
              });
            }}
            style={{ color: "white" }}
          />
        </div>
        <div className="mt-3 d-flex justify-content-end">
          <button
            className="btn btn-primary btn-sm-block btn-lg font-weight-medium"
            onClick={this.onSave}
            disabled={loading}
          >
            Save
          </button>
        </div>
      </section>
    );
  }
}

const mapDispatchToProps = {
  setAuthenticated: AuthActions.setAuthenticated,
  setLoading: AuthActions.setLoading,
  setUser: AuthActions.setUser,
};

const mapStateToProps = (state, props) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(Account);
