import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export const ButtonLabel = styled.span`
  margin-left: 0.5rem;
  font-size: 12px;
  text-transform: uppercase;
  white-space: nowrap;
`;

export const ButtonBadge = styled.span`
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: gray;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 2em;
  margin-top: 0.5rem;
  cursor: pointer;

  @media only screen and (max-width: 576px) {
    & {
      width: unset;
      margin-left: unset;
      margin-right: 1.5rem;
    }
  }

  &.active {
    ${ButtonBadge} {
      background: #f6c548;
    }
  }
`;

function RadioButton({ className, label, checked, onClick }) {
  return (
    <ButtonWrapper className={`${checked ? "active" : ""} ${className || ""}`} onClick={() => onClick && onClick()}>
      <ButtonBadge className="custom-radio-badge"></ButtonBadge>
      <ButtonLabel className="custom-radio-label">{label}</ButtonLabel>
    </ButtonWrapper>
  );
}

RadioButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
};

export default RadioButton;
