let userInteracted = false;

export function canPlayMedia() {
  return navigator.userActivation?.hasBeenActive || userInteracted;
}

if (!navigator.userActivation || !("hasBeenActive" in navigator.userActivation)) {
  function onUserInteraction() {
    userInteracted = true;

    window.removeEventListener("mousedown", onUserInteraction, true);
    window.removeEventListener("keydown", onUserInteraction, true);
    window.removeEventListener("touchstart", onUserInteraction, true);
  }

  window.addEventListener("mousedown", onUserInteraction, true);
  window.addEventListener("keydown", onUserInteraction, true);
  window.addEventListener("touchstart", onUserInteraction, true);
}
