import React, { Component } from "react";
import "./StepRangeSlider.scss";
import PropTypes from "prop-types";
import classnames from "classnames";
import isEqual from "lodash/isEqual";
import { configureRange } from "./slider-utils";
import StepRangeHandler from "./StepRangeHandler";

export class StepSlider extends Component {
  constructor(props) {
    super(props);
    const range = configureRange(props.range);
    this.state = {
      range,
      value: props.value,
    };
    this.handleValueChange = this.handleValueChange.bind(this);
  }
  setInitialState = (props) => {
    const { value } = props;
    const range = configureRange(props.range);
    this.setState({ value, range });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.range && nextProps.range !== this.props.range) {
      this.setInitialState(nextProps);
    }
    if (nextProps.value !== this.props.value && nextProps.value !== this.state.value) {
      this.setState({ value: nextProps.value });
    }
  }

  handleValueChange(value) {
    const { onChange } = this.props;
    this.setState({ value }, () => {
      onChange(this.state.value);
    });
  }

  render() {
    const { id, name, disabled, tooltip, className, showTooltip, tooltipFormat } = this.props;
    const { range, value } = this.state;

    return (
      <div
        className={classnames("StepRangeSlider", className, { disabled })}
        onMouseDown={this.handleMouseDown}
        ref={(node) => (this.slider = node)}
      >
        <div className="SliderBar" />
        <div className="StepRangeSlider__track" />
        <StepRangeHandler
          range={range}
          values={{ high: value }}
          identifier="high"
          onChangeComplete={this.handleValueChange}
          showTooltip={!!showTooltip}
          tooltipFormat={tooltipFormat}
        />
        <input type="hidden" id={id} name={name} disabled={disabled} />
      </div>
    );
  }
}

StepSlider.displayName = "StepSlider";

StepSlider.propTypes = {
  children: PropTypes.any,
  value: PropTypes.number.isRequired,
  defaultValue: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onChangeComplete: PropTypes.func,
  range: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      step: PropTypes.number,
    }).isRequired
  ).isRequired,
};

StepSlider.defaultProps = {
  defaultValue: 0,
  range: [{ value: 0, step: 1 }, { value: 100 }],
  children: (value) => <div className="StepRangeSlider__tooltip">{value}</div>,
};

export default StepSlider;
