import * as firebase from "firebase/app";

if (process.env.REACT_APP_ENV === "prod") {
  const firebaseConfig = {
    apiKey: "AIzaSyCCBtyETNzMNPUrCF4O5qzLi3TgPjGtDA0",
    authDomain: "momopro-prod.firebaseapp.com",
    databaseURL: "https://momopro-prod.firebaseio.com",
    projectId: "momopro-prod",
    storageBucket: "momopro-prod.appspot.com",
    messagingSenderId: "282226852728",
    appId: "1:282226852728:web:10d7f1ffee15d74edefe8d",
    measurementId: "G-Y49SKXGN3E",
  };

  firebase.initializeApp(firebaseConfig);
} else {
  const firebaseConfig = {
    apiKey: "AIzaSyDpV0LYg4hF8_WFUca9Lo6_PNlHZk44-jM",
    authDomain: "momoweb-bfe4a.firebaseapp.com",
    databaseURL: "https://momoweb-bfe4a.firebaseio.com",
    projectId: "momoweb-bfe4a",
    storageBucket: "momoweb-bfe4a.appspot.com",
    messagingSenderId: "4608974693",
    appId: "1:4608974693:web:2819c21f46358a0f014b8c",
  };

  firebase.initializeApp(firebaseConfig);
}
