import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { ReactSortable } from "react-sortablejs";

import CollapsibleWrapper from "../CollapsibleWrapper";
import UpgradeButton from "../../shared/Button/UpgradeButton";
import {
  DISCOVERY_COLUMN_LABELS,
  DISCOVERY_DISPLAY_PERCENT_CHANGE,
  DISCOVERY_DISPLAY_DOLLAR_CHANGE,
  isActiveSubscription,
  isPro,
  isProNew,
  isProPlusNew,
  DEFAULT_DISCOVERY_COLUMNS,
} from "../../constants";

import { Types as ConfigTypes } from "../../store/config";
import { getActiveLayoutConfig } from "../../dashboard/dashboardReducer";

import styles from "./index.module.scss";

function DiscoverySettings(props) {
  const { setting } = props;

  const dispatch = useDispatch();
  const {
    auth,
    dashboard: { layout },
  } = useSelector((state) => state);

  const { menu } = getActiveLayoutConfig(layout);

  // if (!menu[setting.id]) {
  //   return (
  //   <div className={styles['setting-wrapper']}>
  //     <div className="d-flex align-items-center">
  //       <label className="small mr-2 mb-0 setting-panel-label-1">{setting.title}</label>
  //       <label className="small mb-0 mt-1">
  //         <span className="text-symbol">(Disabled)</span>
  //       </label>
  //       </div>
  //   </div>
  //   )
  // }

  const plan = {
    isPro:
      isActiveSubscription(auth.user.subscription) &&
      (isPro(auth.user.subscription.plan) || isProNew(auth.user.subscription.plan)),
    isProOld: isActiveSubscription(auth.user.subscription) && isPro(auth.user.subscription.plan),
    isProPlus: isActiveSubscription(auth.user.subscription) && isProPlusNew(auth.user.subscription.plan),
  };

  const [discoverySaving, setDiscoverySaving] = useState(false);

  const isColumnIncluded = (column) => {
    if (column === "squeeze" || column === "moneyflow") {
      return plan.isProPlus || plan.isProOld;
    }
    if (
      column === "trend" ||
      column === "short_ratio" ||
      column === "atr" ||
      column === "gap" ||
      column === "tradeCount"
    ) {
      return plan.isProPlus;
    }
    return true;
  };

  const hasDisplayOption = (column) => {
    return column === "price_dist" || column === "atr" || column === "gap";
  };

  const toggleColumnDisplayOption = (item, option) => {
    const columns = setting.value || DEFAULT_DISCOVERY_COLUMNS;

    dispatch({
      type: ConfigTypes.UPDATE_DISCOVERY_COLUMNS,
      value: {
        id: setting.id,
        columns: columns.map((item1) => {
          if (item1.column !== item.column) return item1;
          return {
            ...item1,
            display: option,
          };
        }),
      },
    });

    setDiscoverySaving(false);
    setTimeout(() => setDiscoverySaving(true), 100);
  };

  const toggleColumn = (item) => {
    const columns = setting.value || DEFAULT_DISCOVERY_COLUMNS;

    dispatch({
      type: ConfigTypes.UPDATE_DISCOVERY_COLUMNS,
      value: {
        id: setting.id,
        columns: columns.map((item1) => {
          if (item1.column === item.column) {
            return {
              ...item1,
              hidden: !item1.hidden,
            };
          }
          return item1;
        }),
      },
    });

    setDiscoverySaving(false);
    setTimeout(() => setDiscoverySaving(true), 100);
  };

  const activeColumns = setting.value.filter((item) => isColumnIncluded(item.column));
  const inactiveColumns = setting.value.filter((item) => !isColumnIncluded(item.column));

  return (
    <div className={styles["setting-wrapper"]}>
      <CollapsibleWrapper
        title={setting.title}
        labelClassName="setting-panel-label-1"
        storeKey="discovery"
        storeSubKey={setting.id}
        triggerAnim={discoverySaving}
        dimmed={!(plan.isPro || plan.isProPlus)}
      >
        {plan.isPro || plan.isProPlus ? (
          <>
            <div className={"d-flex justify-content-between align-items-center item-content mt-1 pl-2 py-1"}>
              <div className="d-flex align-items-center">
                <i className={`${styles["sort-handler"]} mdi mdi-pin`} style={{ color: "gray" }}></i>
                <span className="small">Symbol</span>
              </div>
            </div>
            <ReactSortable
              animation={200}
              handle={`.${styles["sort-handler"]}`}
              list={activeColumns}
              setList={(newState) => {
                if (plan.isProPlus) {
                  dispatch({
                    type: ConfigTypes.UPDATE_DISCOVERY_COLUMNS,
                    value: {
                      id: setting.id,
                      columns: newState,
                    },
                  });
                } else {
                  dispatch({
                    type: ConfigTypes.UPDATE_DISCOVERY_COLUMNS,
                    value: {
                      id: setting.id,
                      columns: [
                        ...newState,
                        {
                          column: "moneyflow",
                          hidden: false,
                        },
                        {
                          column: "squeeze",
                          hidden: false,
                        },
                      ],
                    },
                  });
                }
                let shouldAnimate = false;
                for (let i = 0; i < activeColumns.length; i++) {
                  if (activeColumns[i].column != newState[i].column) {
                    shouldAnimate = true;
                    break;
                  }
                  if (activeColumns[i].hidden != newState[i].hidden) {
                    shouldAnimate = true;
                    break;
                  }
                }
                if (shouldAnimate) {
                  setDiscoverySaving(false);
                  setTimeout(() => setDiscoverySaving(true), 100);
                }
              }}
            >
              {activeColumns.map((item) => {
                let hasExtraOptions;
                if (hasDisplayOption(item.column)) {
                  hasExtraOptions = true;
                }
                return (
                  <div key={item.column} className={"mb-1"}>
                    <div
                      className={`${styles["discovery-column-block"]} align-items-center item-content mt-1 px-2 py-1 row mx-0`}
                    >
                      <div
                        className={`d-flex align-items-center ${
                          hasExtraOptions ? "col-6 col-sm-3" : "col-6"
                        } order-1 px-0`}
                      >
                        <i className={`${styles["sort-handler"]} mdi mdi-menu`}></i>
                        <span className="small" style={{ whiteSpace: "nowrap" }}>
                          {DISCOVERY_COLUMN_LABELS[item.column]}
                        </span>
                      </div>
                      {hasDisplayOption(item.column) && (
                        <div
                          className={`${styles["discovery-display-option"]} col-12 col-sm-6 d-flex justify-content-start align-items-center order-3 order-sm-2`}
                        >
                          <span className="custom-radio-wrapper-label">Display: </span>
                          {[DISCOVERY_DISPLAY_PERCENT_CHANGE, DISCOVERY_DISPLAY_DOLLAR_CHANGE].map((option) => {
                            const isActive = item.display
                              ? item.display === option
                              : DISCOVERY_DISPLAY_PERCENT_CHANGE === option;
                            return (
                              <div
                                key={item.column + option}
                                className={`custom-radio-wrapper ${isActive ? "active" : ""}`}
                                style={{ width: "unset" }}
                                onClick={() => toggleColumnDisplayOption(item, option)}
                              >
                                <span className="custom-radio-badge"></span>
                                <span className="custom-radio-label">{option} CHANGE</span>
                              </div>
                            );
                          })}
                        </div>
                      )}
                      <div
                        className={`d-flex justify-content-end align-items-center ${
                          hasExtraOptions ? "col-6 col-sm-3 order-2 order-sm-12" : "col-6 order-12"
                        } px-0`}
                      >
                        {item.hidden ? (
                          <div
                            className={"btn-toggle-state"}
                            onClick={() => {
                              toggleColumn(item);
                            }}
                          >
                            Disabled
                          </div>
                        ) : (
                          <div
                            className={"btn-toggle-state active"}
                            onClick={() => {
                              toggleColumn(item);
                            }}
                          >
                            Enabled
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </ReactSortable>
            {inactiveColumns.map((item) => (
              <div key={item.column} className={"mb-1"}>
                <div className={"d-flex justify-content-between align-items-center item-content mt-1 pl-2 py-1"}>
                  <div className="d-flex align-items-center text-symbol">
                    <i className={`${styles["sort-handler"]} mdi mdi-menu`}></i>
                    <span className="small" style={{ whiteSpace: "nowrap" }}>
                      {DISCOVERY_COLUMN_LABELS[item.column]}
                    </span>
                  </div>
                  <div className={"d-flex justify-content-end align-items-center flex-grow-1"}>
                    <UpgradeButton label="PRO + ONLY" />
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <UpgradeButton label="PRO ONLY" />
        )}
      </CollapsibleWrapper>
    </div>
  );
}

DiscoverySettings.propTypes = {
  setting: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    value: PropTypes.array,
  }),
};

export default DiscoverySettings;
