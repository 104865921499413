import React from "react";
import { Menu, Item } from "react-contexify";
import { onPopover, registerAlert } from "../helper";
import { connect } from "react-redux";
import { QuoteActions } from "../../store";
import { SYMBOL_CONTEXT_MENU_ID } from "../../constants";
import "./MenuItems.scss";
import SymbolDetail from "../SymbolPopup/symbol-detail";

class Items extends React.Component {
  render() {
    const { registerQuote, menuIdKey: key, data, type, symbol: selectedSymbol } = this.props;
    return (
      <Menu id={SYMBOL_CONTEXT_MENU_ID} className="p-0" theme={"dark"}>
        <div className="context-menu-style">
          <div className="mt-2" />
          {selectedSymbol && <SymbolDetail symbol={selectedSymbol} mode="context-menu" />}
          <div className="my-1 symbol-context-menu-divider"></div>
          <Item
            data={{ data, type, domain: "cnbc" }}
            onClick={() => onPopover("cnbc", key !== "discovery-context-menu" ? data[0] : selectedSymbol)}
          >
            <div className="row align-items-center mt-1">
              <img
                className={"context-menu-item-icon-style"}
                style={{ height: 16, width: 25 }}
                src={require("../../../assets/images/dashboard/cnbc.png")}
              />
              <span className="medium white-no-wrap bar-txt">CNBC</span>
            </div>
          </Item>
          <Item
            data={{ data, type, domain: "marketwatch" }}
            onClick={() => onPopover("marketwatch", key !== "discovery-context-menu" ? data[0] : selectedSymbol)}
          >
            <div className="row align-items-center mt-1">
              <img
                className={"context-menu-item-icon-style"}
                style={{ height: 13, width: 25 }}
                src={require("../../../assets/images/dashboard/marketwatch.png")}
              />
              <span className="medium white-no-wrap bar-txt">MarketWatch</span>
            </div>
          </Item>
          <Item
            data={{ data, type, domain: "seekingalpha" }}
            onClick={() => onPopover("seekingalpha", key !== "discovery-context-menu" ? data[0] : selectedSymbol)}
          >
            <div className="row align-items-center mt-1">
              <img
                className={"context-menu-item-icon-style"}
                style={{ height: 22, width: 25 }}
                src={require("../../../assets/images/dashboard/seekingalpha.png")}
              />
              <span className="medium white-no-wrap bar-txt">Seeking Alpha</span>
            </div>
          </Item>
          <Item
            data={{ data, type, domain: "nasdaq" }}
            onClick={() => onPopover("nasdaq", key !== "discovery-context-menu" ? data[0] : selectedSymbol)}
          >
            <div className="row align-items-center mt-1">
              <i
                className="mdi mdi-chart-line-variant popover-icon context-menu-item-icon-style"
                style={{ height: 21, width: 25, marginTop: -10 }}
              />
              <span className="medium white-no-wrap bar-txt">Nasdaq</span>
            </div>
          </Item>
          <Item
            data={{ data, type, domain: "stocktwits" }}
            onClick={() => onPopover("stocktwits", key !== "discovery-context-menu" ? data[0] : selectedSymbol)}
          >
            <div className="row align-items-center mt-1">
              <img
                className={"context-menu-item-icon-style"}
                style={{ height: 25, width: 24 }}
                src={require("../../../assets/images/dashboard/stocktwits.png")}
              />
              <span className="medium white-no-wrap bar-txt">Stocktwits</span>
            </div>
          </Item>
          <div className="my-1 symbol-context-menu-divider"></div>
          <div className="row justify-content-between align-items-center">
            <Item
              data={{ data, type }}
              onClick={async () => {
                await registerAlert(
                  key === "discovery-context-menu" ? selectedSymbol : data[0],
                  "trade",
                  type === "high" ? data[1] : 0,
                  type === "low" ? data[1] : 0
                );
              }}
            >
              <div className="row justify-content-center align-items-center">
                <i className="mdi mdi-bell text-white popover-icon" />
                <span className="ml-1">Alert</span>
              </div>
            </Item>
            <Item
              data={{ data, type }}
              onClick={() => {
                console.info("Item.registerQuote - ", data, type);
                registerQuote([key === "discovery-context-menu" ? selectedSymbol : data[0]]);
              }}
            >
              <div className="row justify-content-center align-items-center">
                <i className="mdi mdi-star text-white popover-icon" />
                <span className="ml-1">Favorites</span>
              </div>
            </Item>
          </div>
        </div>
      </Menu>
    );
  }
}

const mapDispatchToProps = {
  registerQuote: QuoteActions.registerQuote,
};

const mapStateToProps = (state, props) => ({
  symbol: state.dashboard.contextMenuSymbol,
  ...props,
});

export default connect(mapStateToProps, mapDispatchToProps)(Items);
