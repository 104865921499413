import React, { Component } from "react";
import { connect } from "react-redux";
import Swiper from "react-id-swiper";
import cogoToast from "cogo-toast";
import { ReactSortable } from "react-sortablejs";
import { Sparklines, SparklinesCurve } from "react-sparklines";
import PropTypes from "prop-types";
import _ from "lodash";

import { QuoteActions, DashboardActions, ConfigActions } from "../store";
import { store } from "../store/createStore";
import { getActiveLayoutConfig } from "../dashboard/dashboardReducer";

import { withDataSource } from "../../contexts/datasource/hoc/withSocket";

import Symbol from "../shared/Symbol";
import { round } from "../shared/helper";
import { getDecimal, getInteger } from "../util";

import API from "../api";
import uploadCurrentConfig from "../shared/Utilities/config";

class QuoteContent extends Component {
  state = {
    expanded: false,
    sortToolExpanded: false,
    sortableElemKey: 1,
  };

  quoteRefs = {};

  sortableInitialized = false;
  prevLayoutLocked = null;
  prevQuotes = null;
  storeUnsubscribe = null;
  quotesPendingSaveTimeoutId = null;
  statsTimer = null;
  sortToolAutoCloseTimeoutId = null;

  constructor(props) {
    super(props);

    this.onCompressedUpdate = this.onCompressedUpdate.bind(this);
    this.onRealtimeData = this.onRealtimeData.bind(this);
  }

  updateSortMethod(sortMethod) {
    const { updateQuoteConfig } = this.props;
    updateQuoteConfig({ sortMethod });
    uploadCurrentConfig();
  }

  componentDidMount() {
    this.props.fetchQuotes();

    this.statsTimer = setInterval(() => {
      this.props.fetchQuotes();
    }, 1 * 60 * 1000); // Update Every 3 minutes

    // Register callback for stream
    if (this.props.datasource.primary) {
      this.onPrimaryDatasourceInit();
    }
    if (this.props.datasource.realtime) {
      this.onRealtimeDatasourceInit();
    }

    this.prevQuotes = (store.getState().quote.quotes || []).map((item) => item.symbol);
    this.prevLayoutLocked = getActiveLayoutConfig(store.getState().dashboard.layout, this.props.setLayoutActive).locked;
    this.storeUnsubscribe = store.subscribe(() => {
      const { quote, auth, dashboard } = store.getState();
      if (!auth.authenticated) {
        return;
      }
      const currentQuotes = (quote.quotes || []).map((item) => item.symbol);
      if (currentQuotes.length === this.prevQuotes.length) {
        const prevQuotesStr = this.prevQuotes.join(",");
        const currentQuotesStr = currentQuotes.join(",");
        if (prevQuotesStr !== currentQuotesStr) {
          this.storeQuotesOrderChanged(currentQuotes);
        }
      }
      this.prevQuotes = [...currentQuotes];
      const layoutLocked = getActiveLayoutConfig(dashboard.layout, this.props.setLayoutActive).locked;
      if (layoutLocked !== this.prevLayoutLocked) {
        this.setState({
          sortableElemKey: this.state.sortableElemKey + 1,
        });
      }
      this.prevLayoutLocked = layoutLocked;
    });
  }

  componentWillUnmount() {
    this.props.datasource.primary?.off("compressedUpdate", this.onCompressedUpdate);
    this.props.datasource.realtime?.off("quotes", this.onRealtimeData);

    !this.storeUnsubscribe || this.storeUnsubscribe();
    if (this.quotesPendingSaveTimeoutId) {
      this.updateQuotesOrder(this.prevQuotes);
      clearTimeout(this.quotesPendingSaveTimeoutId);
      this.quotesPendingSaveTimeoutId = null;
    }
    if (this.sortToolAutoCloseTimeoutId) {
      clearTimeout(this.sortToolAutoCloseTimeoutId);
    }
    if (this.statsTimer) {
      clearInterval(this.statsTimer);
      this.statsTimer = null;
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.datasource.primary && this.props.datasource.primary !== prevProps.datasource.primary) {
      this.onPrimaryDatasourceInit();
    }
    if (this.props.datasource.realtime && this.props.datasource.realtime !== prevProps.datasource.realtime) {
      this.onRealtimeDatasourceInit();
    }
  }

  onPrimaryDatasourceInit() {
    this.props.datasource.primary.on("compressedUpdate", this.onCompressedUpdate);
  }

  onRealtimeDatasourceInit() {
    this.props.datasource.realtime.on("quotes", this.onRealtimeData);
  }

  onCompressedUpdate = (event) => {
    const data = event.detail;

    let msg = data[0];
    let highs = msg[1];
    let lows = msg[2];
    highs.concat(lows).map((item) => this.blinkSymbol(item[0]));
  };

  onRealtimeData = (event) => {
    const data = event.detail;
    if (Array.isArray(data)) {
      const { quotes, updateQuotes } = this.props;
      const transformedQuotes = [];
      for (const item of data) {
        if (item?.s && item.l !== undefined) {
          // this.blinkSymbol(item.s);

          const quoteItem = (quotes || []).find((quoteItem) => quoteItem.symbol === item.s);
          if (quoteItem) {
            const updateQuoteItem = {
              ...quoteItem,
            };
            updateQuoteItem.price = Number(item.l);
            if (!isNaN(updateQuoteItem.high) && updateQuoteItem.price > updateQuoteItem.high) {
              updateQuoteItem.high = updateQuoteItem.price;
            }
            if (!isNaN(updateQuoteItem.low) && updateQuoteItem.price < updateQuoteItem.low) {
              updateQuoteItem.low = updateQuoteItem.price;
            }
            if (Array.isArray(item.p)) {
              updateQuoteItem.percent = item.p[0];
              updateQuoteItem.dollar_dist = item.p[1];
            }
            if (Array.isArray(quoteItem.sl) && quoteItem.sl[quoteItem.sl.length - 1] == quoteItem.price) {
              updateQuoteItem.sl[updateQuoteItem.sl.length - 1] = updateQuoteItem.price;
            }
            if (updateQuoteItem.pin_set) {
              const splitRatio =
                isNaN(updateQuoteItem.pin_splitr) || updateQuoteItem.pin_splitr == 0 ? 1 : updateQuoteItem.pin_splitr;
              if (!isNaN(updateQuoteItem.price) && !isNaN(updateQuoteItem.pin) && updateQuoteItem.pin > 0) {
                updateQuoteItem.pin_percent =
                  ((updateQuoteItem.price / splitRatio - updateQuoteItem.pin) / updateQuoteItem.pin) * 100;
                updateQuoteItem.pin_percent = updateQuoteItem.pin_percent.toFixed(2);
              }
            } else {
              updateQuoteItem.pin = updateQuoteItem.price;
            }
            // TODO: update pin point
            transformedQuotes.push(updateQuoteItem);
          }
        }
      }
      updateQuotes(transformedQuotes);
    }
  };

  blinkSymbol = (symbol) => {
    if (!this.quoteRefs[symbol]) {
      return;
    }
    const quoteRef = this.quoteRefs[symbol];
    for (let key in quoteRef.refs) {
      const ref = quoteRef.refs[key];
      if (ref) {
        const elemSymbol = ref.querySelector(".quote-symbol");
        elemSymbol.classList.add("symbol-blink");
      }
    }

    if (!quoteRef.timerId) {
      let timeout = 5 * 1000;
      quoteRef.timerId = setTimeout(() => {
        for (let key in quoteRef.refs) {
          const ref = quoteRef.refs[key];
          if (ref) {
            const elemSymbol = ref.querySelector(".quote-symbol");
            elemSymbol.classList.remove("symbol-blink");
          }
        }
        quoteRef.timerId = null;
      }, timeout);
    }

    // let timeout = 5 * 1000;
    // const currentTimestamp = (new Date()).getTime();
    // let shouldResetTimer = true;
    // if (quoteRef.timerId && quoteRef.timeout) {
    //   if (quoteRef.timeout - currentTimestamp >= 5 * 1000) {
    //     shouldResetTimer = false;
    //   } else {
    //     timeout += quoteRef.timeout - currentTimestamp;
    //   }
    // }
    // if (shouldResetTimer) {
    //   if (quoteRef.timerId) {
    //     clearTimeout(quoteRef.timerId);
    //   }
    //   quoteRef.timerId = setTimeout(() => {
    //     for (let key in quoteRef.refs) {
    //       const ref = quoteRef.refs[key];
    //       if (ref) {
    //         const elemSymbol = ref.querySelector(".quote-symbol");
    //         elemSymbol.classList.remove("symbol-blink");
    //       }
    //     }
    //     quoteRef.timerId = null;
    //     quoteRef.timeout = null;
    //   }, timeout);
    //   quoteRef.timeout = (new Date()).getTime() + timeout;
    // }
  };

  storeQuotesOrderChanged = async (quotes) => {
    if (this.quotesPendingSaveTimeoutId) {
      clearTimeout(this.quotesPendingSaveTimeoutId);
    }
    this.quotesPendingSaveTimeoutId = setTimeout(async () => {
      this.quotesPendingSaveTimeoutId = null;
      this.updateQuotesOrder(quotes);
    }, 2000);
  };

  updateQuotesOrder = async (quotes) => {
    try {
      const symbolList = quotes || [];
      const res = await API.updateQuotesOrder(symbolList);
      if (!res.success) {
        throw "error";
      }
    } catch (e) {
      cogoToast.error(`Failed to update quotes order`);
    }
  };

  renderSparklines = (points, is_high) => {
    const color = is_high ? "#00ff6e" : "#fb0410";
    const data = points || [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    return (
      <Sparklines data={data} limit={data.length} height={45} margin={5} style={{ marginLeft: 3 }}>
        <SparklinesCurve color={color} style={{ strokeWidth: 2, stroke: color }} />
        {/* <SparklinesReferenceLine type="avg" style={{ strokeWidth: 1, stroke: color, fill: "none" }} /> */}
      </Sparklines>
    );
  };

  renderPinPrice = (item) => {
    let percent = 0;
    const { symbol, price, pin, pin_percent } = item;
    // if (price && pin) {
    //   percent = (price - pin) / pin * 100
    //   if (!isNaN(percent)) {
    //     percent = percent.toFixed(1)
    //   }
    // }
    if (!isNaN(pin_percent)) {
      percent = Number(pin_percent).toFixed(1);
    }
    return (
      <>
        <div
          className="btn btn-quote-pin"
          onClick={(e) => {
            const { currentTarget } = e;
            if (currentTarget.classList.contains("rotate")) {
              return;
            }
            currentTarget.classList.add("rotate");
            setTimeout(() => {
              this.props.resetQuotePin({
                symbol,
                pin: price,
              });
            }, 1000);
            setTimeout(() => {
              currentTarget.classList.remove("rotate");
            }, 2000);
          }}
        >
          <i className="fa fa-map-marker" aria-hidden="true"></i>
        </div>
        <label
          className="font-14 dash-font-color ml-1 mb-0"
          style={{
            ...(percent > 0 ? { color: "#00d25b" } : {}),
            ...(percent < 0 ? { color: "#fc424a" } : {}),
            whiteSpace: "nowrap",
          }}
        >
          {percent > 0 ? `+${round(percent, 1)}%` : `${round(percent, 1)}%`}
        </label>
      </>
    );
  };

  renderSwiperQuoteCards = () => {
    let renderCards = [];
    this.getSortedQuotes().map((item, index) => {
      renderCards.push(
        <div
          key={`render-cards-${index}-${item.symbol}`}
          ref={(ref) => {
            if (!this.quoteRefs[item.symbol]) {
              this.quoteRefs[item.symbol] = {
                refs: {},
              };
            }
            this.quoteRefs[item.symbol].refs["swiper"] = ref;
          }}
          className="quote-card"
        >
          <div className="card p-1 overflow-hidden">
            <div className="horizontal-quote-container">
              <div className="d-flex flex-column justify-content-between flex-grow-1">
                <div className="card-padding pr-0 mb-1">
                  <label className="mb-0 font-weight-bold font-20" style={{ cursor: "pointer" }}>
                    <Symbol symbol={item.symbol} />
                  </label>
                  <div
                    className="d-inline-block remove-cursor pl-0 ml-2 mb-0"
                    onClick={() => this.props.removeFromQuotes(item.symbol)}
                  >
                    <i className="mdi mdi-star quote-star d-block" style={{ position: "absolute", top: "0px" }} />
                  </div>
                </div>
                <div className="quote-label-container flex-grow-1">
                  <div
                    className="current-price"
                    style={{
                      color: item.percent > 0 ? "#00d25b" : "#fc424a",
                      ...(item.price >= 1000
                        ? {
                            transform: "scaleX(0.95)",
                            marginLeft: 2,
                          }
                        : {}),
                    }}
                  >
                    <span>{`${round(item.price, 2)}`}</span>
                    <table className="quote-change-values">
                      <tbody>
                        <tr>
                          <td>{getInteger(round(item.dollar_dist, 2))}</td>
                          <td>{getDecimal(round(item.dollar_dist, 2), 2)}</td>
                        </tr>
                        <tr>
                          <td>{getInteger(round(item.percent, 1))}</td>
                          <td>{getDecimal(round(item.percent, 1))}%</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {this.renderSparklines(item.sl, item.percent > 0)}
                </div>
              </div>
              <div className="d-flex flex-column justify-content-end">
                <div className="no-wrap d-flex flex-row justify-content-between align-items-center mb-1">
                  {this.renderPinPrice(item)}
                </div>
                <div className="no-wrap d-flex flex-row justify-content-between align-items-center mb-0">
                  <label className="quote-status-label mb-0">
                    <span>H</span>
                    <span>:</span>
                  </label>
                  <label className="font-14 dash-font-color ml-1 mb-0">{`${round(item.high, 2)}`}</label>
                </div>
                <div className="no-wrap d-flex flex-row justify-content-between align-items-center">
                  <label className="quote-status-label mb-0">
                    <span>L</span>
                    <span>:</span>
                  </label>
                  <label className="font-14 dash-font-color ml-1 mb-0">{`${round(item.low, 2)}`}</label>
                </div>
              </div>
            </div>
          </div>
          <div className="bullets-section" />
        </div>
      );
    });

    return renderCards;
  };

  renderDropdownQuoteCards = () => {
    let renderCards = [];
    this.getSortedQuotes().map((item, index) => {
      renderCards.push(
        <div
          key={`render-cards-${index}-${item.symbol}`}
          ref={(ref) => {
            if (!this.quoteRefs[item.symbol]) {
              this.quoteRefs[item.symbol] = {
                refs: {},
              };
            }
            this.quoteRefs[item.symbol].refs["dropdown"] = ref;
          }}
          className="quote-card"
        >
          <div className="card p-1 overflow-hidden">
            <i className="handler mdi mdi-drag-horizontal"></i>
            <div className="horizontal-quote-container">
              <div className="d-flex flex-column justify-content-between flex-grow-1">
                <div className="card-padding pr-0 mb-1">
                  <label className="mb-0 font-weight-bold font-20" style={{ cursor: "pointer" }}>
                    <Symbol symbol={item.symbol} />
                  </label>
                  <div
                    className="d-inline-block remove-cursor pl-0 ml-2 mb-0"
                    onClick={() => this.props.removeFromQuotes(item.symbol)}
                  >
                    <i className="mdi mdi-star quote-star d-block" style={{ position: "absolute", top: "0px" }} />
                  </div>
                </div>
                <div className="quote-label-container flex-grow-1">
                  <div
                    className="current-price"
                    style={{
                      color: item.percent > 0 ? "#00d25b" : "#fc424a",
                      ...(item.price >= 1000
                        ? {
                            transform: "scaleX(0.95)",
                            marginLeft: 2,
                          }
                        : {}),
                    }}
                  >
                    <span>{`${round(item.price, 2)}`}</span>
                    <table className="quote-change-values">
                      <tbody>
                        <tr>
                          <td>{getInteger(round(item.dollar_dist, 2))}</td>
                          <td>{getDecimal(round(item.dollar_dist, 2), 2)}</td>
                        </tr>
                        <tr>
                          <td>{getInteger(round(item.percent, 1))}</td>
                          <td>{getDecimal(round(item.percent, 1))}%</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {this.renderSparklines(item.sl, item.percent > 0)}
                </div>
              </div>
              <div className="d-flex flex-column justify-content-end">
                <div className="no-wrap d-flex flex-row justify-content-between align-items-center mb-1">
                  {this.renderPinPrice(item)}
                </div>
                <div className="no-wrap d-flex flex-row justify-content-between align-items-center">
                  <label className="quote-status-label mb-0">
                    <span>H</span>
                    <span>:</span>
                  </label>
                  <label className="font-14 dash-font-color ml-1 mb-0">{`${round(item.high, 2)}`}</label>
                </div>
                <div className="no-wrap d-flex flex-row justify-content-between align-items-center">
                  <label className="quote-status-label mb-0">
                    <span>L</span>
                    <span>:</span>
                  </label>
                  <label className="font-14 dash-font-color ml-1 mb-0">{`${round(item.low, 2)}`}</label>
                </div>
              </div>
            </div>
          </div>
          <div className="bullets-section" />
        </div>
      );
    });

    return renderCards;
  };

  renderSortTools = () => {
    const { sortMethod } = this.props;
    const { sortToolExpanded } = this.state;
    return (
      <div className={`quote-sort-tools-wrapper ${sortToolExpanded ? "expanded" : ""}`}>
        <div className="quote-sort-tools">
          <span className={`badge-toggle-indicator mr-1 ${sortMethod === "a" ? "active" : ""}`}></span>
          <a
            className="btn-toggle-link"
            style={{ fontSize: "18px" }}
            onClick={() => {
              this.updateSortMethod("a");
            }}
          >
            A
          </a>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <a
            className="btn-toggle-link"
            style={{ fontSize: "18px" }}
            onClick={() => {
              this.updateSortMethod("%");
            }}
          >
            %
          </a>
          <span className={`badge-toggle-indicator ml-1 ${sortMethod === "%" ? "active" : ""}`}></span>
          <a
            className="expand-tools-icon"
            onClick={() => {
              this.setState({
                sortToolExpanded: !sortToolExpanded,
              });
              if (sortToolExpanded) {
                if (this.sortToolAutoCloseTimeoutId) {
                  clearTimeout(this.sortToolAutoCloseTimeoutId);
                }
                this.sortToolAutoCloseTimeoutId = null;
              } else {
                this.sortToolAutoCloseTimeoutId = setTimeout(() => {
                  this.setState({
                    sortToolExpanded: false,
                  });
                }, 10000);
              }
            }}
          >
            {/* <i className="mdi mdi-chevron-right cursor-pointer"></i> */}
            <span>{">"}</span>
          </a>
        </div>
      </div>
    );
  };

  getSortedQuotes = () => {
    const { sortMethod } = this.props;
    const quotes = [...(this.props.quotes || [])];
    let res;
    if (sortMethod === "a") {
      res = (quotes || []).sort((a, b) => {
        if (a.symbol < b.symbol) return -1;
        if (a.symbol > b.symbol) return 1;
        return 0;
      });
    } else if (sortMethod === "%") {
      res = (quotes || []).sort((a, b) => {
        if (Number(a.percent) > Number(b.percent)) return -1;
        if (Number(a.percent) < Number(b.percent)) return 1;
        return 0;
      });
    } else {
      res = quotes;
    }
    return res;
  };

  render() {
    const { quotes, updateQuoteOrders } = this.props;
    const { expanded, sortableElemKey } = this.state;
    const { locked: layoutLocked } = getActiveLayoutConfig(this.props.layout, this.props.setLayoutActive);
    const params = {
      grabCursor: true,
      slidesPerView: "auto",
      // spaceBetween: 20,
      pagination: {
        el: ".swiper-pagination",
      },
      shouldSwiperUpdate: true,
    };
    return (
      <>
        {quotes.length > 0 && (
          <div className="quotes-area">
            <div className="quote-tools card">
              <a
                className="add-quote-icon"
                onClick={() => {
                  this.props.openEditQuote();
                }}
              >
                <i className="mdi mdi-plus cursor-pointer" />
              </a>
              <a
                className={`expand-quoute-icon ${expanded ? "expanded" : ""}`}
                onClick={() => {
                  this.setState({
                    expanded: !expanded,
                    sortToolExpanded: false,
                  });
                }}
              >
                <i className="mdi mdi-chevron-down cursor-pointer" />
              </a>
            </div>
            {!expanded && quotes.length > 0 && <Swiper {...params}>{this.renderSwiperQuoteCards()}</Swiper>}
            <div
              className={`quote-dropdown-wrapper ${layoutLocked ? "" : "layout-unlocked"} ${
                expanded ? "expanded" : ""
              }`}
            >
              <div className="quote-dropdown-inner-wrapper">
                <ReactSortable
                  key={sortableElemKey}
                  animation={200}
                  handle=".handler"
                  list={this.getSortedQuotes()}
                  setList={(newState) => {
                    const oldOrder = this.getSortedQuotes().map((item) => item.symbol);
                    const newOrder = newState.map((item) => item.symbol);

                    if (!_.isEqual(oldOrder, newOrder)) {
                      this.updateSortMethod(null);
                      updateQuoteOrders(newState);
                    }
                  }}
                  disabled={layoutLocked}
                >
                  {this.renderDropdownQuoteCards()}
                </ReactSortable>
              </div>
              {this.renderSortTools()}
            </div>
          </div>
        )}
        {this.props.quotes.length === 0 && (
          <div className="card add-quote-empty mb-2">
            <a
              className="add-quote-icon"
              onClick={() => {
                this.props.openEditQuote();
              }}
            >
              <i className="mdi mdi-plus cursor-pointer" />
            </a>
          </div>
        )}
      </>
    );
  }
}

QuoteContent.propTypes = {
  openEditQuote: PropTypes.func,
  fetchQuotes: PropTypes.func,
};

const mapDispatchToProps = {
  removeFromQuotes: QuoteActions.removeFromQuotes,
  updateQuoteOrders: QuoteActions.updateQuoteOrders,
  updateQuoteConfig: ConfigActions.updateQuoteConfig,
  updateQuotes: QuoteActions.updateQuotes,
  resetQuotePin: QuoteActions.resetQuotePin,
  setLayoutActive: DashboardActions.setLayoutActive,
};

const mapStateToProps = (state, props) => ({
  layout: state.dashboard.layout,
  ...state.quote,
  ...props,
  sortMethod: state.config.quote.sortMethod,
});

export default withDataSource(connect(mapStateToProps, mapDispatchToProps)(QuoteContent));
