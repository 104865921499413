import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import cogoToast from "cogo-toast";

import { AuthActions } from "../store";
import Api from "../api";
import { validateEmail } from "../util";

class ForgotPassword extends Component {
  state = {
    sendErrTxt: "",
    sent: false,
    email: "",
  };

  componentDidMount() {
    window.localStorage.removeItem("jwt_access_token");
    if (new URLSearchParams(this.props.location.search).get("_t") === "invalid") {
      cogoToast.error(<div>This link is expired or something went wrong</div>, {
        hideAfter: 8,
      });
      this.props.history.replace("/forgot-password");
    }
  }

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({ sendErrTxt: "" });

    const { email } = this.state;
    if (!validateEmail(email)) {
      this.setState({ sendErrTxt: "Please enter a valid email address" });
      return;
    }

    this.props.setLoading(true);

    Api.sendForgotPasswordEmail(email)
      .then(() => {
        this.props.setLoading(false);
        this.setState({
          sent: true,
          email,
        });
      })
      .catch((error) => {
        this.props.setLoading(false);
        const errTxt = error.toString();
        console.info(errTxt);
        let sendErrTxt;
        if (errTxt === "TypeError: Failed to fetch") {
          sendErrTxt = "Service not available";
        } else if (errTxt === "no_user") {
          sendErrTxt = "Email address is not registered";
        } else {
          sendErrTxt = "Failed to send instructions. Please try again";
        }
        this.setState({ sendErrTxt });
      });
  };

  render() {
    const { sendErrTxt } = this.state;
    return (
      <div className="auth px-0 pt-4 pt-md-5 h-100">
        <div className="row w-100 mx-0">
          <div className="col-lg-4 mx-auto">
            <div className="card text-left py-5 px-4 px-sm-5">
              <div className="brand-logo">
                <span className="pr-2">MOMO</span>
              </div>
              <h4>Forgot password</h4>

              {this.state.sent ? (
                <>
                  <div className="text-muted">We have sent an email to reset your password: {this.state.email}</div>
                  <div className="text-center mt-4 font-weight-light">
                    <div className="text-muted">
                      If you do not see any emails, you can{" "}
                      <a
                        className="text-secondary"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          this.setState({
                            sent: false,
                          });
                        }}
                      >
                        try again
                      </a>
                      .
                    </div>
                  </div>

                  <div className="text-center mt-4 font-weight-light">
                    <Link to="/login" className="text-success">
                      Log in
                    </Link>
                  </div>
                </>
              ) : (
                <>
                  <h6 className="font-weight-light">Please enter your email.</h6>
                  <div className="text-muted">We will send instructions to your email.</div>
                  <Form className="pt-3" onSubmit={this.onSubmit}>
                    <Form.Group>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <i className="input-group-text mdi mdi-email text-momo-green" />
                        </div>
                        <Form.Control
                          type="text"
                          className="form-control text-light form-momo-green"
                          placeholder="Email"
                          value={this.state.email}
                          onChange={(e) => {
                            this.setState({
                              email: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </Form.Group>

                    {sendErrTxt !== "" && <label className="text-danger">{`${sendErrTxt}`}</label>}
                    <div className="mt-3">
                      <button
                        type="submit"
                        className="btn btn-block btn-momo-green btn-lg font-weight-medium auth-form-btn"
                      >
                        SEND
                      </button>
                    </div>
                    <div className="text-center mt-4 font-weight-light">
                      <Link to="/login" className="text-momo-green">
                        Log in
                      </Link>
                    </div>
                  </Form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setAuthenticated: AuthActions.setAuthenticated,
  setLoading: AuthActions.setLoading,
  setUser: AuthActions.setUser,
};

const mapStateToProps = (state) => ({
  authenticated: state.auth.authenticated,
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
