import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import style from "./style.module.scss";
import { DiscoveryActions } from "../../../store";
import Pop from "./popup";
import Icon from "@mdi/react";
import { mdiPin } from "@mdi/js";

function FaderButton({ widget }) {
  const dispatch = useDispatch();
  const filterFader = useSelector((state) => state.discovery.filterFader?.[widget]);
  const [showPanel, setShowPanel] = React.useState(false);

  useEffect(() => {
    if (filterFader === undefined) {
      dispatch(DiscoveryActions.setFilterFader({ widget, enabled: false }));
    }
  }, []);

  return (
    <div className={"d-flex " + style.root}>
      <Icon
        path={mdiPin}
        size="20px"
        style={{ transform: "rotate(45deg)", color: filterFader ? "rgb(246, 197, 72)" : "white" }}
        onClick={() => {
          dispatch(
            DiscoveryActions.setFilterFader({
              widget,
              enabled: !filterFader,
            })
          );
        }}
      />
      <div
        className={["mdi", "mdi-chevron-down", style.dropdown].join(" ")}
        style={{ fontSize: "20px", ...(showPanel ? { opacity: 1 } : {}) }}
        tabIndex={0}
        onClick={() => setShowPanel(!showPanel)}
        onMouseLeave={() => setShowPanel(false)}
      >
        {showPanel && <Pop widget={widget} />}
      </div>
    </div>
  );
}

export default FaderButton;
