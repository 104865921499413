import React, { useEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import setupChart from "./chartconfig";
import _, { invert } from "lodash";
import moment from "moment";
import { checkOnHoliday, timeframeToMs, timeframeToUnit } from "../../util";

am5.addLicense("AM5S349138421");

const getTimeCount = (tf) => {
  return tf.match(/^\d*/)[0];
};

function ChartBox({ data, chartColor, tf, symbol }) {
  const chartDiv = useRef(null);
  const chartRoot = useRef(null);

  const prevData = useRef(null);

  const updateHighlights = useRef(() => {});

  const [chartSeries, setChartSeries] = useState(null);

  useEffect(() => {
    if (prevData.current) {
      if (_.isEqual(_.omit(prevData.current, "data"), { symbol, tf, chartColor })) {
        return;
      }
    }
    prevData.current = { data, symbol, tf, chartColor };

    if (chartRoot.current) {
      chartRoot.current?.dispose();
      chartDiv.current.innerHTML = "";
    }
    const { root, series, dispose } = setupChart({
      chartDiv: "chartdiv",
      chartColor,
      timeUnit: timeframeToUnit(tf),
      timeCount: getTimeCount(tf),
      tf,
    });

    chartRoot.current = root;
    series.events.on("datavalidated", () => {
      updateHighlights.current();
    });
    setChartSeries(series);
    return dispose;
  }, [symbol, tf, chartColor]);

  useEffect(() => {
    if (!chartSeries) return;
    if (!(data?.length > 1)) return;
    if (!(data[0].time && data[0].value)) return;

    let modifiedData = _.cloneDeep(data);

    // Get Timeframe
    let timeframe = timeframeToMs(tf);

    //Populate missing data points
    let filledData = [];
    filledData.push(modifiedData[modifiedData.length - 1]);

    for (let i = modifiedData.length - 2; i >= 0; i--) {
      const dataPoint = modifiedData[i];

      const time = dataPoint.time;
      const value = dataPoint.value;

      let lastTime = filledData[filledData.length - 1].time;
      while (lastTime - time > timeframe) {
        const newTime = lastTime - timeframe;
        const timeInfo = getMarketTimeInfo(newTime);
        if (timeInfo.open) {
          filledData.push({
            time: newTime,
            value: null,
          });
        }
        lastTime = newTime;
      }

      const marketTimeInfo = getMarketTimeInfo(time);

      filledData.push({ time, value });
    }

    filledData = filledData.reverse();
    modifiedData = filledData;

    const highlights = [];

    // Check timeframe
    if (timeframe < 12 * 60 * 60 * 1000) {
      //Clean data to trading hours
      modifiedData = modifiedData.filter((d) => {
        const marketTimeInfo = getMarketTimeInfo(d.time);
        return marketTimeInfo.open;
      });

      const hasExtended = filledData.some((d) => getMarketTimeInfo(d.time).extended);

      // Mark extended time differently
      if (hasExtended) {
        const preColor = am5.color("#CFB043");
        const preOpacity = 0.21;
        const afterColor = am5.color("#1C1F5F");
        const afterOpacity = 0.9;
        const highlightOpacity = 0.35;

        // const preColor = "#00ff00";
        // const afterColor = "#ff0000";

        let currentHighlight;

        for (let i = 0; i < modifiedData.length; i++) {
          const dataInfo = getMarketTimeInfo(modifiedData[i].time);
          if (dataInfo.regular) {
            if (currentHighlight) {
              currentHighlight.end = modifiedData[i].time;
              highlights.push(currentHighlight);
              currentHighlight = undefined;
            }
            continue;
          }

          if (!currentHighlight) {
            currentHighlight = {
              type: dataInfo.pre ? "pre" : "after",
              start: modifiedData[i].time,
              end: modifiedData[i].time,
              color: dataInfo.pre ? preColor : afterColor,
              opacity: dataInfo.pre ? preOpacity : afterOpacity,
              fullOpacity: highlightOpacity,
            };
          } else {
            if (dataInfo[currentHighlight.type]) {
              currentHighlight.end = modifiedData[i].time;
            } else {
              currentHighlight.end = modifiedData[i].time;
              highlights.push(currentHighlight);

              currentHighlight = {
                type: dataInfo.pre ? "pre" : "after",
                start: modifiedData[i].time,
                end: modifiedData[i].time,
                color: dataInfo.pre ? preColor : afterColor,
                opacity: dataInfo.pre ? preOpacity : afterOpacity,
                fullOpacity: highlightOpacity,
              };
            }
          }
        }
        if (currentHighlight) {
          highlights.push(currentHighlight);
        }
      }
    }

    chartSeries.data.setAll(modifiedData);

    if (highlights.length > 0) {
      updateHighlights.current = () => {
        const xAxis = chartSeries.get("xAxis");
        if (xAxis.axisRanges.length > 0) {
          xAxis.axisRanges.clear();
        }
        for (const highlight of highlights) {
          const range = xAxis.createAxisRange(
            xAxis.makeDataItem({
              value: highlight.start,
              endValue: highlight.end,
              above: false,
            })
          );
          range.get("axisFill")?.setAll({
            fill: highlight.color,
            fillOpacity: highlight.opacity,
            opacity: highlight.fullOpacity,
            visible: true,
          });

          range.get("grid")?.setAll({
            visible: false,
          });
        }
      };
    }
  }, [data, chartSeries]);

  return (
    <div className="w-100 h-100 d-flex justify-content-start align-items-center">
      <div ref={chartDiv} id="chartdiv" className="w-100 h-100"></div>
    </div>
  );
}

function getMarketTimeInfo(time) {
  if (!time) throw "Time is required";

  time = moment.tz(time, "America/New_York");

  const marketHours = {
    pre_market: { start: "04:00:00", end: "09:30:00" },
    regular_market: { start: "09:30:00", end: "16:00:00" },
    after_hours: { start: "16:00:00", end: "20:00:00" },
  };

  const onWeekend = time.day() === 6 || time.day() === 0;
  const onHoliday = checkOnHoliday(time);

  const marketStatus = Object.fromEntries(
    Object.entries(marketHours).map(([key, { start, end }]) => [
      key,
      !(onWeekend || onHoliday) &&
        time.isBetween(
          moment.tz(time.format("YYYY-MM-DD") + " " + start, "America/New_York"),
          moment.tz(time.format("YYYY-MM-DD") + " " + end, "America/New_York"),
          "minute",
          "[)"
        ),
    ])
  );

  const extended = marketStatus.pre_market || marketStatus.after_hours;
  const open = marketStatus.regular_market || extended;

  return {
    open,
    regular: marketStatus.regular_market,
    pre: marketStatus.pre_market,
    after: marketStatus.after_hours,
    extended,
  };
}

export default ChartBox;
