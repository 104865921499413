import { createActions, createReducer } from "reduxsauce";

const defaultState = {
  channels: [],
  messages: [],
  channelSelected: null,
  channelReachedEnd: false,
  input: "",
  searchInput: "",
  replyMsg: null,
  typingInfo: null,
  loadingMessage: false,
};

export const { Types, Creators } = createActions({
  fetchChannels: [],
  fetchChannelsSucceed: ["value"],
  selectChannel: ["value"],
  setInput: ["value"],
  appendInput: ["value"],
  setSearchInput: ["value"],
  setReplyMsg: ["value"],
  setTypingInfo: ["value"],
  resetMessages: ["value"],
  setMessages: ["value"],
  receiveMessage: ["value"],
  receiveVote: ["value"],
  setLoadingMessage: ["value"],
  setChannelReachedEnd: ["value"],
});

const fetchChannelsSucceed = (state, { value }) => {
  return {
    ...state,
    channels: value || [],
  };
};

const selectChannel = (state, { value }) => {
  return {
    ...state,
    channelSelected: value,
    channelReachedEnd: false,
  };
};

const setInput = (state, { value }) => {
  return {
    ...state,
    input: value,
  };
};

const appendInput = (state, { value }) => {
  return {
    ...state,
    input: state.input + value,
  };
};

const setSearchInput = (state, { value }) => {
  return {
    ...state,
    searchInput: value,
  };
};

const setReplyMsg = (state, { value }) => {
  return {
    ...state,
    replyMsg: value,
  };
};

const setTypingInfo = (state, { value }) => {
  return {
    ...state,
    typingInfo: value,
  };
};

const resetMessages = (state) => {
  return {
    ...state,
    messages: [],
  };
};

const setMessages = (state, { value }) => {
  const ids = (state.messages || []).map((item) => item.id);
  const validMessages = (value || []).filter(
    (item) => !ids.includes(item.id) && state.channelSelected === item.channel_id
  );
  return {
    ...state,
    messages: [...validMessages, ...state.messages],
  };
};

const receiveMessage = (state, { value }) => {
  if (state.channelSelected !== value.channel_id) {
    return state;
  }
  const duplicate = state.messages.find((item) => item.id === value.id);
  if (duplicate) {
    return state;
  }
  if (state.searchInput) {
    const { searchInput } = state;
    if (searchInput.startsWith("@")) {
      const user_id = searchInput.slice(1);
      if (value.user.id != user_id) {
        return state;
      }
    } else {
      if (!value.content.includes(`$${searchInput}`)) {
        return state;
      }
    }
  }
  return {
    ...state,
    messages: [...state.messages, value],
  };
};

const receiveVote = (state, { value }) => {
  return {
    ...state,
    messages: state.messages.map((item) => {
      if (item.id === value.id) {
        item.vote = value.vote;
        if (value.me_vote !== undefined) {
          item.me_vote = value.me_vote;
        }
      }
      return item;
    }),
  };
};

const setLoadingMessage = (state, { value }) => {
  return {
    ...state,
    loadingMessage: !!value,
  };
};

const setChannelReachedEnd = (state, { value }) => {
  return {
    ...state,
    channelReachedEnd: !!value,
  };
};

export const ChatTypes = Types;

export const chatReducer = createReducer(defaultState, {
  [Types.FETCH_CHANNELS_SUCCEED]: fetchChannelsSucceed,
  [Types.SELECT_CHANNEL]: selectChannel,
  [Types.SET_INPUT]: setInput,
  [Types.APPEND_INPUT]: appendInput,
  [Types.SET_SEARCH_INPUT]: setSearchInput,
  [Types.SET_REPLY_MSG]: setReplyMsg,
  [Types.SET_TYPING_INFO]: setTypingInfo,
  [Types.RESET_MESSAGES]: resetMessages,
  [Types.SET_MESSAGES]: setMessages,
  [Types.RECEIVE_MESSAGE]: receiveMessage,
  [Types.RECEIVE_VOTE]: receiveVote,
  [Types.SET_LOADING_MESSAGE]: setLoadingMessage,
  [Types.SET_CHANNEL_REACHED_END]: setChannelReachedEnd,
});

export default Creators;
