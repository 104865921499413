const RerenderCounter = (() => {
  const counts = {};
  const logEvery = 5 * 1000;

  setInterval(() => {
    for (const name in counts) {
      if (counts[name] > 0) {
        console.log(`Rendered ${name} ${counts[name]} times in the last 5 seconds`);
        counts[name] = 0;
      }
    }
  }, logEvery);

  return {
    increment(name) {
      counts[name] = (counts[name] || 0) + 1;
    },
  };
})();

export default RerenderCounter;
