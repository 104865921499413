import React, { useRef } from "react";
import PropTypes from "prop-types";
import { SymbolInfoWrapper, SymbolIcon, SymbolItem, SymbolName, SymbolText } from "./styles";
import { useSelector } from "react-redux";

const SymbolDetail = function ({ symbol, hoverable, mode, onClick }) {
  const stocknames = useSelector((state) => state.stocknames?.data || {});
  const stockItem = stocknames[symbol];

  const imgRef = useRef();

  return (
    <SymbolItem
      hoverable={!!hoverable}
      mode={mode}
      onClick={(e) => {
        onClick && onClick(e);
      }}
    >
      <SymbolIcon
        ref={imgRef}
        src={`https://cdn.mometic.com/icons/${symbol}/icon.png`}
        onError={() => {
          if (imgRef.current) {
            // TODO: update with default img
            imgRef.current.src = "/no-stock-icon.png";
          }
        }}
      />
      <SymbolInfoWrapper>
        <SymbolText>{symbol}</SymbolText>
        <SymbolName>{stockItem?.n}</SymbolName>
      </SymbolInfoWrapper>
    </SymbolItem>
  );
};

SymbolDetail.propTypes = {
  symbol: PropTypes.string.isRequired,
  hoverable: PropTypes.bool,
  mode: PropTypes.oneOf(["context-menu", "default"]),
  onClick: PropTypes.func,
};

SymbolDetail.defaultProps = {
  hoverable: false,
  mode: "default",
};

export default SymbolDetail;
