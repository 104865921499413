import React from "react";
import PropTypes from "prop-types";
import { useRef, useEffect } from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { LeftArrow, RightArrow } from "../../chart/Arrows";
import API from "../../api";

function RecentSymbols({ recents, symbol, onSelect, onRemove }) {
  const recentContainer = useRef(null);
  const currentSymbolItem = useRef(null);

  useEffect(() => {
    if (recentContainer.current && currentSymbolItem.current) {
      recentContainer.current.scrollToItem(currentSymbolItem.current);
    }
  }, [symbol]);

  if (!Array.isArray(recents) && !recents.length > 0) return <></>;
  return (
    <div className="d-flex align-items-center mt-2 mb-2 ">
      <div className="flex-shrink-1 flex-shrink-1 mx-0" style={{ overflow: "hidden" }}>
        <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} apiRef={recentContainer}>
          {renderRecentsBar()}
        </ScrollMenu>
      </div>
    </div>
  );

  function renderRecentsBar() {
    return (recents || []).map((item, index) => {
      return (
        <div
          key={index}
          itemID={index}
          className={`news-recent-item mr-1 ${item == symbol ? "active" : ""}`}
          onClick={() => {
            onSelect(item);

            // update for popular
            if (item !== symbol) {
              item && API.incSymbolStats(item);
            }
          }}
          ref={item === symbol ? currentSymbolItem : undefined}
        >
          {item}
          <a
            className="mdi mdi-close btn-news-recent-delete"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onRemove(item);
            }}
          ></a>
        </div>
      );
    });
  }
}

RecentSymbols.propTypes = {
  recents: PropTypes.array,
  symbol: PropTypes.string,
  onSelect: PropTypes.func,
  onRemove: PropTypes.func,
};
export default RecentSymbols;
