import React, { Component } from "react";
import "./settings.css";
import Slider from "nouislider-react";
import StepSlider from "../shared/StepRangeSlider/StepSlider";

const PERCENT_RANGE = [
  { value: 0.1, step: 0.1 }, // acts as min value
  { value: 5, step: 0.5 },
  { value: 10, step: 1 },
  { value: 20, step: 5 },
  { value: 50, step: 10 },
  { value: 100 },
];

const UV_PERCENT_RANGE = [
  { value: 0.1, step: 0.1 }, // acts as min value
  { value: 1, step: 1 },
  { value: 10, step: 5 },
  { value: 50, step: 10 },
  { value: 100, step: 50 },
  { value: 400, step: 100 },
  { value: 1000 },
];

const HIGH_LOW_RANGE = [
  { value: 0.02, step: 0.01 }, // acts as min value
  { value: 0.1, step: 0.1 },
  { value: 10, step: 1 },
  { value: 50, step: 10 },
  { value: 100, step: 50 },
  { value: 400, step: 100 },
  { value: 1000 },
];

export default class AlertInput extends Component {
  getRange = () => {
    const { type } = this.props;
    let range = PERCENT_RANGE;
    if (type === "trade") {
      range = HIGH_LOW_RANGE;
    } else if (type === "uv") {
      range = UV_PERCENT_RANGE;
    }
    return range;
  };

  getMinMaxValue = () => {
    const range = this.getRange();
    const min = range[0].value;
    const max = range[range.length - 1].value;
    return {
      min,
      max,
    };
  };

  onChange = (name, newValue) => {
    const value = { ...this.props.value };
    value[name] = newValue;
    if (name === "rate") {
      const minmax = this.getMinMaxValue();
      if (newValue < minmax.min) value[name] = minmax.min;
      if (newValue > minmax.max) value[name] = minmax.max;
    }
    this.props.onChange(value);
  };

  render() {
    const { value, editing, type, onEdit } = this.props;
    const { category, rate } = value;
    const range = this.getRange();

    return (
      <div
        className={
          "row mx-0 justify-content-between align-items-center item-content mt-1 pl-2 " + (editing ? "alert-edit" : "")
        }
        onClick={onEdit}
      >
        <input
          placeholder="Name"
          className="bg-dark progress-input justify-content-center align-items-center text-center border-0 white-color small"
          value={category}
          onChange={(e) => {
            this.onChange("category", e.target.value);
          }}
          autoFocus
          disabled={!editing}
        />
        <div className="d-flex flex-row flex-fill justify-content-center align-items-center progress-section">
          <StepSlider
            range={range}
            value={parseFloat(rate)}
            disabled={!editing}
            onChange={(value) => {
              this.onChange("rate", parseFloat(parseFloat(value).toFixed(2)));
            }}
          />
          <input
            placeholder="Sensitivity"
            className="ml-3 bg-dark progress-input justify-content-center align-items-center text-center border-0 white-color small"
            value={rate}
            onChange={(e) => {
              this.onChange("rate", e.target.value);
            }}
            disabled={!editing}
          />
        </div>
        <button
          className="bg-transparent border-0"
          onClick={this.props.onSubmit}
          disabled={!editing || value.category === ""}
        >
          <i className={"mdi  popover-icon mdi-check text-light " + (editing ? "" : "transparent-txt")} />
        </button>
        <button className="bg-transparent border-0 delete-alert" onClick={this.props.onDelete}>
          <i className="mdi mdi-close text-white popover-icon" />
        </button>
      </div>
    );
  }
}
