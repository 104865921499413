import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { deviceDetect } from "react-device-detect";
import cogoToast from "cogo-toast";
import _ from "lodash";

import QuoteContent from "./QuoteContent";
import QuoteInlineInput from "./QuoteInlineInput";
import TagButton from "../shared/Button/TagButton";
import { MAX_QUOTES_LIMIT } from "../constants";

import { Types as QuoteTypes } from "./quoteReducer";
import { getActiveLayoutConfig, isWidgetVisible } from "../dashboard/dashboardReducer";

function Quote(props) {
  const [quotesInEdit, setQuotesInEdit] = useState([]);
  const [editQuoteMsg, setEditQuoteMsg] = useState("");
  const [showEditQuote, setShowEditQuote] = useState(false);
  const [showCancelEditQuote, setShowCancelEditQuote] = useState(false);

  const inputQuoteSymbol = useRef(null);
  const symbolInEdit = useRef("");
  const editErrorMsgTimeoutId = useRef(null);

  const dispatch = useDispatch();
  const {
    quote: { quotes },
    dashboard: { layout, layoutCurrentDevice },
  } = useSelector((state) => state);

  useEffect(() => {
    fetchQuotes();
  }, []);

  useEffect(() => {
    // Register key event handler
    window.addEventListener("keydown", onKeydown, false);

    return () => {
      window.removeEventListener("keydown", onKeydown);
    };
  }, [showEditQuote, showCancelEditQuote, quotes]);

  const isQuotesWidgetVisible = useMemo(() => {
    if (layout && layoutCurrentDevice) {
      const { devices } = getActiveLayoutConfig(layout);
      const deviceLayoutConfig = devices[layoutCurrentDevice];
      return deviceLayoutConfig && isWidgetVisible("quotes", deviceLayoutConfig);
    } else {
      return false;
    }
  }, [layout, layoutCurrentDevice]);

  const fetchQuotes = _.debounce(
    () => {
      dispatch({
        type: QuoteTypes.QUOTES_FETCH,
      });
    },
    100,
    {}
  );

  const onKeydown = useCallback(
    (event) => {
      const { key } = event;
      if (key === "/") {
        const activeElement = document.activeElement;
        if (activeElement.tagName === "INPUT" || activeElement.tagName === "TEXTAREA") {
          return;
        }
        if (!showEditQuote && !showCancelEditQuote && !document.getElementById("discovery-smart-filter")) {
          openEditQuote();
        }
      }
    },
    [showEditQuote, showCancelEditQuote, quotes]
  );

  const openEditQuote = () => {
    const sorted = (quotes || []).map((item) => item.symbol).sort();

    symbolInEdit.current = "";
    setShowEditQuote(true);
    setShowCancelEditQuote(false);
    setEditQuoteMsg("");
    setQuotesInEdit(sorted);

    setTimeout(() => {
      inputQuoteSymbol.current && inputQuoteSymbol.current.focus();
    }, 200);
  };

  const renderEditQuoteModal = () => {
    return (
      <Modal
        className={"modal-drak-overlay"}
        show={showEditQuote}
        onHide={() => onCloseEditQuoteModal()}
        aria-labelledby="Quote Edit Modal"
        backdrop={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span style={{ fontSize: "1rem", fontWeight: "normal" }}>Edit Quote</span>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="d-flex flex-row flex-wrap align-items-center">
            {quotesInEdit.map((item, index) => (
              <TagButton
                key={`${item}-${index}`}
                label={item}
                hasClose
                onClose={() => {
                  setQuotesInEdit(quotesInEdit.filter((item1) => item1 != item));
                }}
              />
            ))}
            <QuoteInlineInput
              ref={(ref) => {
                inputQuoteSymbol.current = ref;
              }}
              value={symbolInEdit.current}
              onEnter={(value) => {
                if (value && value.trim()) {
                  if (!quotesInEdit.includes(value)) {
                    setQuotesInEdit([...quotesInEdit, value]);
                  } else {
                    const errorMsg = "Symbol already exists!";
                    showErrorMsgInEditModal(errorMsg, errorMsg);
                  }
                }
              }}
            />
          </div>
          {!editQuoteMsg && (
            <small className="form-text text-muted mt-3">
              You can have max <b>{MAX_QUOTES_LIMIT}</b> quotes.
            </small>
          )}
          {editQuoteMsg && <span className="quote-edit-popup-error-msg mt-3">{editQuoteMsg}</span>}
        </Modal.Body>

        <Modal.Footer>
          <div className="footer-container">
            <Button variant="warning col-12" onClick={onSaveQuote} className="btn btn-primary">
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };

  const renderEditQuoteCancelModal = () => {
    return (
      <Modal
        show={showCancelEditQuote}
        onHide={() => {
          setShowCancelEditQuote(false);
          symbolInEdit.current = "";
        }}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span style={{ fontSize: "1rem", fontWeight: "normal" }}>Warning</span>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-left">You have unsaved changes. Are you sure you want to quit without saving?</div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowEditQuote(true);
              setShowCancelEditQuote(false);
              setEditQuoteMsg("");
            }}
          >
            Keep Editing
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setShowCancelEditQuote(false);
              symbolInEdit.current = "";
            }}
          >
            &nbsp;Yes&nbsp;
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const showErrorMsgInEditModal = (toastDOMMsg, msg) => {
    if (editErrorMsgTimeoutId.current) {
      clearTimeout(editErrorMsgTimeoutId.current);
      editErrorMsgTimeoutId.current = null;
    }

    const device = deviceDetect();
    if (device?.isMobile || device?.isTablet) {
      setEditQuoteMsg(msg);
      editErrorMsgTimeoutId.current = setTimeout(() => {
        setEditQuoteMsg("");
      }, 5000);
    } else {
      cogoToast.warn(toastDOMMsg);
    }
  };

  const onSaveQuote = () => {
    if (quotesInEdit?.length > MAX_QUOTES_LIMIT) {
      showErrorMsgInEditModal(
        <div>
          <span>
            You can't have more than <strong>{MAX_QUOTES_LIMIT}</strong> quotes.
          </span>
        </div>,
        `You can't have more than ${MAX_QUOTES_LIMIT} quotes.`
      );
      return;
    }

    const quoteSymbols = quotes.map((item) => item.symbol.toUpperCase());
    const adds = quotesInEdit.filter((item) => !quoteSymbols.includes(item));
    const deletes = quoteSymbols.filter((item) => !quotesInEdit.includes(item));

    if (adds.length || deletes.length) {
      dispatch({
        type: QuoteTypes.REGISTER_QUOTE,
        adds,
        deletes,
      });
    } else {
      const errMsg = "No symbols added or deleted to save!";
      showErrorMsgInEditModal(errMsg, errMsg);
      return;
    }

    setShowEditQuote(false);
    setShowCancelEditQuote(false);
    setEditQuoteMsg("");
    setQuotesInEdit([]);
    symbolInEdit.current = "";
  };

  const onCloseEditQuoteModal = () => {
    const quoteSymbols = quotes.map((item) => item.symbol.toUpperCase());
    const adds = quotesInEdit.filter((item) => !quoteSymbols.includes(item));
    const deletes = quoteSymbols.filter((item) => !quotesInEdit.includes(item));
    const symbolInEditValue = inputQuoteSymbol.current.value;

    if (adds.length || deletes.length || symbolInEditValue) {
      setShowEditQuote(false);
      setShowCancelEditQuote(true);
      setEditQuoteMsg("");
      symbolInEdit.current = symbolInEditValue;
    } else {
      setShowEditQuote(false);
      setShowCancelEditQuote(false);
      setEditQuoteMsg("");
      symbolInEdit.current = "";
    }
  };

  return (
    <>
      {isQuotesWidgetVisible && <QuoteContent openEditQuote={openEditQuote} fetchQuotes={fetchQuotes} />}
      {renderEditQuoteModal()}
      {renderEditQuoteCancelModal()}
    </>
  );
}

export default Quote;
