import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DiscoveryActions } from "../../../../store";
import style from "./style.module.scss";
import { SettingLabel, SettingValue } from "./components";
import { DISCOVERY_DEFAULT_FILTEROUT_FADEOUT_TIME } from "../../../../constants";

function Pop({ widget }) {
  const dispatch = useDispatch();

  const filterFadeoutTime = useSelector((state) => state.discovery.filterFadeoutTime?.[widget]);
  const [sliderValue, setSliderValue] = useState(Math.round(msToValue(filterFadeoutTime)));
  const [sliderLabel, setSliderLabel] = useState();

  useEffect(() => {
    const ms = valueToMs(sliderValue);

    dispatch(
      DiscoveryActions.setFilterFadeoutTime({
        widget,
        time: ms,
      })
    );
    setSliderLabel(secondsToLabel(ms / 1000));
  }, [sliderValue]);

  useEffect(() => {
    if (filterFadeoutTime === undefined) {
      dispatch(
        DiscoveryActions.setFilterFadeoutTime({
          widget,
          time: DISCOVERY_DEFAULT_FILTEROUT_FADEOUT_TIME,
        })
      );
    }
  }, []);

  return (
    <div className={["audio-control-popup", style.container].join(" ")}>
      <div className={[style.root, style.slideLeft].join(" ")} onClick={(e) => e.stopPropagation()}>
        <div className={style.items}>
          <SettingLabel>Pin hold</SettingLabel>
          <input
            type="range"
            min="0"
            max="100"
            value={sliderValue}
            onChange={(e) => {
              setSliderValue(e.target.value);
            }}
            className={style.slider}
            step="10"
          />
          <SettingValue>{sliderLabel}</SettingValue>
        </div>
      </div>
    </div>
  );
}

const sliderTimeMap = {
  100: 30 * 60 * 1000,
  90: 10 * 60 * 1000,
  80: 8 * 60 * 1000,
  70: 5 * 60 * 1000,
  60: 3 * 60 * 1000,
  50: 2 * 60 * 1000,
  40: 1.5 * 60 * 1000,
  30: 1 * 60 * 1000,
  20: 30 * 1000,
  10: 10 * 1000,
  0: 5 * 1000,
};

function secondsToLabel(seconds) {
  if (seconds < 60) {
    return `${seconds} s`;
  } else {
    return `${seconds / 60} min`;
  }
}

function valueToMs(value) {
  const val = Math.floor(value / 10) * 10;
  return sliderTimeMap[val];
}
function msToValue(ms) {
  for (let key in sliderTimeMap) {
    if (sliderTimeMap[key] >= ms) {
      return parseInt(key);
    }
  }
  return 0;
}

export default Pop;
