import { store } from "../../app/store/createStore";
import { canPlayMedia } from "./mediaPlayAbility";

export function playAudio({ url, context, callbacks }) {
  if (!context) {
    context = "default";
  }
  const audioState = store.getState().audioplayer;
  const { masterVolume, masterMuted } = audioState;

  let volume = masterVolume ?? 1;
  let muted = masterMuted;

  const contextState = audioState.contexts[context];
  volume *= contextState.volume ?? 1;
  muted = muted || contextState.muted;

  if (canPlayMedia() && !muted) {
    const sound = new Audio(url);

    sound.onended = function (ev) {
      if (callbacks?.onended) {
        callbacks.onended(ev);
        sound.remove();
      }
    };

    sound.volume = volume;

    sound.play().catch((e) => {
      if (callbacks?.onerror) {
        callbacks.onerror(e);
        sound.remove();
      }
    });
    return sound;
  } else if (muted) {
    if (callbacks?.onended) {
      callbacks.onended();
    }
  } else {
    if (callbacks?.onerror) {
      callbacks.onerror();
    }
  }
}
