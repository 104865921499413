import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translations: {
          "Overview dashboard": "Overview dashboard",
          email_not_found: "Invalid email",
          invalid_email_or_password: "Invalid email or password",
          empty_email_password: "Empty email or password",
          empty_email_password_username: "Empty email or password or username",
          password_mismatch: "The passwords do not match",
          no_user: "Invalid email",
        },
      },
      ar: {
        translations: {
          "Corona admin template now with a new facelift for enhanced legibility and aesthetics":
            "إدارة قالب Corona الآن مع عملية تجميل جديدة لتعزيز الوضوح والجماليات",
          "New refreshing look": "نظرة منعشة جديد",
          "Get Started": "البدء",
          "Revenue current": "الإيرادات الحالية",
          "Daily Income": "الدخل اليومي",
          "Expense current": "النفقات الجارية",
          "Transaction History": "سجل المعاملات",
          Total: "مجموع",
          "Transfer to Paypal": "نقل إلى باي بال",
          "Tranfer to Stripe": "يمكنك نقل إلى الشريط",
          "Open Projects": "مشاريع مفتوحة",
          "Your data status": "حالة البيانات الخاصة بك",
          "Admin dashboard design": "تصميم لوحة تحكم المشرف",
          "Broadcast web app mockup": "تطبيق نموذج بالحجم الطبيعي",
          "minutes ago": "دقائق مضت",
          tasks: "مهام",
          issues: "مسائل",
          "Wordpress Development": "مدعوم من وورد",
          "Upload new design": "قم بتنزيل التصميم الجديد",
          "hour ago": "منذ ساعات",
          "Project meeting": "اجتماع مشروع",
          "New project discussion": "",
          "Broadcast Mail": "مناقشة مشروع جديد",
          "Sent release details to team": "",
          "UI Design": "أرسلت تفاصيل الافراج عن الفريق",
          "New application planning": "تخطيط التطبيق الجديد",
          "Since last month": "منذ الشهر الماضي",
          "Order Status": "حالة الطلب",
          "Client Name": "اسم الزبون",
          "Order No": "رقم الطلب",
          "Product Cost": "تكلفة المنتج",
          Project: "مشروع",
          "Payment Mode": "طريقة الدفع او السداد",
          "Start Date": "تاريخ البدء",
          "Payment Status": "حالة السداد",
          "Credit card": "بطاقة ائتمان",
          Dec: "ديسمبر",
          Approved: "وافق",
          "Estella Bryan": "إستيلا براين",
          Website: "موقع الكتروني",
          "Cash on delivered": "الدفع عند الاستلام",
          Pending: "قيد الانتظار",
          "Lucy Abbott": "لوسي أبوت",
          "App design": "تصميم التطبيق",
          Rejected: "غير مقبول",
          "Peter Gill": "بيتر جيل",
          Development: "تطوير",
          "Online Payment": "الدفع الالكتروني",
          "Sallie Reyes": "سالي رييس",
          "View all": "عرض الكل",
          Leonard: "ليونارد",
          Well: "حسنا",
          "it seems to be working now": "يبدو أن العمل الآن",
          "Ethel Kelly": "اثيل كيلي",
          "Please review the tickets": "يرجى التحقق من التذاكر",
          "Herman May": "هيرمان ماي",
          "Thanks a lot": "شكرا جزيلا",
          "It was easy to fix it": "كان من السهل الإصلاح",
          "CeeCee Bass": "الحافلات CeeCee",
          "To do list": "حضر قائمة",
          "Visitors by Countries": "زوار حسب الدول",
          USA: "الولايات المتحدة الامريكيه",
          Germany: "ألمانيا",
          Australia: "أستراليا",
          "United Kingdom": "المملكة المتحدة",
          Romania: "رومانيا",
          Brasil: "البرازيل",
          Purchase: "شراء",
          Revenue: "إيرادات",
          Sales: "مبيعات",
          "Pick up kids from school": "مبيعات",
          Add: "حفظ",
          "Hours Ago": "منذ ساعات",
          Jan: "كانون الثاني",
          Portfolio: "محفظة الشريحة",
          "Potential growth": "النمو المحتمل",

          Main: "الرئيسي",
          Dashboard: "لوحة القيادة",
          Widgets: "الحاجيات",
          "UI Elements": "عناصر واجهة المستخدم",
          "UI Features": "ميزات واجهة المستخدم",
          "Basic UI Elements": "العناصر الأساسية",
          Accordions: "أكورديون",
          Buttons: "زر",
          Badges: "شارات",
          Breadcrumbs: "فتات الخبز",
          "Form Elements": "نماذج",
          "Data Representation": "شرح البيانات",
          Dropdowns: "قائِمة مُنْسَدِلة",
          Modals: "الحوار",
          "Progress bar": "شريط التقدم",
          Pagination: "ترقيم الصفحات",
          Tabs: "علامات التبويب",
          Typography: "الاسلوب والظهور",
          Tooltips: "تلميح",
          "Advanced UI": "واجهة المستخدم المتقدمة",
          Clipboard: "الحافظة",
          "Context menu": "قائمة السياق",
          Sliders: "الانزلاق",
          Carousel: "دائري",
          Loaders: "رافعاتs",
          "Form elements": "نماذج",
          "Basic Elements": "عناصر النموذج",
          "Advanced Elements": "العناصر المتقدمة",
          Validation: "التحقق من صحة",
          Wizard: "ساحر",
          Editors: "المحررين",
          "Text Editors": "محرري النصوص",
          "Code Editor": "محرري الكود",
          Charts: "الرسوم البيانية",
          Tables: "الطاولةs",
          "Basic Table": "الجداول الأساسية",
          "Data Table": "جداول البيانات",
          "Sortable Table": "جدول قابل للفرز",
          Popups: "يظهر فجأةs",
          Notifications: "إخطاراتs",
          Icons: "الرموز",
          Maps: "خرائط",
          "Sample Pages": "صفحات عينة",
          "User Pages": "صفحات المستخدم",
          "User Listing": "قائمة المستخدم",
          Login: "تسجيل الدخول",
          "Login 2": " تسجيل الدخول 2 ",
          Register: "سجل",
          "Register 2": " سجل 2 ",
          Lockscreen: " قفل الشاشة ",
          "Error Pages": "صفحات خطأ",
          "General Pages": "الصفحات العامة",
          "Blank Page": " صفحة فارغة ",
          Profile: "الملف الشخصي ",
          FAQ: "تعليمات",
          "FAQ 2": " أسئلة مكررة 2 ",
          "News Grid": " شبكة الأخبار ",
          Timeline: " الجدول الزمني ",
          "Search Results": " نتائج البحث ",
          Chats: " دردشة",
          Tickets: " تذاكر ",
          Gallery: " صالة عرض",
          "Todo List": "قوائم المهام",
          "E-commerce": "التجارة الإلكترونية",
          Invoice: " فاتورة ",
          Pricing: " جدول التسعير ",
          Apps: "تطبيقات",
          "E-mail": "البريد الإلكتروني",
          "Kanban Board": "كانبان بورد",
          Calendar: "التقويم",
          Help: "مساعدة",
          Documentation: "توثيق",
          "Henry Klein": "هنري كلاين",
          "Take Tour": "خذ جولة",
          "Log Out": "تسجيل خروج",
          "Gold Member": "عضو ذهبي",
          "Tree View": "عرض الشجرة",
          "Landing Page": "الصفحة المقصودة",
          "Product Catalogue": "بيان المنتج",
          "Project List": "قائمة المشروع",
          Orders: "طلب",
          Navigation: "قائمة طعام",
          "Account settings": "إعدادت الحساب",
          "Change Password": "تغيير كلمة السر",
          "To-do list": "حضر قائمة",

          Reports: "تقارير",
          PDF: "بي دي إف",
          doc: "وثيقة",
          Projects: "وثيقة",
          "Search Products": "البحث عن المنتجات",
          "View Project": "عرض المشاريع",
          "Edit Project": "تحرير المشاريع",
          English: "الإنجليزية",
          Arabic: "عربى",
          "User Options": "خيارات المستخدم",
          Actions: "عمل",
          "Lock Account": "قفل الحساب",
          Messages: "رسائل",
          "Mark send you a message": "مارك يرسل لك رسالة",
          "Minutes ago": "منذ 1 دقيقة",
          "Cregh send you a message": "إنشاء نرسل لك رسالة",
          "Profile picture updated": "تحديث صورة ملفك الشخصي",
          "Update dashboard": "تحديث لوحة القيادة",
          "new messages": "4 رسائل جديدة",
          "Event today": "حدث اليوم",
          "Just a reminder that you have an event today": "مجرد تذكير بأن لديك حدث اليوم",
          "Launch Admin": "تشغيل المسؤول",
          "New admin wow": "مشرف جديد واو!",
          "See all notifications": "اطلع على جميع الإشعارات",
          Inbox: "صندوق الوارد",
          "All rights reserved": "كل الحقوق محفوظة",
          "Hand-crafted": "الحرف اليدوية",
          "made with": "مصنوع من",
          Copyright: "حقوق التأليف والنشر",
          Settings: "الإعدادات",
          "Advanced settings": "إعدادات متقدمة",
          "Create New Project": "إنشاء مشروع جديد",
          "Software Development": "تطوير البرمجيات",
          "UI Development": "تطوير واجهة المستخدم",
          "Software Testing": "اختبار البرمجيات",
          "See all projects": "رؤية جميع المشاريع",
        },
      },
    },
    fallbackLng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
