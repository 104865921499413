import React, { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { isChrome, isChromium, isEdge, isFirefox, isSafari, isMobileSafari, isEdgeChromium } from "react-device-detect";
import styled from "styled-components";

const BannerWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f5c549;
  padding: 5px 30px 5px 10px;
  font-size: 16px;
  color: black;
  z-index: 1031;

  span {
    font-weight: 500;
  }

  .fa-ban {
    font-weight: bold;
    font-size: 20px;
  }

  .btn-help {
    border: 2px solid black;
    padding: 2px 5px;
    border-radius: 8px;
    cursor: pointer;
    user-select: none;
  }

  .btn-close {
    position: absolute;
    font-weight: bold;
    font-size: 24px;
    top: 0;
    right: 5px;
    cursor: pointer;
  }
`;

const Banner = forwardRef((props, ref) => {
  const { isPopoutBlocked } = useSelector((state) => state.dashboard);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(isPopoutBlocked);
  }, [isPopoutBlocked]);

  const browserName = useMemo(() => {
    if (isChrome || isChromium) {
      return "Chrome";
    } else if (isSafari || isMobileSafari) {
      return "Safari";
    } else if (isEdge || isEdgeChromium) {
      return "Edge";
    } else if (isFirefox) {
      return "Firefox";
    } else {
      return "Browser";
    }
  }, [isChrome, isChromium, isEdge, isFirefox, isSafari, isMobileSafari, isEdgeChromium]);

  const onClickHelp = useCallback(() => {
    let link;
    if (isChrome || isChromium) {
      link = "https://support.google.com/chrome/answer/95472?hl=en&co=GENIE.Platform%3DDesktop";
    } else if (isSafari || isMobileSafari) {
      link = "https://support.apple.com/guide/safari/block-pop-ups-sfri40696/mac";
    } else if (isEdge || isEdgeChromium) {
      link =
        "https://support.microsoft.com/en-us/microsoft-edge/block-pop-ups-in-microsoft-edge-1d8ba4f8-f385-9a0b-e944-aa47339b6bb5";
    } else if (isFirefox) {
      link = "https://support.mozilla.org/en-US/kb/pop-blocker-settings-exceptions-troubleshooting";
    } else {
      link = "https://www.google.com/search?q=How+to+allow+popups+in+web+browser";
    }
    link && window.open(link, "_blank");
  }, [isChrome, isChromium, isEdge, isFirefox, isSafari, isMobileSafari, isEdgeChromium]);

  return (
    visible && (
      <BannerWrapper ref={ref}>
        <span>
          <i className="fa fa-ban mr-2 mr-md-4" />
          {browserName} prevented MOMO Pro from launching pop-up windows. Enable in URL bar above or in Settings.
        </span>
        <span className="btn-help ml-4" onClick={() => onClickHelp()}>
          Help
        </span>
        <i className="btn-close mdi mdi-close" onClick={() => setVisible(false)} />
      </BannerWrapper>
    )
  );
});

export default Banner;
