import React from "react";
import { Button, Dropdown, Form, Modal, FormText, FormControl, Collapse } from "react-bootstrap";
import SyntaxHighlighter from "react-syntax-highlighter";
import { monokaiSublime } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { validateUrl } from "../util";
import { ALERT_TYPE_TITLE } from "../constants";

const sampleResponses = {
  conditional: `Sample Request Payload:
  {
    "type": "conditional",
    "name": "Uvol-Breakout",
    "symbols": [
      "AMZN",
      ...
    ],
    "time": 1577941200000
  }

  "time": Timestamp in miliseconds based UTC timezone`,
  halts: `Sample Request Payload:
  {
    "type": "halts",
    "subtype": "halt",
    "symbol": "ANGH",
    "haltdate": "02/11/2022",
    "halthalt": "15:39:51",
    "reason_code": "LUDP",
    "resumption_date": "02/11/2022",
    "resumption_quote_time": "15:39:51",
    "resumption_trade_time": "15:49:51",
    "msg": "ANGH was halted for (Volatility 5%/1min) - 02/11/22 15:39",
    "time": 1577941200000
  }

  "subtype": "halt" / "resume"
  "time": Timestamp in miliseconds based UTC timezone`,
  high_low: `Sample Request Payload:
  {
    "type": "high_low",
    "symbol": "AMZN",
    "price": 3000,
    "subtype": "high",
    "msg": "AMZN has just reached a new high 3000",
    "time": 1577941200000
  }
  
  "subtype": "high" / "low"
  "time": Timestamp in miliseconds based UTC timezone`,
  uvol: `Sample Request Payload:
  {
    "type": "uvol",
    "symbol": "AMZN",
    "uv": 20,
    "msg": "AMZN vol spiked 20% over average",
    "time": 1577941200000
  }
  
  "time": Timestamp in miliseconds based UTC timezone`,
  vwapdist: `Sample Request Payload:
  {
    "type": "vwapdist",
    "symbol": "AMZN",
    "vwap": 2.8,
    "vwap_dist": 2,
    "msg": "AMZN VWAP channel crossed at $2.8",
    "time": 1577941200000
  }
  
  "time": Timestamp in miliseconds based UTC timezone`,
  price: `Sample Request Payload:
  {
    "type": "price",
    "symbol": "AMZN",
    "price": 3000,
    "price_dist": 2,
    "msg": "AMZN is up 2%, trading at $3000.00",
    "time": 1577941200000
  }
  
  "time": Timestamp in miliseconds based UTC timezone`,
};

class SettingWebhookModal extends React.Component {
  state = {
    url: "",
    detailCollapsed: true,
    isFormSubmitted: false,
    loading: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.open !== prevState.open) {
      if (nextProps.open) {
        return {
          url: nextProps.url || "",
          open: nextProps.open,
          detailCollapsed: true,
          isFormSubmitted: false,
          loading: false,
        };
      }
    }
    return {
      open: nextProps.open,
    };
  }

  isFormValid = () => {
    return this.isFormValidUrl();
  };

  isFormValidUrl = () => {
    const { url } = this.state;
    return url == "" || validateUrl(url);
  };

  handleSave = () => {
    try {
      this.setState({
        isFormSubmitted: true,
      });

      if (!this.isFormValid()) {
        return;
      }

      this.setState({
        loading: true,
      });

      const { type } = this.props;
      const { url } = this.state;
      this.props.onSave({
        url,
        type,
      });
    } catch (e) {
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    const { open, onHide, type } = this.props;
    const { detailCollapsed, url, isFormSubmitted } = this.state;

    const errors = [];
    if (isFormSubmitted && !this.isFormValidUrl()) {
      errors.push("Webhook URL is invalid.");
    }

    return (
      <Modal
        id="setting-webhook-alert-modal"
        show={open}
        onHide={() => {
          onHide && onHide(false);
        }}
        aria-labelledby="alert webhook setting modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span style={{ fontSize: "1rem", fontWeight: "normal" }}>{ALERT_TYPE_TITLE[type]} Webhook Setting</span>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="">
          <Form.Control
            as="textarea"
            rows={2}
            placeholder="https://"
            className=""
            style={{ color: "white" }}
            value={url}
            onChange={(e) => {
              e.preventDefault();
              this.setState({
                url: e.target.value,
              });
            }}
          />

          <div className="mt-1 mb-2">
            {errors.map((error, index) => {
              return (
                <div key={`filtered-alert-error-${index}`} style={{ color: "rgb(252, 66, 74)", fontSize: "14px" }}>
                  {error}
                </div>
              );
            })}
          </div>

          <Collapse in={!detailCollapsed}>
            <div style={{ marginTop: "15px" }}>
              <SyntaxHighlighter language="json" style={monokaiSublime}>
                {`METHOD: "POST"`}
              </SyntaxHighlighter>
              <SyntaxHighlighter language="json" style={monokaiSublime}>
                {`Content-Type: "application/json"`}
              </SyntaxHighlighter>
              <SyntaxHighlighter language="json" style={monokaiSublime} customStyle={{ marginBottom: 0 }}>
                {sampleResponses[type] || ""}
              </SyntaxHighlighter>
            </div>
          </Collapse>

          <div className="d-flex justify-content-start">
            <div
              className="btn-expander"
              onClick={() => {
                this.setState({
                  detailCollapsed: !detailCollapsed,
                });
              }}
            >
              <i className={`mdi ${detailCollapsed ? "mdi-chevron-down" : "mdi-chevron-up"}`} />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="justify-content-between">
          <Button
            variant="secondary"
            onClick={() => {
              this.setState({
                url: "",
              });
            }}
          >
            Reset
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              this.handleSave();
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default SettingWebhookModal;
