import { store } from "../app/store/createStore";
import {
  AVG_VOL_MAX,
  PRICE_MAX,
  FLOAT_MAX,
  SECTORS_FILTER,
  DEFAULT_STREAM_SETTING,
  DEFAULT_NEWS_CONFIG,
  isPro,
  isActiveSubscription,
  isProPlusNew,
  TEST_SYMBOLS,
  COUNT_MAX,
  VOICE_NOTI_VOICE,
  DEFAULT_VOICE_BUFFER,
  DEFAULT_OPTIONS_MODE,
  STREAM_CHANNEL_TYPE,
  STREAM_CHANNEL_MODE,
  ATR_MAX,
  GAP_MAX,
  MARKET_CAP_MAX,
} from "../app/constants";
import { delay } from "../app/util";
import { playAudio } from "./AudioPlayer";

export class VoiceAlertHandler {
  baseUrl = process.env.REACT_APP_VOICE_URL || "https://audio.mometic.com";
  sameStockTimeSpan = process.env.REACT_APP_VOICE_SAME_STOCK_TIME_SPAN || 10;
  symbolPlayTime = 2;
  companyNamePlayTime = 3;
  bufferLimitInSec = 60;

  lastPlayedSymbols = []; // array of symbols played in last 10 seconds
  lastPlayedSymbolTimerId = null;
  buffer = []; // array of symbols, is truncated if total play time exceedes 1 minute

  isPlaying = false;
  inPlayLoop = false;

  constructor() {
    this.initLastPlayedSymbolsHandler();
  }

  initLastPlayedSymbolsHandler() {
    this.lastPlayedSymbolTimerId = setInterval(() => {
      const now = new Date();
      this.lastPlayedSymbols = this.lastPlayedSymbols.filter(
        (item) => now - item.timestamp < this.sameStockTimeSpan * 1000
      );
      // console.log(this.lastPlayedSymbols.map(item => item.symbol));
    }, 1000);
  }

  clearLastPlayedSymbolsHandler() {
    this.lastPlayedSymbolTimerId && clearInterval(this.lastPlayedSymbolTimerId);
    this.lastPlayedSymbolTimerId = null;
  }

  handleData(data) {
    const state = store.getState();

    let { highs, highsT1, highsT2, lows, lowsT1, lowsT2, halts, filteredAlerts, flows } = data;
    highs = highs || [];
    highsT1 = highsT1 || [];
    highsT2 = highsT2 || [];
    lows = lows || [];
    lowsT1 = lowsT1 || [];
    lowsT2 = lowsT2 || [];
    halts = halts || [];
    filteredAlerts = filteredAlerts || [];
    flows = flows || [];

    if (!this.isProPlus(state)) {
      filteredAlerts = [];
    }

    const removeDuplicates = (arr) => {
      const symbols = arr.map((item) => item[0]);
      return arr.filter((item, pos) => {
        return symbols.indexOf(item[0]) === pos;
      });
    };
    highs = removeDuplicates(highs);
    highsT1 = removeDuplicates(highsT1);
    highsT2 = removeDuplicates(highsT2);
    lows = removeDuplicates(lows);
    lowsT1 = removeDuplicates(lowsT1);
    lowsT2 = removeDuplicates(lowsT2);

    const { stream } = state.config;
    const streamPrimaryChannelItem = (stream || []).filter((item) => item.channel === "stream1")[0];
    const streamAltChannelItem = (stream || []).filter((item) => item.channel === "stream2")[0];

    let primaryHighs = highs;
    let primaryLows = lows;
    let altHighs = highs;
    let altLows = lows;

    if (streamPrimaryChannelItem.mode === STREAM_CHANNEL_MODE.NEART1) {
      primaryHighs = highsT1;
      primaryLows = lowsT1;
    } else if (streamPrimaryChannelItem.mode === STREAM_CHANNEL_MODE.NEART2) {
      primaryHighs = highsT2;
      primaryLows = lowsT2;
    }

    if (streamAltChannelItem.mode === STREAM_CHANNEL_MODE.NEART1) {
      altHighs = highsT1;
      altLows = lowsT1;
    } else if (streamAltChannelItem.mode === STREAM_CHANNEL_MODE.NEART2) {
      altHighs = highsT2;
      altLows = lowsT2;
    }

    const primaryHighSymbols = streamPrimaryChannelItem
      ? this.filterStream(primaryHighs, streamPrimaryChannelItem, state)
      : [];
    const primaryLowSymbols = streamPrimaryChannelItem
      ? this.filterStream(primaryLows, streamPrimaryChannelItem, state)
      : [];
    const altHighSymbols = streamAltChannelItem ? this.filterStream(altHighs, streamAltChannelItem, state) : [];
    const altLowSymbols = streamAltChannelItem ? this.filterStream(altLows, streamAltChannelItem, state) : [];
    const favSymbols = [...this.filterFavorite(highs, state), ...this.filterFavorite(lows, state)];
    const popularSymbols = [...this.filterPopular(highs, state), ...this.filterPopular(lows, state)];
    const haltSymbols = this.filterHalt(halts, state);
    const filteredAlertSymbols = this.filterFilteredAlerts(filteredAlerts, state);
    const flowSymbols = flows || [];

    // if (primaryHighSymbols.length > 0) {
    //   console.log("Symbols for primary high stream", primaryHighSymbols);
    // }
    // if (primaryLowSymbols.length > 0) {
    //   console.log("Symbols for primary low stream", primaryLowSymbols);
    // }
    // if (altHighSymbols.length > 0) {
    //   console.log("Symbols for alt high stream", altHighSymbols);
    // }
    // if (altLowSymbols.length > 0) {
    //   console.log("Symbols for alt low stream", altLowSymbols);
    // }
    // if (favSymbols.length > 0) {
    //   console.log("Symbols for favorite", favSymbols);
    // }
    // if (popularSymbols.length > 0) {
    //   console.log("Symbols for popular", popularSymbols);
    // }
    // if (haltSymbols.length > 0) {
    //   console.log("Symbols for halt", haltSymbols);
    // }
    // if (filteredAlertSymbols.length > 0) {
    //   console.log("Symbols for filtered alerts", filteredAlertSymbols);
    // }

    // Note: Priority: Fav > Alt > Primary > Popular
    // TODO: calculate priority & truncate or buffer
    const voiceNoti = state.config.voiceNoti || [];
    const voiceBuffer = state.config.voiceBuffer || DEFAULT_VOICE_BUFFER;
    const voiceNotiMap = {};
    voiceNoti.forEach((item) => {
      voiceNotiMap[item.zone] = item;
    });

    let symbolsToPlay = [];
    const orderedZoneList = [
      {
        zone: "filtered",
        data: filteredAlertSymbols,
      },
      {
        zone: "halt",
        data: haltSymbols,
      },
      {
        zone: "flow",
        data: flowSymbols,
      },
      {
        zone: "primary_high",
        data: primaryHighSymbols,
      },
      {
        zone: "primary_low",
        data: primaryLowSymbols,
      },
      {
        zone: "alt_high",
        data: altHighSymbols,
      },
      {
        zone: "alt_low",
        data: altLowSymbols,
      },
      {
        zone: "fav",
        data: favSymbols,
      },
      {
        zone: "popular",
        data: popularSymbols,
      },
    ];
    for (const { zone, data } of orderedZoneList) {
      if (voiceNotiMap[zone]) {
        data.forEach((symbol) => {
          symbolsToPlay.push({
            symbol,
            voice: voiceNotiMap[zone].voice,
            buffer: voiceBuffer,
            zone,
            playCompanyName: voiceNotiMap[zone].type === "name",
          });
        });
      }
    }

    const arr = symbolsToPlay.map((item) => item["symbol"]);
    symbolsToPlay = symbolsToPlay.filter((item, pos) => {
      return arr.indexOf(item["symbol"]) === pos;
    });

    if (symbolsToPlay.length > 0) {
      this.bufferSymbols(symbolsToPlay);
    }

    if (this.buffer.length > 0 && !this.inPlayLoop) {
      this.playBuffer();
    }
  }

  bufferSymbols(data) {
    this.buffer = [...this.buffer, ...data];
    let totalPlayTime = 0;
    let indexToSlice = 0;
    for (let i = this.buffer.length - 1; i >= 0; i--) {
      totalPlayTime += this.buffer[i].buffer;
      totalPlayTime += this.buffer[i].playCompanyName ? this.companyNamePlayTime : this.symbolPlayTime;
      if (totalPlayTime > this.bufferLimitInSec) {
        indexToSlice = i + 1;
        break;
      }
    }
    if (indexToSlice) {
      this.buffer = this.buffer.slice(indexToSlice);
      // console.log("VoiceAlertHandler", "Truncated", indexToSlice);
    }
  }

  playBuffer() {
    if (this.buffer.length > 0) {
      this.inPlayLoop = true;
      if (this.buffer.length > 1) {
        this.cacheVoice(this.buffer[1]);
      }
      this.playVoice(this.buffer.shift());
    } else {
      this.inPlayLoop = false;
    }
  }

  playVoice({ symbol, voice, buffer, zone, playCompanyName }) {
    const type = playCompanyName ? "n" : "s";

    playAudio({
      url: `https://d3ghmnfpmp6xw.cloudfront.net/${VOICE_NOTI_VOICE[voice]}/${symbol}/${type}.mp3`,
      callbacks: {
        onended: async () => {
          await delay(buffer * 1000);
          this.isPlaying = false;
          this.playBuffer();
        },
        onerror: () => {
          this.isPlaying = false;
          this.playBuffer();
        },
      },
    });

    this.isPlaying = true;

    this.lastPlayedSymbols.push({
      symbol,
      timestamp: new Date(),
    });
  }

  cacheVoice({ symbol, voice, buffer, zone, playCompanyName }) {
    const type = playCompanyName ? "n" : "s";
    fetch(`https://d3ghmnfpmp6xw.cloudfront.net/${VOICE_NOTI_VOICE[voice]}/${symbol}/${type}.mp3`).catch(() => {});
  }

  filterOutLastPlayedSymbols(symbols) {
    const lastPlayedSymbolList = this.lastPlayedSymbols.map((item) => item.symbol);
    return symbols.filter((symbol) => !lastPlayedSymbolList.includes(symbol));
  }

  // Keep synced with Dashboard.applyFilter
  filterStream(data, { channel, title, type, value }, state) {
    if (!type || type === STREAM_CHANNEL_TYPE.NONE) {
      return [];
    }

    const isProPlus = this.isProPlus(state);
    const { options } = state.options;
    const optionsMode = state.config.optionsMode || DEFAULT_OPTIONS_MODE;
    const popularSymbols = state.dashboard.popularSymbols || [];

    if (!value.float) value.float = DEFAULT_STREAM_SETTING.float;
    if (!value.price) value.price = DEFAULT_STREAM_SETTING.price;
    if (!value.volume) value.volume = DEFAULT_STREAM_SETTING.volume;
    if (!value.count) value.count = DEFAULT_STREAM_SETTING.count;
    if (!value.atr) value.atr = DEFAULT_STREAM_SETTING.atr;
    if (!value.gap) value.gap = DEFAULT_STREAM_SETTING.gap;
    if (!value.marketCap) value.marketCap = DEFAULT_STREAM_SETTING.marketCap;
    if (!value.custom_view) value.custom_view = [];

    let sectorDigits = {};
    for (let key in value.industries) {
      if (value.industries[key]) {
        sectorDigits[SECTORS_FILTER[key]] = true;
      }
    }

    const popularFilterSymbols = (popularSymbols || []).slice(0, 100);

    const filtered_data = data.filter((item) => {
      return TEST_SYMBOLS.indexOf(item[0]) < 0;
    });
    let filtered = [];

    if (type === STREAM_CHANNEL_TYPE.FILTER) {
      filtered = filtered_data
        .filter((item, i) => {
          let price = item[1];
          let priceFilter = value.price;
          const min = priceFilter.min || 0;
          const max = priceFilter.max >= PRICE_MAX ? Infinity : priceFilter.max;
          return price >= min && price <= max;
        })
        .filter((item, i) => {
          let volume = item[5];
          let volumeFilter = value.volume;
          const min = volumeFilter.min || 0;
          const max = volumeFilter.max >= AVG_VOL_MAX ? Infinity : volumeFilter.max;
          return volume >= min && volume <= max;
        })
        .filter((item) => {
          const float = item[7];
          const floatFilter = value.float;
          const min = floatFilter.min || 0;
          const max = floatFilter.max >= FLOAT_MAX ? Infinity : floatFilter.max;
          return float >= min && float <= max;
        })
        .filter((item) => {
          const count = item[2];
          const countFilter = value.count;
          const min = countFilter.min || 0;
          const max = countFilter.max >= COUNT_MAX ? Infinity : countFilter.max;
          return count >= min && count <= max;
        })
        .filter((item) => {
          if (item[6]) {
            return sectorDigits[item[6]];
          }
        })
        .filter((item) => {
          if (!isProPlus) {
            return true;
          } else {
            const atr = item[9];
            const atrFilter = value.atr;
            const min = atrFilter.min || 0;
            const max = atrFilter.max >= ATR_MAX ? Infinity : atrFilter.max;
            return atr >= min && atr <= max;
          }
        })
        .filter((item) => {
          if (!isProPlus) {
            return true;
          } else {
            const gap = Math.abs(item[10]); // Gap Percent Dist
            const gapFilter = value.gap;
            const min = gapFilter.min || 0;
            const max = gapFilter.max >= GAP_MAX ? Infinity : gapFilter.max;
            return gap >= min && gap <= max;
          }
        })
        .filter((item) => {
          const marketCap = item[12];
          const marketCapFilter = value.marketCap;
          const min = marketCapFilter.min || 0;
          const max = marketCapFilter.max >= MARKET_CAP_MAX ? Infinity : marketCapFilter.max;
          return marketCap >= min && marketCap <= max;
        })
        .filter((item) => {
          if (optionsMode !== "Filter") return true;
          if (options.indexOf(item[0]) > -1) return true;
          if (this.isSymbolFav(item[0], state)) return true;
          return false;
        });
    }
    if (type === STREAM_CHANNEL_TYPE.CUSTOM) {
      filtered = filtered_data.filter((item) => {
        return value.custom_view.indexOf(item[0]) > -1;
      });
    }
    if (type === STREAM_CHANNEL_TYPE.FAV) {
      filtered = filtered_data.filter((item) => {
        return this.isSymbolFav(item[0], state);
      });
    }
    if (type === STREAM_CHANNEL_TYPE.POPULAR) {
      filtered = filtered_data.filter((item) => {
        return popularFilterSymbols.includes(item[0]);
      });
    }

    filtered = filtered.map((item) => item[0]);

    return this.filterOutLastPlayedSymbols(filtered);
  }

  filterPopular(data, state) {
    let { popularSymbols } = state.dashboard;
    popularSymbols = popularSymbols || [];
    const filtered = data.filter((item) => popularSymbols.includes(item[0])).map((item) => item[0]);
    return this.filterOutLastPlayedSymbols(filtered);
  }

  filterHalt(data, state) {
    const symbols = data.map((item) => item[0]);
    return this.filterOutLastPlayedSymbols(symbols);
  }

  filterFilteredAlerts(data, state) {
    return this.filterOutLastPlayedSymbols(data || []);
  }

  filterFavorite(data, state) {
    let { quotes } = state.quote;
    quotes = quotes || [];
    const quoteSymbols = quotes.map((item) => item.symbol);
    const filtered = data.filter((item) => quoteSymbols.includes(item[0])).map((item) => item[0]);
    return this.filterOutLastPlayedSymbols(filtered);
  }

  isSymbolFav(symbol, state) {
    let { quotes } = state.quote;
    quotes = quotes || [];
    const qouteItem = quotes.find((item) => item.symbol === symbol);
    return qouteItem ? true : false;
  }

  isProPlus(state) {
    return (
      state &&
      state.auth &&
      state.auth.user &&
      state.auth.user.subscription &&
      isActiveSubscription(state.auth.user.subscription) &&
      isProPlusNew(state.auth.user.subscription.plan)
    );
  }
}
