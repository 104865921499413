import * as messaging from "firebase/messaging";
import { playAudio } from "../AudioPlayer";
import cogoToast from "cogo-toast";
import _ from "lodash";

const NOTIFICATON_TIME = 4000;
const MAX_NOTIFICATIONS = 5;

export function setupNotifications() {
  let notifProcessor;
  let notifTimes = [];
  let messageQueue = [];

  const processMessageFunc = () => {
    notifTimes = notifTimes.filter((time) => {
      return Date.now() - time < NOTIFICATON_TIME;
    });
    const maxShow = Math.max(MAX_NOTIFICATIONS - notifTimes.length, 0);

    for (let i = 0; i < Math.min(maxShow, messageQueue.length); i++) {
      const message = messageQueue.shift();

      cogoToast.info(message, { hideAfter: NOTIFICATON_TIME / 1000 });
      playAudio({ url: "https://momo.mometic.com/alert.mp3" });

      notifTimes.push(Date.now());
    }

    if (messageQueue.length > 0 && !notifProcessor) {
      notifProcessor = setTimeout(processMessageFunc, NOTIFICATON_TIME);
    } else {
      notifProcessor = null;
    }
  };

  const processMessage = _.debounce(processMessageFunc, 1000, {
    maxWait: 2000,
  });

  const appendMessage = (message) => {
    const newMessageWaitlist = [];

    const stripNumbers = /[^a-zA-Z ]/g;

    for (const msg of messageQueue) {
      if (msg.replace(stripNumbers, "") !== message.replace(stripNumbers, "")) {
        newMessageWaitlist.push(msg);
      }
    }
    newMessageWaitlist.push(message);
    messageQueue = newMessageWaitlist;

    processMessage();
  };

  messaging
    .isSupported()
    .then((supported) => {
      if (supported) {
        messaging.onMessage(messaging.getMessaging(), (payload) => {
          console.info("Firebase Notification Received:", payload);
          const message = payload.data.body;
          appendMessage(message);
        });
      }
    })
    .catch(() => {});

  if (navigator.serviceWorker) {
    navigator.serviceWorker.onmessage = (event) => {
      if (event.data && event.data.type === "FCM_ALERT") {
        console.info("FCM event from Service Worker:", event);
        const message = event.data.body;
        appendMessage(message);
      }
    };
  }
}
