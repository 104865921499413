import {
  CHANNEL_CHAT,
  CHANNEL_CHAT_SUBSCRIBE,
  CHANNEL_CHAT_UNSUBSCRIBE,
  CHANNEL_FLOW_SUBSCRIBE,
  CHANNEL_FLOW_UNSUBSCRIBE,
  CHANNEL_STREAM_SUBSCRIBE,
  CHANNEL_STREAM_UNSUBSCRIBE,
} from "./channels";
import DataSource from "./creator";

export default class DataSourcePrimary extends DataSource {
  constructor() {
    super("primary");
  }

  init(userInfo) {
    this.disconnect();
    this.connect(userInfo);

    this.socket.on("compressedUpdate", (data) => {
      this.emit("compressedUpdate", { detail: data });
    });

    this.socket.on("filteredAlerts", (data) => {
      this.emit("filteredAlerts", { detail: data });
    });
  }

  subscribeStream() {
    if (this.socket && this.socket.connected) {
      this.socket.emit(CHANNEL_STREAM_SUBSCRIBE);
    }
  }

  unsubscribeStream() {
    if (this.socket && this.socket.connected) {
      this.socket.emit(CHANNEL_STREAM_UNSUBSCRIBE);
    }
  }

  subscribeChat() {
    if (this.socket && this.socket.connected) {
      this.socket.emit(CHANNEL_CHAT_SUBSCRIBE);
      this.socket.off("chat").on("chat", (data) => {
        this.emit("chatInboundMessage", { detail: data });
      });
    }
  }

  unsubscribeChat() {
    if (this.socket && this.socket.connected) {
      this.socket.emit(CHANNEL_CHAT_UNSUBSCRIBE);
    }
  }

  sendChat(content) {
    if (this.socket && this.socket.connected) {
      this.socket.emit(CHANNEL_CHAT, content);
      return true;
    }
    return false;
  }

  subscribeFlow(timeframe) {
    if (this.socket && this.socket.connected) {
      this.socket.emit(CHANNEL_FLOW_SUBSCRIBE, timeframe);
      this.socket.off("flow").on("flow", (data) => {
        this.emit("flowMessage", { detail: data });
      });
    }
  }

  unsubscribeFlow() {
    if (this.socket && this.socket.connected) {
      this.socket.emit(CHANNEL_FLOW_UNSUBSCRIBE);
    }
  }
}
