import React from "react";
import PropTypes from "prop-types";

import styles from "./index.module.scss";

const replyingStatusImg = require("../../../assets/images/chat/reply-arrow-selected.png");

function MsgReplyStatus({ replyUser, onClose }) {
  return replyUser ? (
    <div className={styles["message-reply-status"]}>
      <div>
        <img src={replyingStatusImg} />
        <span>replying to {replyUser}</span>
      </div>
      <i className={`mdi mdi-close ${styles["btn-close"]}`} onClick={() => onClose && onClose()} />
    </div>
  ) : null;
}

MsgReplyStatus.propTypes = {
  replyUser: PropTypes.string,
  onClose: PropTypes.func,
};

export default MsgReplyStatus;
