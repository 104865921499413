import React from "react";
import cogoToast from "cogo-toast";

import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import API from "../api";
import quoteActions, { QuoteTypes } from "./quoteReducer";
import dashboardActions from "../dashboard/dashboardReducer";
import { MAX_QUOTES_LIMIT } from "../constants";

function* getQuotes() {
  try {
    const quotes = yield call(API.getQuotes);
    yield put(quoteActions.quotesFetchSuccess(quotes));
  } catch (e) {
    cogoToast.error("Failed to get favorite stocks!");
    yield put(quoteActions.quotesFetchFailed(e.message));
  }
}

function* registerQuote(action) {
  const { adds, deletes } = action;
  try {
    yield put(dashboardActions.showSpinner());
    const result = yield call(API.registerQuote, adds, deletes);

    if (!result) {
      throw "";
    } else if (result.error) {
      throw result.error;
    } else {
      const { success, overflow, data, invalids, duplicates } = result;

      let extMsg = null;
      if (overflow) {
        extMsg = (
          <span>
            &nbsp;&nbsp;You can't have more than <strong>{MAX_QUOTES_LIMIT}</strong> quotes.
          </span>
        );
      } else if (invalids?.length > 0 || duplicates?.length > 0) {
        const arr = [].concat(invalids).concat(duplicates);
        if (arr.length === 1) {
          extMsg = (
            <span>
              &nbsp;&nbsp;<strong>{arr[0]}</strong> is invalid or duplicated.
            </span>
          );
        } else if (arr.length === 2) {
          extMsg = (
            <span>
              &nbsp;&nbsp;<strong>{arr[0]}</strong> and <strong>{arr[1]}</strong> are invalid or duplicated.
            </span>
          );
        } else {
          const restCnt = arr.length - 2;
          extMsg = (
            <span>
              &nbsp;&nbsp;<strong>{arr[0]}</strong>, <strong>{arr[1]}</strong> and {restCnt} other symbols are invalid
              or duplicated.
            </span>
          );
        }
      }

      if (success) {
        cogoToast.success(
          <div>
            <span>Quotes updated!</span>
            {extMsg}
          </div>
        );
      } else {
        cogoToast.error(
          <div>
            <span>Failed to update quotes!</span>
            {extMsg}
          </div>
        );
      }

      yield put(quoteActions.registerSuccess(data, deletes));
      yield put(dashboardActions.hideSpinner());
    }
  } catch (e) {
    let msg = <span>Failed to update quotes!</span>;

    let error_code = null;
    if (e === "empty_symbol") {
      error_code = <span>No symbols found to be added or deleted.</span>;
    } else {
      error_code = <span>Something went wrong.</span>;
    }

    cogoToast.error(
      <div>
        {msg}&nbsp;&nbsp;
        {error_code}
      </div>
    );
    yield put(dashboardActions.hideSpinner());
  }
}

function* removeQuote({ symbol }) {
  yield put(dashboardActions.showSpinner());
  try {
    const result = yield call(API.deleteQuote, symbol.toUpperCase());
    if (result && result.success) {
      yield put(quoteActions.removeSuccess(symbol));
    }
  } catch (e) {
    cogoToast.error(`Failed to remove ${symbol} from favorites!`);
  }
  yield put(dashboardActions.hideSpinner());
}

function* resetQuotePin({ value }) {
  const { symbol, pin } = value;
  try {
    const result = yield call(API.resetQuotePin, symbol, pin);
    if (!result || !result.success) {
      throw "error";
    }
  } catch (e) {
    cogoToast.error(`Failed to reset pin price for ${symbol}!`);
  }
  yield put(dashboardActions.hideSpinner());
}

function* quotesSaga() {
  yield takeLatest(QuoteTypes.QUOTES_FETCH, getQuotes);
  yield takeEvery(QuoteTypes.REGISTER_QUOTE, registerQuote);
  yield takeEvery(QuoteTypes.REMOVE_FROM_QUOTES, removeQuote);
  yield takeEvery(QuoteTypes.RESET_QUOTE_PIN, resetQuotePin);
}

export default quotesSaga;
