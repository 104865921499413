import React from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars-2";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
const moment = require("moment-timezone");

import SearchInput from "../shared/SearchInput";
import { LeftArrow, RightArrow } from "./Arrows";

import { store } from "../store/createStore";
import { NewsActions, DashboardActions } from "../store";

import API from "../api";

import { SEARCH_DROPDOWN_MODE_SYMBOL, newsSource } from "../constants";
import RecentSymbols from "../shared/RecentSymbols";

const ArticleLink = styled.a`
  color: #ebebeb;
  display: block;
  text-decoration: underline;
  width: fit-content;
  margin-top: 0.5rem;

  &:hover {
    color: #ebebeb;
  }
`;

class News extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      detailItem: {},
      detailItemLink: null,
      detailModalVisible: false,
    };
  }

  fetchIntervalId = null;
  symbolSearchTimeoutId = null;

  _newsRef = null;
  prevNewsState = null;
  storeUnsubscribe = null;

  _scrollbarRef = {};

  componentDidMount() {
    this.fetchIntervalId = setInterval(this.props.fetchNews, 60 * 1000);
    this.props.updateLoadingMore(false);
    this.props.fetchNews();

    this.prevNewsState = store.getState().news;
    this.prevSearchDropdownState = store.getState().dashboard?.searchDropdown?.clicked || {};
    this.storeUnsubscribe = store.subscribe(() => {
      const { news: currentNews, auth, dashboard } = store.getState();
      if (!auth.authenticated) {
        return;
      }

      if (currentNews.filterValue != this.prevNewsState.filterValue) {
        if (this._newsRef) {
          this._newsRef.scrollToTop();
        }
        const index = currentNews.recents.indexOf(currentNews.filterValue);
        this._scrollbarRef?.current?.scrollToItem(this._scrollbarRef?.current?.getItemElementById(index));
      }
      this.prevNewsState = currentNews;

      const { symbol: searchSymbol, source: searchSource } = dashboard?.searchDropdown?.clicked || {};
      if (
        this.prevSearchDropdownState.symbol !== searchSymbol ||
        this.prevSearchDropdownState.source !== searchSource
      ) {
        this.prevSearchDropdownState = {
          symbol: searchSymbol,
          source: searchSource,
        };
        if (searchSource === "news" && searchSymbol) {
          this.populateSymbol(searchSymbol);
          this.props.updateSearchDropdownClicked("");
        }
      }
    });
  }

  componentWillUnmount() {
    !this.storeUnsubscribe || this.storeUnsubscribe();

    if (this.fetchIntervalId) {
      clearInterval(this.fetchIntervalId);
    }
    this.fetchIntervalId = null;

    if (this.symbolSearchTimeoutId) {
      clearTimeout(this.symbolSearchTimeoutId);
    }
    this.symbolSearchTimeoutId = null;
  }

  populateSymbol = (symbol) => {
    this.props.addToRecent(symbol);
    this.props.updateFilter(symbol);
  };

  onChangeSearch(text, boundingRect) {
    const value = text;

    this.setState({
      search: value,
    });

    const rect = boundingRect;
    this.props.updateSearchDropdown({
      mode: SEARCH_DROPDOWN_MODE_SYMBOL,
      visible: true,
      mobileVisible: true,
      mobileSearch: value,
      search: value,
      source: "news", // TODO: get classname
      top: rect.top,
      right: rect.right,
      bottom: rect.bottom,
      left: rect.left,
    });
  }

  clearSearch() {
    this.setState({
      search: "",
    });
    this.props.updateSearchDropdown({
      mode: "",
      visible: false,
      mobileVisible: false,
      mobileSearch: "",
      search: "",
      source: null,
    });
  }

  onFocusSearch = (e, focused) => {
    if (focused) {
      const rect = e.target.getBoundingClientRect();
      this.props.updateSearchDropdown({
        mode: SEARCH_DROPDOWN_MODE_SYMBOL,
        visible: !!this.state.search,
        mobileVisible: true,
        mobileSearch: this.state.search,
        search: this.state.search,
        source: "news", // TODO: get classname
        top: rect.top,
        right: rect.right,
        bottom: rect.bottom,
        left: rect.left,
      });
    } else {
      if (!isMobile) {
        setTimeout(() => {
          this.props.updateSearchDropdown({
            mode: "",
            visible: false,
            mobileVisible: false,
            mobileSearch: "",
            search: "",
            source: null,
          });
        }, 200);
      }
    }
  };

  setFilter(value) {
    this.props.updateFilter(value);
  }

  async openDetailModal(item) {
    this.setState({
      detailItem: {
        ...item,
      },
      detailItemLink: null,
      detailModalVisible: true,
    });

    let detailLink = item.url;
    try {
      const { url } = await API.getNewsLink({ id: item.id });
      detailLink = url;
    } catch (err) {}

    if (item.id === this.state.detailItem?.id && this.state.detailModalVisible) {
      this.setState({
        detailItemLink: detailLink,
      });
    }
  }

  onRecentSelect(symbol) {
    this.props.updateFilter(symbol);
  }

  renderItem(item, index) {
    const { title, description, url, imageUrl, symbols, publishedAt } = item;

    let pub_str = "";
    const mPublishedAt = moment(publishedAt);
    const duration = moment.duration(moment().diff(mPublishedAt));
    let diffMin = parseInt(duration.asMinutes());

    if (diffMin < 60 || true) {
      pub_str = mPublishedAt
        .fromNow()
        .replace("seconds", "sec")
        .replace("second", "sec")
        .replace("minutes", "min")
        .replace("minute", "min")
        .replace("hours", "hr")
        .replace("hour", "hr");
    } else {
      pub_str = mPublishedAt.format("MM/DD/YY HH:mm");
    }

    const symbols_arr = symbols || [];
    let symbol_str = symbols_arr.slice(0, 1).join(", ");
    if (symbols_arr.length > 2) {
      symbol_str += ", ...";
    }

    return (
      <div
        key={`${index}`}
        className="d-flex justify-content-between align-items-center news-item"
        onClick={() => {
          this.openDetailModal(item);
        }}
      >
        <span className="text-single-line-ellipsis news-title">{title}</span>
        <span className="news-symbols">{symbol_str}</span>
        <span className="news-dt">{pub_str}</span>
      </div>
    );
  }

  renderDetailModal() {
    const { detailModalVisible, detailItem, detailItemLink } = this.state;
    const { title, description, source, url, imageUrl, symbols, publishedAt, details } = detailItem;

    let source_str = "";
    if (source && source.id) {
      source_str = newsSource[source.id] || "";
    }
    let symbol_str = "";
    if (symbols) {
      symbol_str = symbols.join(", ");
    }

    let desc_str = description;
    if (details) {
      if (details.details && typeof details.details === "string") {
        desc_str = details.details;
      } else if (details.description && typeof details.description === "string") {
        desc_str = details.description;
      }
    }

    let source_ref = null;
    if (source_str) {
      if (details && details.linkToFilingDetails) {
        source_ref = (
          <a href={details.linkToFilingDetails} target="_blank" className="mb-2">
            {source_str}
          </a>
        );
      } else {
        source_ref = source_str;
      }
    }

    return (
      <Modal
        show={detailModalVisible}
        onHide={() => {
          this.setState({ detailModalVisible: false, detailItem: {} });
        }}
        className="modal-news-detail"
        size={"xl"}
        centered
        aria-labelledby=""
      >
        <Modal.Header closeButton style={{ border: "none" }}>
          <Modal.Title>
            {!symbol_str || (
              <span className="mr-3 mr-md-4" style={{ fontWeight: "bold" }}>
                {symbol_str}{" "}
              </span>
            )}
            <span style={{ fontSize: "1rem", fontWeight: "normal" }}>{title}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <hr className="mt-0" />
          {!source_ref || <div className="news-source mb-2">Source: {source_ref}</div>}
          <div className="mb-2">Published at: {moment(publishedAt).format("MM/DD/YYYY hh:mm a")}</div>
          {desc_str}
          {detailItemLink && (
            <ArticleLink href={detailItemLink} target="_blank">
              Read article
            </ArticleLink>
          )}
        </Modal.Body>
      </Modal>
    );
  }

  onClickLoadMore() {
    this.props.fetchMoreNews();
  }

  render() {
    const { search } = this.state;
    const { maximisedView } = this.props;
    const { filterValue, data, loadingMore, recents } = this.props.news;

    return (
      <div
        className={`card w-100 ${maximisedView ? "h-auto" : "h-100"}`}
        style={{
          overflow: "hidden",
        }}
      >
        <div className="news-container">
          <div className="d-flex justify-content-between mb-1">
            <h4 style={{ marginBottom: "0px" }}>News</h4>
            <div className="d-flex flex-wrap justify-content-end align-items-center mt-n2">
              <div className="text-right" style={{ fontSize: "14px" }}>
                <span
                  className={
                    filterValue === "all" ? "badge-toggle-indicator mr-1 active" : "badge-toggle-indicator mr-1"
                  }
                ></span>
                <a
                  className="btn-toggle-link"
                  onClick={() => {
                    this.setFilter("all");
                  }}
                >
                  All
                </a>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <a
                  className="btn-toggle-link"
                  onClick={() => {
                    this.setFilter("fav");
                  }}
                >
                  Fav
                </a>
                <span
                  className={
                    filterValue === "fav" ? "badge-toggle-indicator ml-1 active" : "badge-toggle-indicator ml-1"
                  }
                ></span>
              </div>
              <SearchInput
                className={`ml-2`}
                value={search}
                placeholder="Symbol..."
                onChange={this.onChangeSearch.bind(this)}
                onFocus={(e) => this.onFocusSearch(e, true)}
                onBlur={(e) => this.onFocusSearch(e, false)}
                onClear={this.clearSearch.bind(this)}
              />
            </div>
          </div>

          {(() => {
            const { removeFromRecent, fetchNews } = this.props;
            const { recents, filterValue } = this.props.news;

            return (
              <RecentSymbols
                recents={recents}
                symbol={filterValue}
                onSelect={(item) => {
                  this.onRecentSelect(item);
                }}
                onRemove={(item) => {
                  removeFromRecent(item);
                  if (item == filterValue) {
                    fetchNews();
                  }
                }}
              />
            );
          })()}

          <div className="data-section news-section flex-grow-1 d-flex mx-0">
            <Scrollbars
              ref={(ref) => {
                this._newsRef = ref;
              }}
              autoHide
              style={{
                width: "100%",
                height: "auto",
              }}
              renderTrackVertical={(props) => <div className="track-vertical" {...props} />}
            >
              <div className="" style={{ overflowY: "auto" }}>
                {data.map((item, index) => {
                  return this.renderItem(item, index);
                })}
                <div className="d-flex justify-content-center align-items-center" style={{ height: "25px" }}>
                  {loadingMore ? (
                    <i className="fa fa-circle-o-notch fa-spin" style={{ height: "fit-content" }}></i>
                  ) : (
                    <span className="btn-news-loadmore" onClick={() => this.onClickLoadMore()}>
                      Load more...
                    </span>
                  )}
                </div>
              </div>
            </Scrollbars>
          </div>
          {this.renderDetailModal()}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  updateFilter: NewsActions.updateFilter,
  addToRecent: NewsActions.addToRecent,
  removeFromRecent: NewsActions.removeFromRecent,
  fetchNews: NewsActions.newsFetch,
  fetchMoreNews: NewsActions.newsFetchMore,
  updateLoadingMore: NewsActions.updateLoadingMore,
  updateSearchDropdown: DashboardActions.updateSearchDropdown,
  updateSearchDropdownClicked: DashboardActions.updateSearchDropdownClicked,
};

const mapStateToProps = (state, props) => ({
  quotes: state.quote.quotes,
  news: state.news,
  maximisedView: state.dashboard.maximisedView,
  ...props,
});

export default connect(mapStateToProps, mapDispatchToProps)(News);

// function onWheel(apiObj, ev) {
//   const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

//   if (isThouchpad) {
//     ev.stopPropagation();
//     return;
//   }

//   if (ev.deltaY < 0) {
//     apiObj.scrollNext();
//   } else if (ev.deltaY > 0) {
//     apiObj.scrollPrev();
//   }
// }
