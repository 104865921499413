import _ from "lodash";
import { DISCOVERY_DEFAULT_FILTEROUT_FADEOUT_TIME } from "../../constants";

export default class FilterFader {
  faderUpdaterDebounce = 500;

  filteredOutList = [];
  fadeoutTime = DISCOVERY_DEFAULT_FILTEROUT_FADEOUT_TIME;

  triggerUpdateFunc = () => {};
  faderUpdaterTimeouts = {};

  update({ data, previousData, unfilteredData, viewportSymbols, triggerUpdate }) {
    if (!this.triggerUpdateFunc || !this.triggerUpdate) {
      this.triggerUpdateFunc = triggerUpdate;

      this.triggerUpdate = _.debounce(this.triggerUpdateFunc, this.faderUpdaterDebounce, {
        leading: true,
        maxWait: this.faderUpdaterDebounce * 2,
      });
    }

    const currentFilteredOut = this.getFilteredOutList();

    let newFilteredOut = _.differenceBy(previousData, data, "symbol");
    newFilteredOut = newFilteredOut.filter((item) => viewportSymbols.includes(item.symbol));

    const netNewFilteredOut = _.differenceBy(newFilteredOut, currentFilteredOut, "symbol");

    const newFilteredIn = _.differenceBy(data, previousData, "symbol");
    this.filteredOutList = _.differenceBy(currentFilteredOut, newFilteredIn, "symbol");

    this.addNewFilteredOut(netNewFilteredOut);

    const matchingUnfilteredData = _.intersectionBy(unfilteredData, this.filteredOutList, "symbol");
    this.updateFilteredOutListData(matchingUnfilteredData);

    return { data: this.getFilteredOutList(), changed: newFilteredOut.length || newFilteredIn.length };
  }

  updateFadeoutTime(fadeoutTime) {
    if (!fadeoutTime || this.fadeoutTime === fadeoutTime) {
      return;
    }
    this.fadeoutTime = fadeoutTime;
    this.resetUpdaters();
  }

  addNewFilteredOut(data) {
    const addedTime = Date.now();
    const newItems = data.map((item) => ({
      ...item,
      filterFader_addedTime: addedTime,
    }));

    this.filteredOutList = this.filteredOutList.concat(newItems);

    if (newItems.length) {
      this.faderUpdaterTimeouts[addedTime] = setTimeout(() => {
        // console.log("Force Updated-", newItems);
        this.triggerUpdate();
        delete this.faderUpdaterTimeouts[addedTime];
      }, this.fadeoutTime);
    }
  }

  getFilteredOutList() {
    return this.filteredOutList.filter((item) => {
      const currentTime = new Date().getTime();
      return currentTime - item.filterFader_addedTime < this.fadeoutTime;
    });
  }

  updateFilteredOutListData(data) {
    this.filteredOutList.forEach((item, i) => {
      const dataItem = data.find((d) => d.symbol === item.symbol);
      if (dataItem) {
        this.filteredOutList[i] = {
          ...item,
          ...dataItem,
        };
      }
    });
  }

  reset() {
    this.filteredOutList = [];
    this.resetUpdaters();
  }

  resetUpdaters() {
    Object.keys(this.faderUpdaterTimeouts).forEach((timeoutKey) => {
      clearTimeout(this.faderUpdaterTimeouts[timeoutKey]);
    });
  }

  clean() {
    this.reset();
    this.triggerUpdate?.cancel();
    this.triggerUpdate = null;
    this.triggerUpdateFunc = null;
  }
}
