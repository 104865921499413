import React, { Component } from "react";
import PropTypes from "prop-types";
import "react-contexify/dist/ReactContexify.min.css";
import { contextMenu } from "react-contexify";
import { SYMBOL_CONTEXT_MENU_ID, DEFAULT_OPTIONS_MODE } from "../constants";
import { DashboardActions, NewsActions } from "../store";
import API from "../api";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";

class Symbol extends Component {
  handleDoubleClick = (e) => {
    e.preventDefault();
    document.getSelection().removeAllRanges();

    const { symbol } = this.props;

    this.props.updateSymbolPopup({
      visible: false,
    });
    this.props.updateContextMenuSymbol(symbol);

    contextMenu.show({
      id: SYMBOL_CONTEXT_MENU_ID,
      event: e,
      props: {
        symbol,
      },
    });

    // update for popular
    API.incSymbolStats(symbol);
  };

  handleClick = () => {
    const { symbol } = this.props;

    const event = new CustomEvent("symbolClick", {
      detail: {
        symbol,
      },
    });
    window.dispatchEvent(event);
    window.opener && window.opener.dispatchEvent(event);

    // update for popular
    API.incSymbolStats(symbol);
  };

  hasOptions(symbol) {
    const { options } = this.props;
    if (options && symbol) {
      return options.indexOf(symbol.toUpperCase()) > -1;
    }
    return false;
  }

  onMouseEnter = (e) => {
    if (isMobile) return;
    const rect = this._ref.getBoundingClientRect();
    this.props.updateSymbolPopup({
      visible: true,
      top: rect.top,
      right: rect.right,
      bottom: rect.bottom,
      left: rect.left,
      symbol: this.props.symbol,
      onClick: this.handleDoubleClick,
    });
  };

  onMouseLeave = (e) => {
    this.props.updateSymbolPopup({
      visible: false,
      // top: 0,
      // right: 0,
      // bottom: 0,
      // left: 0,
      // symbol: null
    });
  };

  render() {
    const { symbol, optionsMode, showOptions, optionsColor } = this.props;
    return (
      <div
        ref={(ref) => (this._ref = ref)}
        onClick={this.handleClick}
        onDoubleClick={this.handleDoubleClick}
        onContextMenu={this.handleDoubleClick}
        className="position-relative quote-symbol"
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        {symbol}
        {!(showOptions && optionsMode === "Icon" /* || optionsMode === 'Filter' */ && this.hasOptions(symbol)) || (
          <sup>
            <i
              className="fa fa-circle-thin"
              style={{
                color: optionsColor || "white",
                fontSize: "8px",
                marginLeft: "2px",
              }}
            ></i>
          </sup>
        )}
      </div>
    );
  }
}

Symbol.propTypes = {
  symbol: PropTypes.string,
};

const mapDispatchToProps = {
  updateContextMenuSymbol: DashboardActions.updateContextMenuSymbol,
  updateFilter: NewsActions.updateFilter,
  addToRecent: NewsActions.addToRecent,
  updateSymbolPopup: DashboardActions.updateSymbolPopup,
};

const mapStateToProps = (state, props) => ({
  optionsMode: state.config.optionsMode || DEFAULT_OPTIONS_MODE,
  options: state.options.options,
});

export default connect(mapStateToProps, mapDispatchToProps)(Symbol);
