import React, { useEffect, useState, useRef } from "react";
import { Dropdown } from "react-bootstrap";
import PropTypes from "prop-types";

import { APP_COLORS, DEFAULT_DASHBOARD_LAYOUT_CONFIG } from "../../constants";

import styles from "./index.module.scss";
import withScreenSizes from "../Utilities/withScreenSizes";

function LayoutButton(props) {
  const { layout, setLayoutActive, resetLayout, toggleLayoutLock, updateLayoutLabel, triggerLayoutForceUpdate } = props;

  const layoutInfo = layout || DEFAULT_DASHBOARD_LAYOUT_CONFIG;

  const [layoutNames, setLayoutNames] = useState({});
  const [showDropdown, setShowDropdown] = useState(false);

  const editNameInputRef = useRef(null);
  const inputEscaping = useRef(false);

  const editingName = Object.keys(layoutNames).find((key) => layoutNames[key].editing);

  useEffect(() => {
    resetLayoutNames();
  }, [layoutInfo]);

  useEffect(() => {
    if (editNameInputRef.current) {
      editNameInputRef.current.select();
    }
  }, [editingName]);

  function resetLayoutNames() {
    const layoutNames = layoutInfo.reduce((acc, config) => {
      acc[config.key] = { label: config.label };
      return acc;
    }, {});
    setLayoutNames(layoutNames);
  }

  return (
    <div className="d-flex pr-1 pr-sm-3">
      {/* <div className={`bar-icon showWidget`} style={{background: 'unset'}} onClick={() => {
        toggleLayoutLocked();
      }}>
        <i className={`fa ${layoutLocked ? "fa-lock" : "fa-unlock"}`} />
      </div>
      <div className={`bar-icon ${layoutLocked ? 'hideWidget' : 'showWidget'}`} style={{background: 'unset'}} onClick={() => {
        if (!layoutLocked) {
          updateLayoutConfig(null)
          updateLayoutDeviceMedia(null)
        }
      }}>
        <i className={`fa fa-th-large`} />
      </div> */}
      <Dropdown
        varaint="btn btn-outline-secondary"
        className="d-flex align-items-center"
        show={showDropdown}
        onToggle={(isToOpen, event) => {
          const func = () => {
            if (inputEscaping.current) return;
            setShowDropdown(isToOpen);
            if (isToOpen) resetLayoutNames();
          };
          if (isToOpen) {
            func();
          } else {
            setTimeout(func, 100);
          }
        }}
      >
        <Dropdown.Toggle as="a" className="nav-link cursor-pointer no-caret py-0 px-2">
          <div className={`bar-icon showWidget`} style={{ background: "unset" }}>
            <i className={`fa fa-th-large`} />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className={styles.layoutsContainer}>
          {layoutInfo.map((config) => {
            const nameItem = layoutNames[config.key];
            const editing = nameItem?.editing;
            function saveName(key, label) {
              updateLayoutLabel && updateLayoutLabel({ key, label: (label + "").trim() });
            }
            function cancelEdit() {
              inputEscaping.current = true;

              setLayoutNames({
                ...layoutNames,
                [config.key]: {
                  ...nameItem,
                  label: layoutInfo.find((item) => item.key === config.key).label,
                  editing: false,
                },
              });
              setTimeout(() => {
                inputEscaping.current = false;
              }, 600);
            }

            return (
              <Dropdown.Item
                key={config.key}
                className={[
                  "d-flex justify-content-between",
                  styles.layoutItem,
                  props.isSmallScreen ? styles.layoutItemMobile : "",
                ].join(" ")}
                onClick={() => setLayoutActive && setLayoutActive(config.key)}
                disabled={editingName && !editing}
              >
                {/* <span
                  className={styles["layout-menu-item-btnset"]}
                  style={props.isSmallScreen ? { minWidth: "44px" } : {}}
                >
                </span> */}
                <i
                  className={[
                    "mdi",
                    editing ? "mdi-check" : "mdi-pencil",
                    styles.editButton,
                    styles.iconHover,
                    editing || props.isSmallScreen ? styles.opaque : "",
                  ].join(" ")}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (editing) {
                      saveName(config.key, nameItem?.label);
                    } else {
                      setLayoutNames({
                        ...layoutNames,
                        [config.key]: { ...nameItem, editing: true },
                      });
                    }
                  }}
                  style={{ ...(editing && { color: APP_COLORS.active }) }}
                />
                {editing ? (
                  <input
                    autoFocus
                    ref={editNameInputRef}
                    value={nameItem?.label}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onChange={(e) => {
                      if (e.target.value.length > 25) return;
                      setLayoutNames({
                        ...layoutNames,
                        [config.key]: { ...nameItem, label: e.target.value },
                      });
                    }}
                    onKeyDown={(e) => {
                      e.stopPropagation();
                      if (e.key === "Enter") {
                        saveName(config.key, e.target.value);
                      } else if (e.key === "Escape") {
                        cancelEdit();
                      }
                    }}
                  ></input>
                ) : (
                  <span style={{ color: config.active ? APP_COLORS.active : "inherit" }} className={styles.label}>
                    {nameItem?.label}
                  </span>
                )}

                <span
                  className={styles["layout-menu-item-btnset"]}
                  style={props.isSmallScreen ? { minWidth: "44px" } : {}}
                >
                  {editing ? (
                    <i
                      className="mdi mdi-close"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        cancelEdit();
                      }}
                    />
                  ) : config.locked ? (
                    <i
                      className="mdi mdi-lock"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        toggleLayoutLock && toggleLayoutLock(config.key);
                      }}
                    />
                  ) : (
                    <>
                      <i
                        className="fa fa-unlock"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          toggleLayoutLock && toggleLayoutLock(config.key);
                        }}
                      />
                      <i
                        className="fa fa-repeat"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          resetLayout && resetLayout(config.key);
                          triggerLayoutForceUpdate && triggerLayoutForceUpdate();
                        }}
                      />
                    </>
                  )}
                </span>
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

LayoutButton.propTypes = {
  layout: PropTypes.array.isRequired,
  setLayoutActive: PropTypes.func,
  resetLayout: PropTypes.func,
  toggleLayoutLock: PropTypes.func,
  triggerLayoutForceUpdate: PropTypes.func,
};

export default withScreenSizes(LayoutButton);
